import { TemplateInterface, NewTemplateInterface } from './templateInterface';
import { DefinitioniInterface } from '../system/systemDefinition';
import { IdLabel } from '../global';

export const CTD_LABELS = {
  CODE: 'CTD Code',
  TITLE: 'CTD Title',
};

export const REPORT_LABELS = {
  CODE: 'Report Classification',
  TITLE: 'Report Title',
};

export const TEMPLATE_TYPES = {
  submission: 'VCUSTTEMPLATETYPEREGULATORYSUBMISSION',
  internal: 'VCUSTTEMPLATETYPEINTERNALREPORTS',
  generic: 'VCUSTTEMPLATETYPEGENERICTEMPLATE',
  ptqagreements: 'VCUSTTEMPLATETYPEPTQUALITYAGREEMENTS',
  ptqapqr: 'VCUSTTEMPLATETYPEPTQAPQR',
  diapoc: 'VCUSTTEMPLATETYPEDIAPOC'
};

export type CountryGoup = {
  countryGroupName: string | IdLabel;
  countries: string[];
  disabled?: boolean;
};

export class Template {
  constructor(data: TemplateInterface) {
    Object.assign(this, data);
  }

  public static readonly createTemplate = (data: TemplateInterface) => {
    return new Template(data);
  };

  public static readonly getValue = (
    data: string | boolean | undefined | string[],
    definition: DefinitioniInterface,
  ) => {
    if (definition.type === 'string' || definition.type === 'dateTime') {
      return data;
    } else if (definition.type === 'lov' && !definition.flags?.isMultiValue) {
      return { id: data };
    } else if (definition.type === 'multiLov' || definition.flags?.isMultiValue) {
      return (data as string[])?.map((item) => ({
        [definition.type === 'userReference' ? 'value' : 'id']: item,
      }));
    }
  };

  public static readonly createTemplateObject = (
    data: TemplateInterface | { [key: string]: string[] },
    templateDefinitions: DefinitioniInterface[],
  ) => {
    const getMultiValueType = (type: string) => {
      if (type === 'userReference') {
        return 'multiUserReference';
      }
      return 'multiLov';
    };
    const result = Object.keys(data)
      .map((key) => {
        const definition = templateDefinitions.find(
          (item: DefinitioniInterface) => item.id === key,
        );
        if (definition && data[key as keyof TemplateInterface]) {
          return {
            id: definition.id,
            value: this.getValue(data[key as keyof TemplateInterface], definition),
            type: definition.flags?.isMultiValue
              ? getMultiValueType(definition.type)
              : definition.type,
          };
        }
        return null;
      })
      .filter((item) => item);
    return { fields: result } as NewTemplateInterface;
  };

  public static readonly requiredFields = ['FTITLE'];

  public static readonly checkFormValidity = (template: TemplateInterface) => {
    const keys = Object.keys(template) as (keyof TemplateInterface)[];
    const errors: { key: keyof TemplateInterface; error: string }[] = [];
    keys.forEach((key) => {
      if (this.requiredFields.indexOf(key as string) > -1 && !template[key]) {
        errors.push({
          key,
          error: 'Field is required',
        });
      }
    });
    return errors;
  };
}
