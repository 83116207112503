import { THIRD_STEP, defaultTableHeadCells } from '../../constants';
import { Member, SelectValue, SingleObject, IdLabel } from '../models/global';
import { LovValue } from '../models/lovValue';
import { OutputFormats } from '../models/output/outputInterface';
import {
  DefinitioniInterface,
  FieldResponseInterfaceValues,
} from '../models/system/systemDefinition';
import { TEMPLATE_TYPES } from '../models/template/template';
import { FieldsToSend, RequiredMetadata } from '../models/workingDocument/requiredMetadata';

export const setTableHeaders = (
  objectType: 'project' | 'template' | 'document',
  fields: DefinitioniInterface[],
) => {
  let tableHeadCellsNew;

  const savedColumns = localStorage.getItem(objectType);
  if (savedColumns) {
    const parsedColumns = JSON.parse(savedColumns);
    if (Array.isArray(parsedColumns)) {
      const data = parsedColumns.map((id: string) => {
        if (!fields.find((item: { id: string }) => item.id === id) && id !== 'MEMBERS') {
          return { id: '', label: '' };
        }
        return { id, label: '' };
      });
      tableHeadCellsNew = data.filter((item) => item.id);
    } else {
      tableHeadCellsNew = defaultTableHeadCells[objectType];
    }
  } else {
    tableHeadCellsNew = defaultTableHeadCells[objectType];
  }
  return tableHeadCellsNew;
};

export const setFavorite = (
  isMyData: boolean,
  myTableRows: SingleObject[],
  id: string,
  allTableRows: SingleObject[],
  singleObject: SingleObject,
) => {
  if (isMyData) {
    const index = myTableRows.findIndex((row) => row.id === id);
    if (index > -1) {
      myTableRows[index].isFavorite = !myTableRows[index].isFavorite;
    }
  } else {
    const index = allTableRows.findIndex((row) => row.id === id);
    if (index > -1) {
      allTableRows[index].isFavorite = !allTableRows[index].isFavorite;
    }
  }
  if (id === singleObject.id) {
    singleObject.isFavorite = !singleObject.isFavorite;
  }
  return {
    myTableRows,
    allTableRows,
    singleObject,
  };
};

export const getLovValues = (lovs: { [k: string]: LovValue[] }, key: string) => {
  if (lovs?.[key]) {
    return lovs?.[key].map((item) => ({
      id: item.id,
      label: item.label,
    }));
  } else {
    return [];
  }
};

export const isFieldEmpty = (
  optionalFields: string[],
  key: string,
  value: string | string[] | SelectValue | SelectValue[] | Member[],
) => {
  if (optionalFields.includes(key)) {
    return false;
  }
  if (!value || (typeof value === 'string' && !value.trim())) {
    return true;
  }
  if (
    Array.isArray(value) &&
    !(value as SelectValue[]).filter((item) => item.id).length &&
    !(value as string[]).length
  ) {
    return true;
  }
  if (typeof value !== 'string' && !Array.isArray(value) && !value.id) {
    return true;
  }
};

export const actionButtonContentAndAction = (
  activeStep: number,
  saving: boolean,
  nextHandler: () => void,
  saveHandler: () => void,
) => {
  if (activeStep !== THIRD_STEP) {
    return {
      text: 'Next',
      handler: nextHandler,
    };
  }
  if (activeStep === THIRD_STEP && saving) {
    return {
      text: 'Saving...',
      handler: () => {},
    };
  }
  return {
    text: 'Save',
    handler: saveHandler,
  };
};

export const getTemplateTypeByProjectType = (projectType: string) => {
  if (projectType === 'Internal Reports') {
    return [TEMPLATE_TYPES.internal];
  }
  if (projectType === 'Regulatory Submission') {
    return [TEMPLATE_TYPES.submission];
  }
  if (projectType === 'PT Quality Agreements') {
    return [TEMPLATE_TYPES.ptqagreements];
  }
  if (projectType === 'PTQ APQR') {
    return [TEMPLATE_TYPES.ptqapqr];
  }
  if (projectType === 'DIA PoC') {
    return [TEMPLATE_TYPES.diapoc];
  }

  return [TEMPLATE_TYPES.generic];
};

export const disableEventsClass = (loading: boolean) => {
  if (loading) {
    return 'disable-events';
  }
  return '';
};

export const getStatusClassName = (status: string) => {
  if (status === 'Draft') {
    return 'status-draft';
  } else if (status === 'Released' || status === 'Published') {
    return 'status-released';
  } else if (status === 'In review') {
    return 'status-in-review';
  }
  return 'status-archived';
};

export const sortFieldsAlphabetically = (a: RequiredMetadata, b: RequiredMetadata) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const populateFieldsForValuesRequest = (
  allFields: DefinitioniInterface[],
  fieldValues: { [k: string]: IdLabel | IdLabel[] },
) => {
  const fields: FieldsToSend[] = [];
  Object.entries(fieldValues).forEach(([key, val]) => {
    const fieldDetails = allFields.find((el) => el.id === key);
    let value: { id: string } | { id: string }[];
    if (val && fieldDetails) {
      if (!fieldDetails.flags?.isMultiValue) {
        value = { id: (val as IdLabel).id };
      } else {
        value = (val as IdLabel[]).map(({ id }) => ({ id }));
      }
      fields.push({
        id: key,
        type: fieldDetails.flags?.isMultiValue ? 'multiLov' : fieldDetails.type,
        value,
      });
    }
  });
  return fields;
};

export const getOutputFormat = (key: OutputFormats | undefined): string | undefined => {
  const formatsMapping = {
    'FO PDF': 'PDF',
    'Roche DOCX2': 'DOCX',
    'Preview-docx': 'XHTML',
  };
  if (key) {
    return formatsMapping[key];
  }
  return undefined;
};

export const getValueBasedOnType = (
  fieldDefinition: DefinitioniInterface,
  values: FieldResponseInterfaceValues[],
) => {
  if (fieldDefinition.flags?.isMultiValue) {
    return values.map((value) => (value.value as DefinitioniInterface).id);
  } else {
    return (values[0].value as DefinitioniInterface).id;
  }
};
