/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Stack, Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import { SelectValue, FormValues, Member } from '../../core/models/global';
import { IATextArea } from '../IATextArea/IATextArea';
import { IAInput } from '../Input/Input';
import './TemplateSteps.scss';
import DatePicker from '../DatePicker/DatePicker';
import ReviewActionTitle from '../../components/ReviewActionTitle/ReviewActionTitle';
import { GlobalState } from '../../core/models/state/globalState';
import dayjs, { Dayjs } from 'dayjs';
import { getLovValues } from '../../core/services/helpers';
import { FIRST_STEP } from '../../constants';
import AddMore from '../icons/AddMore';
import Cross from '../icons/Cross';
import { getInitials } from '../../core/services/userService';
import TemplateMembersDialog from './TemplateMembersDialog';
import { getProjectMetadataValues } from '../../state/actions/project';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { TEMPLATE_TYPES } from '../../core/models/template/template';

interface BasicDetailsProps {
  fillStepFields: (step: string, value: FormValues) => void;
  fieldsFilled: FormValues;
  isReview?: boolean;
  setActiveStep?: (activeStep: number) => void;
  setResetValuesBasedOnType: (str?: string) => void;
  title?: string;
  emptyFields?: string[];
  isEdit?: boolean;
  canChange?: boolean;
  published?: boolean;
}

const BasicDetailsReview = (props: { formValues: FormValues }) => {
  const { formValues } = props;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            <div>
              <div className='label'>Template type</div>
              <div className='review-value'>{(formValues.type as SelectValue)?.label || '-'}</div>
            </div>
          </div>
        </Grid>
        {(formValues.type as SelectValue)?.id !== TEMPLATE_TYPES.diapoc && (formValues.type as SelectValue)?.id !== TEMPLATE_TYPES.ptqapqr && (
          <>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>Effective version (optional)</div>
                  <div className='review-value'>
                    {(formValues.effectiveVersion as string) || '-'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>Effective date (optional)</div>
                  <div className='review-value'>
                    {formValues.effectiveDate
                      ? dayjs(formValues.effectiveDate as string).format('DD MMM YYYY')
                      : '-'}
                  </div>
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8.1}>
          <div className='description-container' data-testid='description-container'>
            <div>
              <div className='label'>Description (optional)</div>
              <div className='review-value'>
                {(formValues.description as string)?.trim() || '-'}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className='form-field' data-testid='member-container'>
        <div className='label'>Template members</div>
        <div className='review-value'>
          {(formValues.members as Member[])?.length
            ? (formValues.members as Member[]).map(({ label }) => label).join(', ')
            : '-'}
        </div>
      </div>
    </div>
  );
};

const BasicDetails = (props: BasicDetailsProps) => {
  const dispatch = useDispatch();
  const {
    fillStepFields,
    fieldsFilled,
    isReview,
    setActiveStep,
    setResetValuesBasedOnType,
    title,
    emptyFields = [],
    isEdit,
    canChange = true,
    published = false,
  } = props;
  const {
    template: { lovs },
  } = useSelector((state: GlobalState) => state.system);

  const [formValues, setFormValues] = useState<FormValues>({
    type: fieldsFilled.type ?? { id: '', label: '' },
    effectiveVersion: fieldsFilled.effectiveVersion ?? '',
    effectiveDate: fieldsFilled.effectiveDate ?? '',
    description: fieldsFilled.description ?? '',
    members: fieldsFilled?.members ?? [],
  });
  const [membersDialog, setMembersDialog] = useState(false);
  const [memberList, setMemberList] = useState<SelectValue[]>([]);
  const { user } = useSelector((state: GlobalState) => state.user);

  const onChange = (input: string, _event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    if (input === 'type') {
      setResetValuesBasedOnType();
    }
    setFormValues({ ...formValues, [input]: value as string | SelectValue | SelectValue[] });
  };

  const onChangeEffectiveVersion = (value?: AutocompleteValueT) => {
    if (isNaN(Number(value)) || (Number(value as string) < 1 && value !== '')) {
      return;
    }
    setFormValues({ ...formValues, effectiveVersion: value as string });
  };

  const onChangeEffectiveDate = (value: Dayjs | null | { $d: string }) => {
    setFormValues({
      ...formValues,
      effectiveDate:
        String((value as { $d: string }).$d) !== 'Invalid Date' ? (value as Dayjs)?.format() : '',
    });
  };

  useEffect(() => {
    fillStepFields('1', formValues);
  }, [formValues]);

  const handleUsers = (fields: RequiredMetadata[]) => {
    const field = fields.find((el) => el.id === 'FIAPPROJECTASSIGNEE');
    if (field) {
      setMemberList(
        field.values.map((item) => ({
          id: item.value as string,
          label: item.label as string,
        })),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            handleUsers(fields);
          }
        },
        {
          fields: [],
        },
      ),
    );
  }, [lovs]);

  const onEdit = () => {
    if (title && setActiveStep && canChange) {
      return () => {
        setActiveStep(FIRST_STEP);
        setResetValuesBasedOnType('keep');
      };
    }
  };

  const getDeleteIcon = (name: string) => {
    return (
      <span
        onMouseDown={() => {
          setFormValues({
            ...formValues,
            members: (formValues.members as Member[]).filter((el) => el.label !== name),
          });
        }}
      >
        <Cross fill='#0B41CD' />
      </span>
    );
  };

  const renderMembers = () => {
    if (isEdit) {
      return;
    }
    const addMembersIcon = (
      <div className='add-more-container'>
        <div data-testid='add-members' className='add-more' onClick={() => setMembersDialog(true)}>
          <AddMore />
          <span>Add members</span>
        </div>
      </div>
    );
    if (!Object.keys(formValues.members).length) {
      return addMembersIcon;
    }
    return (
      <div className='form-field' data-testid='member-container'>
        <div className='label'>Template members</div>
        <Stack gap={1} direction='row' flexWrap='wrap' className='members-container'>
          {(formValues.members as Member[]).map(({ label, role }) => (
            <Chip
              key={label}
              label={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className='member-name'>{label}</span>
                  <span className='role-name'>{role}</span>
                </div>
              }
              onDelete={() => {}}
              deleteIcon={getDeleteIcon(label)}
              avatar={<Avatar alt={label}>{getInitials(label)}</Avatar>}
            />
          ))}
        </Stack>
        {addMembersIcon}
      </div>
    );
  };

  return (
    <div className='template-form' data-testid='basic-details'>
      {isReview && (
        <>
          <ReviewActionTitle
            allowEdit={!published && canChange}
            onEdit={onEdit()}
            title={title}
            isReview={isReview}
          />
          <BasicDetailsReview formValues={formValues} />
        </>
      )}
      {!isReview && (
        <>
          <div className='action-title' data-testid='action-title'>
            {title}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='Template type'
                  label='Template type'
                  placeholder='Select template type'
                  options={getLovValues(lovs, 'DCUSTTEMPLATETYPE')}
                  onChange={(event, value) => onChange('type', event, value)}
                  value={formValues.type as SelectValue}
                  disabled={isReview}
                  error={emptyFields.includes('type')}
                />
              </div>
            </Grid>
            {(formValues.type as SelectValue)?.id !== TEMPLATE_TYPES.diapoc &&(formValues.type as SelectValue)?.id !== TEMPLATE_TYPES.ptqapqr && (
              <>
                <Grid item xs={4}>
                  <div className='form-field'>
                    <IAInput
                      inputLabel='Effective version (optional)'
                      placeholder='Enter effective version'
                      onChange={(event) => {
                        onChangeEffectiveVersion(event?.target?.value);
                      }}
                      value={formValues.effectiveVersion}
                      type='number'
                      disabled={isReview}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='form-field'>
                    <DatePicker
                      data-testid='Effective date (optional)'
                      label='Effective date (optional)'
                      onChange={(val) => {
                        onChangeEffectiveDate(val as Dayjs);
                      }}
                      value={formValues.effectiveDate as string}
                      disabled={isReview}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={8.1}>
              <div className='description-container' data-testid='description-container'>
                <IATextArea
                  label='Description (optional)'
                  value={formValues.description as string}
                  onChange={(event) => {
                    onChange('description', event, event?.target?.value);
                  }}
                  disabled={isReview}
                />
              </div>
            </Grid>
          </Grid>
          {renderMembers()}
          <TemplateMembersDialog
            open={membersDialog}
            handleClose={() => setMembersDialog(false)}
            templateCreator={user}
            allUsers={memberList}
            addMembers={(membersData) => {
              setFormValues({
                ...formValues,
                members: [...membersData],
              });
              setMembersDialog(false);
            }}
            addedMembers={[...JSON.parse(JSON.stringify(formValues.members as Member[]))]}
          />
        </>
      )}
    </div>
  );
};

export default BasicDetails;
