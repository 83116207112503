import { AnyAction } from 'redux';
import { favoriteActionTypes } from '../actions/favorite';
import { FavoriteState, FavoriteObjectTypes } from '../../core/models/favorites/favoriteState';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';
import { transformFieldsToObject } from '../../core/services/apiTranformations';
import { Project } from '../../core/models/project/project';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { Template } from '../../core/models/template/template';
import { FilterIterface, filterFavoriteObjects } from '../../core/models/filter/filter';
import { sortingHandler } from '../../core/services/sortingHandler';
import { SingleObject } from '../../core/models/global';

let parsedFavorites: FavoriteState = JSON.parse(localStorage.getItem('favorites') as string) ?? {};

const initialState: FavoriteState = {
  projects: parsedFavorites.projects ?? [],
  documents: parsedFavorites.documents ?? [],
  templates: parsedFavorites.templates ?? [],
  favoriteProjectsLoading: true,
  favoriteDocumentsLoading: true,
  favoriteTemplatesLoading: true,
  favoriteProjects: [],
  favoriteDocuments: [],
  favoriteTemplates: [],
  favoriteProjectsNotFiltered: [],
  favoriteDocumentsNotFiltered: [],
  favoriteTemplatesNotFiltered: [],
};

const addOrDeleteFavoriteObject = (
  favorites: string[],
  id: string,
  favoriteObjects: SingleObject[],
  filteredFavorites: SingleObject[],
  newFavorite: SingleObject,
) => {
  if (favorites.indexOf(id) > -1) {
    favorites.splice(favorites.indexOf(id), 1);
    favoriteObjects.splice(
      favoriteObjects.findIndex((el) => el.id === id),
      1,
    );
  } else {
    favorites.push(id);
    favoriteObjects.push(newFavorite);
  }
  const indexOFfiltered = filteredFavorites.findIndex((el) => el.id === id);

  if (indexOFfiltered > -1) {
    filteredFavorites.splice(indexOFfiltered, 1);
  } else {
    filteredFavorites.push(newFavorite);
  }
  return {
    updatedFavorites: favorites,
    updatedFavoriteObjects: favoriteObjects,
    filteredFavorites,
  };
};

const handleSetFavorite = (state: FavoriteState, action: AnyAction) => {
  if (!state[action.objectType as FavoriteObjectTypes]) {
    state[action.objectType as FavoriteObjectTypes] = [];
  }
  const favoriteObjectKey = `favorite${action.objectType[0].toUpperCase()}${action.objectType.slice(
    1,
  )}`;
  const id = action.payload.id;
  const favorites = state[action.objectType as FavoriteObjectTypes];
  const favoriteObjects = state[favoriteObjectKey as keyof FavoriteState] as SingleObject[];
  const favoriteObjectsNotFiltered = state[
    `${favoriteObjectKey}NotFiltered` as keyof FavoriteState
  ] as SingleObject[];

  const { updatedFavorites, updatedFavoriteObjects, filteredFavorites } = addOrDeleteFavoriteObject(
    favorites,
    id,
    [...favoriteObjectsNotFiltered],
    [...favoriteObjects],
    action.payload,
  );

  const result = {
    ...state,
    [action.objectType]: [...updatedFavorites],
    [favoriteObjectKey]: [...filteredFavorites],
    [`${favoriteObjectKey}NotFiltered`]: [...updatedFavoriteObjects],
  };
  localStorage.setItem(
    'favorites',
    JSON.stringify({
      projects: state.projects,
      documents: state.documents,
      templates: state.templates,
    }),
  );

  return result;
};

const handleGetFavorites = (state: FavoriteState, action: AnyAction) => {
  const favorites = localStorage.getItem('favorites') as string;
  parsedFavorites = JSON.parse(favorites) ?? {};
  action.callback({
    templates: parsedFavorites?.templates ?? [],
    projects: parsedFavorites?.projects ?? [],
    documents: parsedFavorites?.documents ?? [],
  });
  return {
    ...state,
    templates: parsedFavorites?.templates ?? [],
    projects: parsedFavorites?.projects ?? [],
    documents: parsedFavorites?.documents ?? [],
  };
};

const handleGetFavoriteDocumentsSuccess = (state: FavoriteState, action: AnyAction) => {
  const { favoriteDocuments } = state;
  const items = action.payload.map(
    (el: { items: { fields: FieldResponseInterface[]; id: string }[] }) => el,
  );
  items.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let row: WorkingDocumentInterface = WorkingDocument.createWorkingDocument({
      id: item.id,
      isFavorite: true,
    }) as WorkingDocumentInterface;
    row = transformFieldsToObject(item.fields, [], false, row);
    row.MEMBERS = String(
      (row.FIAPAUTHORLIST ? (row.FIAPAUTHORLIST as string).split(',').length : 0) +
        (row.FIAPREVIEWER ? (row.FIAPREVIEWER as string).split(',').length : 0),
    );
    favoriteDocuments.push(row);
  });
  return {
    ...state,
    favoriteDocumentsLoading: false,
    favoriteDocuments,
    favoriteDocumentsNotFiltered: favoriteDocuments,
    documents: favoriteDocuments.map((item) => item.id),
  };
};

const handleGetFavoriteProjectsSuccess = (state: FavoriteState, action: AnyAction) => {
  const { favoriteProjects } = state;
  const items = action.payload.map(
    (el: { items: { fields: FieldResponseInterface[]; id: string }[] }) => el,
  );
  items.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let row: ProjectInterface = Project.createProject({
      id: item.id,
      isFavorite: true,
    }) as ProjectInterface;
    row = transformFieldsToObject(item.fields, [], false, row);
    favoriteProjects.push(row);
  });
  return {
    ...state,
    favoriteProjectsLoading: false,
    favoriteProjects,
    favoriteProjectsNotFiltered: favoriteProjects,
    projects: favoriteProjects.map((item) => item.id),
  };
};
const handleGetFavoriteTemplatesSuccess = (state: FavoriteState, action: AnyAction) => {
  const { favoriteTemplates } = state;
  const items = action.payload.map(
    (el: { items: { fields: FieldResponseInterface[]; id: string }[] }) => el,
  );
  items.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let row: TemplateInterface = Template.createTemplate({
      id: item.id,
      isFavorite: true,
    }) as TemplateInterface;
    row = transformFieldsToObject(item.fields, [], false, row);
    row.MEMBERS = String(
      (row.FIAPAUTHORLIST ? (row.FIAPAUTHORLIST as string).split(',').length : 0) +
        (row.FIAPREVIEWER ? (row.FIAPREVIEWER as string).split(',').length : 0),
    );
    favoriteTemplates.push(row);
  });
  return {
    ...state,
    favoriteTemplatesLoading: false,
    favoriteTemplates,
    favoriteTemplatesNotFiltered: favoriteTemplates,
    templates: favoriteTemplates.map((item) => item.id),
  };
};

const handleFilterFavoriteProject = (state: FavoriteState, action: AnyAction) => {
  const { favoriteProjectsNotFiltered } = state;
  const filterFields = action.payload;
  let filteredFavoriteProjects = favoriteProjectsNotFiltered;
  filterFields.forEach((filter: FilterIterface) => {
    filteredFavoriteProjects = filterFavoriteObjects(
      filter.operator,
      filter.id,
      filter.value,
      filteredFavoriteProjects,
    );
  });
  return {
    ...state,
    favoriteProjects: filteredFavoriteProjects,
  };
};

const handleFilterFavoriteDocuments = (state: FavoriteState, action: AnyAction) => {
  const { favoriteDocumentsNotFiltered } = state;
  const { filterFields, order, orderBy } = action.payload;
  let filteredFavoriteDocuments = favoriteDocumentsNotFiltered;
  filterFields.forEach((filter: FilterIterface) => {
    filteredFavoriteDocuments = filterFavoriteObjects(
      filter.operator,
      filter.id,
      filter.value,
      filteredFavoriteDocuments,
    );
  });
  return {
    ...state,
    favoriteDocuments: filteredFavoriteDocuments.sort((a, b) =>
      sortingHandler(a, b, order, orderBy),
    ),
  };
};
const handleFilterFavoriteTemplates = (state: FavoriteState, action: AnyAction) => {
  const { favoriteTemplatesNotFiltered } = state;
  const { filterFields, order, orderBy } = action.payload;
  let filteredFavoriteTemplates = favoriteTemplatesNotFiltered;
  filterFields.forEach((filter: FilterIterface) => {
    filteredFavoriteTemplates = filterFavoriteObjects(
      filter.operator,
      filter.id,
      filter.value,
      filteredFavoriteTemplates,
    );
  });
  return {
    ...state,
    favoriteTemplates: filteredFavoriteTemplates.sort((a, b) =>
      sortingHandler(a, b, order, orderBy),
    ),
  };
};

const handleResetFavoriteTemplatesNotFiltered = (state: FavoriteState, action: AnyAction) => {
  return {
    ...state,
    favoriteTemplatesNotFiltered: [],
  };
};

const handleResetFavoriteDocumentsNotFiltered = (state: FavoriteState, action: AnyAction) => {
  return {
    ...state,
    favoriteDocumentsNotFiltered: [],
  };
};

export default function favoriteReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case favoriteActionTypes.SET_FAVORITE:
      return handleSetFavorite(state, action);
    case favoriteActionTypes.GET_FAVORITES:
      return handleGetFavorites(state, action);
    case favoriteActionTypes.GET_FAVORITE_DOCUMENTS_SUCCESS:
      return handleGetFavoriteDocumentsSuccess(state, action);
    case favoriteActionTypes.GET_FAVORITE_PROJECTS_SUCCESS:
      return handleGetFavoriteProjectsSuccess(state, action);
    case favoriteActionTypes.GET_FAVORITE_TEMPLATES_SUCCESS:
      return handleGetFavoriteTemplatesSuccess(state, action);
    case favoriteActionTypes.FILTER_FAVORITE_PROJECT:
      return handleFilterFavoriteProject(state, action);
    case favoriteActionTypes.FILTER_FAVORITE_DOCUMENT:
      return handleFilterFavoriteDocuments(state, action);
    case favoriteActionTypes.FILTER_FAVORITE_TEMPLATE:
      return handleFilterFavoriteTemplates(state, action);
    case favoriteActionTypes.RESET_FAVORITE_TEMPLATES_NOT_FILTERED:
      return handleResetFavoriteTemplatesNotFiltered(state, action);
    case favoriteActionTypes.RESET_FAVORITE_DOCUMENTS_NOT_FILTERED:
      return handleResetFavoriteDocumentsNotFiltered(state, action);
    default:
      return state;
  }
}
