import { OutlinedInputProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import { IAInput } from '../Input/Input';
import { useEffect, useState } from 'react';
import './Search.scss';

export interface SearchPropsTypes extends OutlinedInputProps {
  handleSearch?: (value: string) => void;
}

const IASearch = (props: SearchPropsTypes) => {
  const { handleSearch = () => {}, id, ...rest } = props;
  const type = props.type;

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch(inputValue);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  useEffect(() => {
    let savedSearchQuery;
    if (type === 'projectFilters') {
      savedSearchQuery = localStorage.getItem('savedSearchQueryProject');
      localStorage.removeItem('savedSearchQueryProject');
    } else if (type === 'documentFilters') {
      savedSearchQuery = localStorage.getItem('savedSearchQueryDocument');
      localStorage.removeItem('savedSearchQueryDocument');
    } else {
      savedSearchQuery = localStorage.getItem('savedSearchQueryTemplate');
      localStorage.removeItem('savedSearchQueryTemplate');
    }
    setInputValue(savedSearchQuery || '');
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  return (
    <div className='ia-search-input' data-testid='ia-search-input'>
      <IAInput
        icon={<Search />}
        placeholder='Search...'
        id={id}
        onChange={handleInputChange}
        value={inputValue}
        {...rest}
      />
    </div>
  );
};

export default IASearch;
