import AppliedFilters from '../Filter/AppliedFilters/AppliedFilters';
import Filter, { FilterProps } from '../Filter/Filter';
import IASearch, { SearchPropsTypes } from '../Search/Search';
import './TableHeader.scss';

type TableHeaderType = FilterProps &
  SearchPropsTypes & { title?: string; hideFilter?: boolean; isFromDocCreation?: boolean };

const IATableHeader = (props: TableHeaderType) => {
  const {
    title,
    categories,
    categoryValues,
    handleSearch,
    type = 'projectFilters',
    className = '',
    hideFilter = false,
    isFromDocCreation = false,
  } = props;

  return (
    <div className={`${className} table-header`}>
      <div className='table-header-top'>
        <div className='left'>
          {handleSearch ? (
            <IASearch handleSearch={handleSearch} type={type} />
          ) : (
            <div className='table-title'>{title || ''}</div>
          )}
        </div>
        {!hideFilter && (
          <div className='right'>
            <Filter
              categories={
                isFromDocCreation
                  ? categories?.filter(
                      (category) =>
                        category.id !== 'FIAPTEMPLATESTATUS' &&
                        category.id !== 'FCUSTTEMPLATETYPE' &&
                        category.id !== 'FCUSTPRODUCTAREA',
                    )
                  : categories
              }
              categoryValues={categoryValues}
              type={type}
            />
          </div>
        )}
      </div>

      <AppliedFilters type={type} isFromDocCreation={isFromDocCreation} />
    </div>
  );
};

export default IATableHeader;
