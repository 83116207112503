import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalState } from '../../core/models/state/globalState';
import './TemplateDetails.scss';
import IAButton from '../../components/Button/Button';
import { getTemplateById, updateTemplate } from '../../state/actions/template';
import TemplateData from '../../components/TemplateSteps/TemplateData';
import BasicDetails from '../../components/TemplateSteps/BasicDetails';
import IADialog from '../../components/Dialog/Dialog';
import {
  FieldsFilledInterface,
  FormValues,
  Member,
  SelectValue,
  IdLabel,
} from '../../core/models/global';
import TemplatePublishDialog from '../../components/TemplateSteps/TemplatePublishDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import ThreeDots from '../../components/icons/ThreeDots';
import Star from '../../components/icons/Star';
import { setFavorite } from '../../state/actions/favorite';
import { getLovValues, isFieldEmpty } from '../../core/services/helpers';
import { FIRST_STEP, SECOND_STEP, TEMPLATE_OPTIONAL_FIELDS } from '../../constants';
import AddMember from '../../components/icons/AddMember';
import { getInitials } from '../../core/services/userService';
import TemplateMembersDialog from '../../components/TemplateSteps/TemplateMembersDialog';
import { CountryGoup, TEMPLATE_TYPES } from '../../core/models/template/template';
import { getLovId, handleMultivalueDerivedFields } from '../../core/services/apiTranformations';
import { getProjectMetadataValues } from '../../state/actions/project';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { updateCountryGroupsValues } from '../../state/actions/system';

export const TemplateDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);
  const { template, templateObject, templateResponse } = useSelector(
    (state: GlobalState) => state.template,
  );
  const { template: templateDefinitions } = useSelector((state: GlobalState) => state.system);
  const { lovs } = templateDefinitions;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [fieldsFilled, setFieldsFilled] = useState<FieldsFilledInterface>({});
  const [editStep, setEditStep] = useState(0);
  const [publishDialog, setPublishDialog] = useState(false);
  const [unarchiveDialog, setUnarchiveDialog] = useState(false);
  const [updateTemplateBody, setUpdateTemplateBody] = useState<FormValues>();
  const [saving, setSaving] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [alertText, setAlertText] = useState<string>('');
  const [membersDialog, setMembersDialog] = useState(false);
  const [memberList, setMemberList] = useState<SelectValue[]>([]);

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [iconColor, setIconColor] = useState('');
  const [countryGroups, setCountryGroups] = useState<CountryGoup[]>([]);
  const [countryGroupsInitial, setCountryGroupsInitial] = useState<CountryGoup[]>([]);
  const [readMore, setReadMore] = useState(false);
  const [publishedTemplate, setPublishedTemplate] = useState(false);
  const characterCountReleaseNote = 105;
  const templateAuthors = (template?.FIAPAUTHORLIST as string)?.split(',') || [];
  const canChange =
    user?.isAdmin || (user?.isAuthor && templateAuthors.includes(user?.displayName));

  const menuItems = [
    {
      label: 'Review template',
      onClick: () => {
        navigate(`/layout/edit/template/${template.id}/review`);
      },
    },
    {
      label: 'View template history',
      onClick: () => {
        navigate(`/layout/edit/template/${template.id}/document-history`);
      },
    },
  ];

  const getData = () => {
    if (id && templateDefinitions?.fields?.length) {
      setIsDataLoaded(false);
      dispatch(
        getTemplateById(() => {
          setIsDataLoaded(true);
        }, id),
      );
    }
  };

  useEffect(() => {
    getData();
  }, [id, templateDefinitions.fields]);

  const isStepsCompleted = (data: FormValues): boolean => {
    const newEmptyFields: string[] = [];
    if (!data) {
      return false;
    }
    const optionalFields = TEMPLATE_OPTIONAL_FIELDS;
    if (
      getLovId(
        'FCUSTTEMPLATETYPE',
        template.FCUSTTEMPLATETYPE as string,
        templateDefinitions.fields,
        lovs,
      ) === TEMPLATE_TYPES.submission
    ) {
      optionalFields.push(...['report', 'reportTitle']);
    } else if (
      getLovId(
        'FCUSTTEMPLATETYPE',
        template.FCUSTTEMPLATETYPE as string,
        templateDefinitions.fields,
        lovs,
      ) === TEMPLATE_TYPES.ptqagreements
    ) {
      optionalFields.push(...['report', 'reportTitle', 'ctdCode', 'ctdTitle', 'stage', 'bbCode']);
    } else {
      optionalFields.push(...['ctdCode', 'ctdTitle']);
    }
    Object.entries(data).forEach(([key, value]) => {
      if (isFieldEmpty(optionalFields, key, value)) {
        newEmptyFields.push(key);
      }
    });
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const filterLovValues = (lovId: string, fieldId: string) => {
    if (template[fieldId]) {
      return getLovValues(lovs, lovId).filter((lov) =>
        (template[fieldId] as string).split(', ').includes(lov.label),
      );
    } else {
      return [];
    }
  };

  const getSelectedMembers = () => {
    const authors = (template?.FIAPAUTHORLIST as string)?.split(',') || [];
    const reviewers = (template?.FIAPREVIEWER as string)?.split(',') || [];
    const reviewersList = memberList
      .filter((el) => reviewers.includes(el.label))
      .map((el) => ({ value: el.id, label: el.label, role: 'Reviewer' }));
    const authorsList = memberList
      .filter((el) => authors.includes(el.label))
      .map((el) => ({ value: el.id, label: el.label, role: 'Author' }));
    return [...authorsList, ...reviewersList];
  };

  useEffect(() => {
    const selectedMembers = getSelectedMembers();
    if (!template.FIAPTEMPLATESTATUS) {
      template.FIAPTEMPLATESTATUS = 'Draft';
    }
    if (template.FIAPTEMPLATESTATUS === 'Published') {
      setPublishedTemplate(true);
    } else {
      setPublishedTemplate(false);
    }
    if (template.FCUSTCOUNTRYGROUP2) {
      const countriesOfCountryGroupField = templateResponse.find(
        (field) => field.id === 'FCUSTCOUNTRIESOFCOUNTRYGROUP',
      );
      if (countriesOfCountryGroupField) {
        setCountryGroups(
          Object.entries(handleMultivalueDerivedFields(countriesOfCountryGroupField)).map(
            ([countryGroupName, countries]) => ({
              countryGroupName,
              countries: countries as string[],
              disabled: true,
            }),
          ),
        );
        setCountryGroupsInitial(
          Object.entries(handleMultivalueDerivedFields(countriesOfCountryGroupField)).map(
            ([countryGroupName, countries]) => ({
              countryGroupName,
              countries: countries as string[],
              disabled: true,
            }),
          ),
        );
      }
    }
    if (template.isFavorite) {
      setIconColor('#FF7D29');
    } else {
      setIconColor('');
    }
    fieldsFilled[1] = {
      ...fieldsFilled[1],
      effectiveVersion: template.FCUSTEFFECTIVEVERSION as string,
      description: template.FDESCRIPTION as string,
      effectiveDate: template.FCUSTEFFECTIVEDATE as string,
      type: getLovValues(lovs, 'DCUSTTEMPLATETYPE').find(
        (type) => type.label === template.FCUSTTEMPLATETYPE,
      ) as SelectValue,
      members: selectedMembers,
    };
    fieldsFilled[SECOND_STEP] = {
      ...fieldsFilled[SECOND_STEP],
      productArea: filterLovValues('DCUSTPRODUCTAREA', 'FCUSTPRODUCTAREA'),
      stage: filterLovValues('DCUSTTEMPLATESTAGE', 'FCUSTTEMPLATESTAGE'),
      bbCode: filterLovValues('DCUSTBBCODE', 'FCUSTBBCODE'),
      report: filterLovValues('DCUSTREPORTCLASSIFICATION', 'FCUSTREPORTCLASSIFICATION')[0],
      reportTitle: filterLovValues('DCUSTREPORTTITLE', 'FCUSTREPORTTITLE'),
      ctdCode: filterLovValues('DCUSTCTDCODE', 'FCUSTCTDCODE')[0],
      ctdTitle: filterLovValues('DCUSTCTDTITLE', 'FCUSTCTDTITLE')[0],
      countryGroupName: template.FCUSTCOUNTRYGROUP as string,
      countries: filterLovValues('DCUSTCOUNTRY', 'FCUSTCOUNTRY'),
      title: template.FTITLE as string,
      agreementType: getLovValues(lovs, 'DCUSTAGREEMENTTYPE').find(
        (type) => type.label === template.FCUSTAGREEMENTTYPE,
      ) as SelectValue,
      diastudytype: getLovValues(lovs, 'DCUSTDIASTUDYTYPE').find(
        (type) => type.label === template.FCUSTDIASTUDYTYPE,
      ) as SelectValue,
      diastudysubtype: getLovValues(lovs, 'DCUSTDIASTUDYSUBTYPE').find(
        (type) => type.label === template.FCUSTDIASTUDYSUBTYPE,
      ) as SelectValue,
      DIAmQMSDocDeliverable: filterLovValues('DCUSTDIAMQMSDOCDELIVERABLE', 'FCUSTDIAMQMSDOCDELIVERABLE'),
      diacustomerarea: filterLovValues('DCUSTDIACUSTOMERAREA', 'FCUSTDIACUSTOMERAREA'), 
      diadignosticssolution: filterLovValues('DCUSTDIADIAGNOSTICSSOL', 'FCUSTDIADIAGNOSTICSSOL'),
      diastudysubtypevariant: filterLovValues('DCUSTSTUDYSUBTYPEVARIANT', 'FCUSTSTUDYSUBTYPEVARIANT'),
      dialifecyclestatus: getLovValues(lovs, 'DCUSTLIFECYCLESTATUS').find(
        (type) => type.label === template.FCUSTLIFECYCLESTATUS,
      ) as SelectValue,
    };
    setFieldsFilled({ ...fieldsFilled });
  }, [template, templateDefinitions, memberList]);

  
  const getArrayFieldValues = (key: string) => {
    if ((updateTemplateBody?.[key] as SelectValue[]).length === 0) {
      return undefined;
    }
    return (updateTemplateBody?.[key] as SelectValue[]).map((item) => item.id);
  }

  const handleSubmit = () => {
    if (!isStepsCompleted(updateTemplateBody as FormValues)) {
      setSaveClicked(true);
      return;
    }

    let body: { [key: string]: string | string[] | undefined};
    const FCUSTCOUNTRYGROUP2 = countryGroups
      .filter(
        (countryGroup) =>
          typeof countryGroup.countryGroupName !== 'string' ||
          getLovValues(lovs, 'DCUSTCOUNTRYGROUP').find(
            (cg) => cg.label === countryGroup.countryGroupName,
          ),
      )
      .map((countryGroup) => {
        if (typeof countryGroup.countryGroupName === 'string') {
          return (
            getLovValues(lovs, 'DCUSTCOUNTRYGROUP').find(
              (cg) => cg.label === countryGroup.countryGroupName,
            )?.id ?? ''
          );
        } else {
          return countryGroup.countryGroupName.id;
        }
      });
    setSaving(true);
    setSaveClicked(false);
    if (editStep === 1) {
      const effectiveDate = updateTemplateBody?.effectiveDate as string;
      body = {
        FCUSTEFFECTIVEVERSION: updateTemplateBody?.effectiveVersion as string,
        FDESCRIPTION: updateTemplateBody?.description as string,
        FCUSTTEMPLATETYPE: (updateTemplateBody?.type as SelectValue).id,
      };
      if (effectiveDate) {
        body.FCUSTEFFECTIVEDATE = effectiveDate.includes('+')
          ? effectiveDate.slice(0, effectiveDate.indexOf('+'))
          : effectiveDate;
      }
      dispatch(
        updateTemplate(
          (err) => {
            setSaving(false);
            if (!err) {
              getData();
              setEditStep(0);
            }
          },
          {
            body,
            id: id as string,
          },
        ),
      );
    } else {
      body = {
        FCUSTCTDCODE: (updateTemplateBody?.ctdCode as SelectValue).id,
        FCUSTCTDTITLE: (updateTemplateBody?.ctdTitle as SelectValue).id,
        FCUSTREPORTCLASSIFICATION: updateTemplateBody?.report
          ? (updateTemplateBody?.report as SelectValue).id
          : '',
        FCUSTREPORTTITLE: getArrayFieldValues('reportTitle'),
        FCUSTTEMPLATESTAGE: getArrayFieldValues('stage'),
        FCUSTBBCODE: getArrayFieldValues('bbCode'),
        FCUSTPRODUCTAREA: getArrayFieldValues('productArea'),
        FCUSTCOUNTRY: getArrayFieldValues('countries'),
        FTITLE: updateTemplateBody?.title as string,
        FCUSTAGREEMENTTYPE: ((updateTemplateBody as FormValues).agreementType as SelectValue).id,        
        FCUSTDIASTUDYTYPE:(
          (fieldsFilled[SECOND_STEP] as FormValues).diastudytype as SelectValue
        ).id,
        FCUSTDIASTUDYSUBTYPE:(
          (fieldsFilled[SECOND_STEP] as FormValues).diastudysubtype as SelectValue
        ).id,                
        FCUSTSTUDYSUBTYPEVARIANT:getArrayFieldValues('diastudysubtypevariant'),
        FCUSTLIFECYCLESTATUS:(
          (fieldsFilled[SECOND_STEP] as FormValues).dialifecyclestatus as SelectValue
        ).id,
        FCUSTDIACUSTOMERAREA:getArrayFieldValues('diacustomerarea'),
        FCUSTDIADIAGNOSTICSSOL:getArrayFieldValues('diadignosticssolution'),
        FCUSTDIAMQMSDOCDELIVERABLE:getArrayFieldValues('DIAmQMSDocDeliverable'),
      };
      dispatch(
        updateCountryGroupsValues(
          (error: unknown, ids: string[]) => {
            if (error) {
              setSaving(false);
              return setErrMessage('Something went wrong while saving the country groups');
            }
            if(body.FCUSTCOUNTRY === undefined) {
              body.FCUSTCOUNTRY = [];
            }
            FCUSTCOUNTRYGROUP2.push(...ids);
            body.FCUSTCOUNTRYGROUP2 = FCUSTCOUNTRYGROUP2;
            dispatch(
              updateTemplate(
                (err) => {
                  setSaving(false);
                  if (!err) {
                    getData();
                    setEditStep(0);
                  }
                },
                {
                  body,
                  id: id as string,
                },
              ),
            );
          },
          countryGroups.filter(
            (countryGroup) =>
              typeof countryGroup.countryGroupName === 'string' &&
              !getLovValues(lovs, 'DCUSTCOUNTRYGROUP').find(
                (cg) => cg.label === countryGroup.countryGroupName,
              ),
          ),
        ),
      );
    }
  };

  const publishTemplate = () => {
    setSaving(true);
    dispatch(
      updateTemplate(
        (err) => {
          setSaving(false);
          if (!err) {
            getData();
            setPublishDialog(false);
          } else {
            setErrMessage(
              'Not all components are released, you must release them all before you can publish the template',
            );
          }
        },
        {
          body: {
            FIAPTEMPLATESTATUS: 'VIAPTEMPLATESTATUSPUBLISHED',
          },
          id: id as string,
        },
      ),
    );
  };

  const handleFavoriteClick = () => {
    if (template) {
      const isFavorite = template.isFavorite;
      dispatch(
        setFavorite(
          () => {
            setAlertText(
              `Template ${template.FTITLE ?? ''} is ${
                isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'templates',
          template,
        ),
      );
    }
  };

  const setCountryGroupValues = (updatedCountryGroups: CountryGoup[]) => {
    setCountryGroups(updatedCountryGroups);
  };

  const getCountriesOfCountryGroup = (countryGroup: IdLabel) => {
    setSaving(true);
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            const countriesOfCountryGroup = fields.find(
              (field) => field.id === 'FCUSTCOUNTRIESOFCOUNTRYGROUP',
            )?.values[0].value;
            const selectedIndex = countryGroups.findIndex(
              (el) => (el.countryGroupName as IdLabel).id === countryGroup.id,
            );
            if (selectedIndex > -1) {
              countryGroups[selectedIndex] = {
                countryGroupName: countryGroup,
                countries: countriesOfCountryGroup as string[],
                disabled: true,
              };
            } else {
              countryGroups.push({
                countryGroupName: countryGroup,
                countries: countriesOfCountryGroup as string[],
                disabled: true,
              });
            }
            setCountryGroups([...countryGroups]);

            setSaving(false);
          }
        },
        {
          fields: [
            {
              id: 'FCUSTCOUNTRYGROUP2',
              type: 'multiLov',
              value: [
                {
                  id: countryGroup.id,
                },
              ],
            },
          ],
        },
      ),
    );
  };

  const getStatusButtonAndClassName = () => {
    if (template?.FIAPTEMPLATESTATUS === 'Draft') {
      return {
        button: (
          <IAButton
            className='main-action-btn'
            handleClick={() => navigate(`/layout/edit/template/${template.id}`)}
            data-testid='template-view'
          >
            Open Editor
          </IAButton>
        ),
        className: 'status-draft',
      };
    } else if (template?.FIAPTEMPLATESTATUS === 'Archived') {
      return {
        button: (
          <IAButton
            className='back-btn'
            handleClick={() => setUnarchiveDialog(true)}
            data-testid='template-unarchive'
          >
            Unarchive
          </IAButton>
        ),
        className: 'status-archived',
      };
    } else if (template?.FIAPTEMPLATESTATUS === 'Published') {
      return {
        button: (
          <IAButton
            className='main-action-btn'
            handleClick={() => navigate(`/layout/edit/template/${template.id}`)}
            data-testid='template-view'
          >
            Open Editor
          </IAButton>
        ),
        className: 'status-released',
      };
    } else {
      return {
        button: '',
        className: '',
      };
    }
  };

  const getDialogContent = () => {
    if (editStep === FIRST_STEP) {
      return (
        <BasicDetails
          fillStepFields={(_step, value) => {
            setUpdateTemplateBody(value);
            if (saveClicked) {
              isStepsCompleted(value);
            }
          }}
          fieldsFilled={(fieldsFilled[1] as FormValues) ?? {}}
          setResetValuesBasedOnType={() => {}}
          setActiveStep={(step) => {
            setEditStep(step);
          }}
          emptyFields={emptyFields}
          isEdit
        />
      );
    }

    if (editStep === SECOND_STEP) {
      return (
        <TemplateData
          type={((fieldsFilled[1] as FormValues)?.type as SelectValue)?.id}
          fillStepFields={(_step, value) => {
            setUpdateTemplateBody(value);
            if (saveClicked) {
              isStepsCompleted(value);
            }
          }}
          fieldsFilled={(fieldsFilled[SECOND_STEP] as FormValues) ?? {}}
          resetValuesBasedOnType='keep'
          setResetValuesBasedOnType={() => {}}
          setActiveStep={(step) => {
            setEditStep(step);
          }}
          emptyFields={emptyFields}
          countryGroups={countryGroups}
          setCountryGroupValues={setCountryGroupValues}
          getCountriesOfCountryGroup={getCountriesOfCountryGroup}
        />
      );
    }
  };

  const getAvatarInfo = () => {
    if (template.FIAPREVIEWER || template.FIAPAUTHORLIST) {
      const reviewers = template.FIAPREVIEWER ? (template.FIAPREVIEWER as string).split(',') : [];
      const authors = template.FIAPAUTHORLIST ? (template.FIAPAUTHORLIST as string).split(',') : [];
      return (
        <Tooltip title='Update members' placement='top'>
          <AvatarGroup
            className='project-card-avatar'
            max={4}
            onClick={(e) => {
              e.stopPropagation();
              setMembersDialog(true);
            }}
          >
            <Avatar alt='add member'>
              <AddMember />
            </Avatar>
            {[...reviewers, ...authors].map((el) => (
              <Avatar key={el} alt={el}>
                {getInitials(el)}
              </Avatar>
            ))}
          </AvatarGroup>
        </Tooltip>
      );
    }
    return (
      <AvatarGroup
        className='project-card-avatar'
        max={4}
        onClick={(e) => {
          e.stopPropagation();
          setMembersDialog(true);
        }}
      >
        <Tooltip title='Add member' placement='top'>
          <Avatar alt='add member'>
            <AddMember />
          </Avatar>
        </Tooltip>
      </AvatarGroup>
    );
  };

  const renderAvatar = () => {
    if (isDataLoaded && canChange) {
      return <div style={{ cursor: 'pointer', pointerEvents: 'auto' }}>{getAvatarInfo()}</div>;
    }
    return <></>;
  };

  const getTemplateCreator = () => {
    const creatorObject = templateObject?.fields?.find((el) => el.id === 'FIAPCREATEDBY')
      ?.values[0];
    return {
      userId: creatorObject?.value as string,
      displayName: creatorObject?.label as string,
    };
  };

  const handleUsers = (fields: RequiredMetadata[]) => {
    const field = fields.find((el) => el.id === 'FIAPPROJECTASSIGNEE');
    if (field) {
      setMemberList(
        field.values.map((item) => ({
          id: item.value as string,
          label: item.label as string,
        })),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            handleUsers(fields);
          }
        },
        {
          fields: [],
        },
      ),
    );
  }, [lovs]);

  const saveMembers = (data: Member[]) => {
    let authorList = data
      .filter((member) => member.role.toLowerCase().includes('author'))
      .map((el) => el.value);
    const templateCreator = getTemplateCreator();
    if (templateCreator?.userId && !authorList.includes(templateCreator?.userId)) {
      authorList = [...authorList, templateCreator?.userId];
    }
    const reviewerList = data
      .filter((member) => member.role.toLowerCase().includes('reviewer'))
      .map((el) => el.value);
    setSaveInProcess(true);
    dispatch(
      updateTemplate(
        (err) => {
          setSaveInProcess(false);
          if (err) {
            console.log(err);
          } else {
            getData();
            setMembersDialog(false);
          }
        },
        {
          id: id as string,
          body: {
            FIAPAUTHORLIST: authorList,
            FIAPREVIEWER: reviewerList,
          },
        },
      ),
    );
  };

  const getReleaseNote = () => {
    if (template?.FIAPTEMPLATESTATUS !== 'Published') {
      return null;
    }
    const note = template?.FIAPSTATUSTRANSITIONNOTE as string;
    if (note?.length > characterCountReleaseNote && !readMore) {
      return `${note.slice(0, characterCountReleaseNote)}...`;
    }
    return note || '-';
  };

  return (
    <div className='template-details' data-testid='template-details'>
      <div className='page-header'>
        <PageTitle
          name={template?.id === id ? (template.FTITLE as string) : ''}
          icon={<ArrowBackIcon />}
          onIconClick={() => navigate('/layout/templates')}
        />
        {isDataLoaded && (
          <div className='title-buttons' data-testid='template-title-buttons'>
            <Star
              className='favorite-icon'
              fill={iconColor}
              stroke={iconColor}
              onClick={() => {
                handleFavoriteClick();
              }}
            />
            <div
              className={`status ${getStatusButtonAndClassName().className}`}
              data-testid='template-status'
            >
              {template?.FIAPTEMPLATESTATUS}
            </div>
            {canChange && getStatusButtonAndClassName().button}
            <ThreeDots
              data-testid='template-three-dots'
              className='template-three-dots'
              aria-label='three-dots'
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            />
          </div>
        )}
      </div>
      <div className='page-content'>
        {isDataLoaded ? (
          <>
            <Accordion disabled={!getReleaseNote()}>
              <AccordionSummary
                expandIcon={getReleaseNote() ? <ExpandMoreIcon /> : null}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <div className='details-members-container'>
                  <div className='details' data-testid='details'>
                    <div>
                      <div className='detail-text'>Created By</div>
                      <div className='detail-text'>{template?.FIAPCREATEDBY}</div>
                    </div>
                    <div>
                      <div className='detail-text'>Last Modified By</div>
                      <div className='detail-text'>{template?.FIAPMODIFIEDBY ?? '-'}</div>
                    </div>
                    <div>
                      <div className='detail-text'>Date Modified</div>
                      <div className='detail-text'>
                        {template['MODIFIED-ON']
                          ? new Date(template['MODIFIED-ON'] as string).toLocaleDateString('en-GB')
                          : ''}
                      </div>
                    </div>
                    <div>
                      <div className='detail-text'>Version</div>
                      <div className='detail-text'>{template?.VERSION}</div>
                    </div>
                  </div>
                  {renderAvatar()}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='details' style={{ maxWidth: '85%' }}>
                  <div className='detail-text'>Release notes</div>
                  <div className='detail-text'>
                    {getReleaseNote()}{' '}
                    {(template?.FIAPSTATUSTRANSITIONNOTE as string)?.length >
                      characterCountReleaseNote &&
                      (!readMore ? (
                        <span className='read-more' onClick={() => setReadMore(true)}>
                          read more
                        </span>
                      ) : (
                        <span className='read-more' onClick={() => setReadMore(false)}>
                          read less...
                        </span>
                      ))}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className='form'>
              <BasicDetails
                title='Basic details'
                fillStepFields={() => {}}
                fieldsFilled={(fieldsFilled[1] as FormValues) ?? {}}
                setResetValuesBasedOnType={() => {}}
                isReview
                setActiveStep={(step) => {
                  setEditStep(step);
                }}
                canChange={canChange}
                published={publishedTemplate}
              />
              <TemplateData
                title='Template details'
                type={((fieldsFilled[1] as FormValues)?.type as SelectValue)?.id}
                fillStepFields={() => {}}
                fieldsFilled={(fieldsFilled[SECOND_STEP] as FormValues) ?? {}}
                resetValuesBasedOnType='keep'
                setResetValuesBasedOnType={() => {}}
                isReview
                setActiveStep={(step) => {
                  setEditStep(step);
                }}
                countryGroups={countryGroups}
                canChange={canChange}
                published={publishedTemplate}
              />
            </div>
          </>
        ) : (
          <div className='loader-container'>
            <CircularProgress value={30} size='80px' />
          </div>
        )}
      </div>
      <IADialog
        className='template-details'
        title={'Edit details'}
        open={!!editStep}
        handleClose={() => {
          if (saving) {
            return;
          }
          setEditStep(0);
          setEmptyFields([]);
          setUpdateTemplateBody({});
          setSaveClicked(false);
          setCountryGroups(countryGroupsInitial);
        }}
        handleSubmit={() => {
          handleSubmit();
        }}
        xButton
        mainBtnText={saving ? 'Saving...' : 'Save'}
        maxWidth='lg'
        disabled={saving}
      >
        {getDialogContent()}

        {saving && (
          <>
            <div className='loader-container with-bg'>
              <CircularProgress value={30} size='80px' />
            </div>
            <div className='loader-background'></div>
          </>
        )}
      </IADialog>
      <TemplatePublishDialog
        open={publishDialog}
        handleClose={() => setPublishDialog(false)}
        id={id as string}
        publishTemplate={publishTemplate}
        saving={saving}
      />
      <ConfirmationDialog
        open={unarchiveDialog}
        handleClose={() => setUnarchiveDialog(false)}
        mainBtnText='Unarchive'
        confirmationText={`Are you sure you want to unarchive the ${template?.FTITLE}?`}
        handleSubmit={() => {}}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errMessage}
        autoHideDuration={5000}
        onClose={() => setErrMessage('')}
      >
        <Alert
          onClose={() => setErrMessage('')}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {errMessage}
        </Alert>
      </Snackbar>
      <div className='options-menu'>
        <Menu
          disablePortal
          open={!!anchorEl}
          className='options-list'
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuList autoFocusItem={!!anchorEl}>
            {menuItems.map((menuItem) => {
              return (
                <MenuItem
                  key={menuItem.label}
                  className='menu-item'
                  onClick={() => menuItem.onClick()}
                >
                  {menuItem.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertText}
        autoHideDuration={5000}
        onClose={() => setAlertText('')}
      >
        <Alert
          onClose={() => setAlertText('')}
          severity='success'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <TemplateMembersDialog
        open={membersDialog}
        handleClose={() => setMembersDialog(false)}
        templateCreator={getTemplateCreator()}
        allUsers={memberList}
        save={(data) => saveMembers(data)}
        addedMembers={
          ((fieldsFilled[1] as FormValues)?.members as Member[])
            ? [...((fieldsFilled[1] as FormValues)?.members as Member[])]
            : []
        }
        saveInProcess={saveInProcess}
      />
    </div>
  );
};
