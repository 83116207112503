import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalState } from '../../core/models/state/globalState';
import { getWorkingDocumentById, updateWorkingDocument } from '../../state/actions/workingDocument';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import './WorkingDocumentDetails.scss';
import IATabs from '../../components/Tabs/Tabs';
import TabPanel from '../../components/DashboardTable/TabPanel';
import IAButton from '../../components/Button/Button';
import { OutputTable } from './OutputTable';
import { getProjectById } from '../../state/actions/project';
import { getTemplateById } from '../../state/actions/template';
import {
  ProductFamilies,
  SelectedProductMetadata,
} from '../../core/models/project/projectInterface';
import DocumentData from '../../components/DocumentSteps/DocumentData';
import BasicDetails from '../../components/DocumentSteps/BasicDetails';
import IADialog from '../../components/Dialog/Dialog';
import {
  FieldsFilledInterface,
  FormValues,
  Member,
  SelectValue,
  StepFieldsInterface,
} from '../../core/models/global';
import { FIRST_STEP, SECOND_STEP, documentAdditionalMetadataAndReports } from '../../constants';
import ThreeDots from '../../components/icons/ThreeDots';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { filterAdditionalMetadataFields } from '../../core/services/apiTranformations';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import {
  DefinitioniInterface,
  ItemsInterface,
  FieldResponseInterface,
  FieldResponseInterfaceValues,
} from '../../core/models/system/systemDefinition';
import Star from '../../components/icons/Star';
import { setFavorite } from '../../state/actions/favorite';
import { getSelectedMetadata } from '../../core/services/productMetadata';
import AddMember from '../../components/icons/AddMember';
import { getInitials } from '../../core/services/userService';
import DocumentMembersDialog from '../../components/DocumentSteps/DocumentMembersDialog';
import { getStatusClassName } from '../../core/services/helpers';

type DocumentBody = {
  step: number;
  stepData: StepFieldsInterface;
};

export const WorkingDocumentDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { user } = useSelector((state: GlobalState) => state.user);
  const { workingDocument, workingDocumentObject } = useSelector(
    (state: GlobalState) => state.workingDocument,
  );
  const { project: selectedProject, projects: projectsData } = useSelector(
    (state: GlobalState) => state.project,
  );
  const { template: selectedTemplate } = useSelector((state: GlobalState) => state.template);
  const {
    document: wdDefinitions,
    project: projectDefinitions,
    template: templateDefinitions,
  } = useSelector((state: GlobalState) => state.system);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [template, setTemplate] = useState<TemplateInterface>(selectedTemplate);
  const [fieldsFilled, setFieldsFilled] = useState<FieldsFilledInterface>({});
  const [activeTab, setActiveTab] = useState(0);
  const [editStep, setEditStep] = useState(0);
  const [templatePage, setTemplatePage] = useState(1);
  const [productMetadata, setProductMetadata] = useState<ProductFamilies[]>([]);
  const [updateDocumentBody, setUpdateDocumentBody] = useState<DocumentBody>();
  const [saving, setSaving] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [additionalMetadata, setAdditionalMetadata] = useState<RequiredMetadata[]>([]);

  const [additionalMetadataValues, setAdditionalMetadataValues] = useState<FormValues>({});
  const [additionalMetadataInitialValues, setAdditionalMetadataInitialValues] =
    useState<FormValues>({});
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [iconColor, setIconColor] = useState('');

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [membersDialog, setMembersDialog] = useState(false);
  const [projectMemberList, setProjectMemberList] = useState<FieldResponseInterfaceValues[]>([]);
  const [readMore, setReadMore] = useState(false);
  const characterCountReleaseNote = 105;
  const wdAuthors = (workingDocument?.FIAPAUTHORLIST as string)?.split(',') || [];
  const canChange = user?.isAdmin || (user?.isAuthor && wdAuthors.includes(user?.displayName));
  const menuItems = [
    {
      label: 'Review document',
      onClick: () => {
        navigate(`/layout/edit/document/${workingDocument.id}/review`);
      },
    },
    {
      label: 'View document history',
      onClick: () => {
        navigate(`/layout/edit/document/${workingDocument.id}/document-history`);
      },
    },
  ];

  const getSelectedMembers = () => {
    const authors = (workingDocument.FIAPAUTHORLIST as string)?.split(',') || [];
    const reviewers = (workingDocument.FIAPREVIEWER as string)?.split(',') || [];
    const reviewersList = projectMemberList
      .filter((el) => reviewers.includes(el.label as string))
      .map((el) => ({ value: el.value as string, label: el.label as string, role: 'Reviewer' }));
    const authorsList = projectMemberList
      .filter((el) => authors.includes(el.label as string))
      .map((el) => ({ value: el.value as string, label: el.label as string, role: 'Author' }));
    return [...authorsList, ...reviewersList];
  };

  const setListOfMembers = (projects: ItemsInterface[]) => {
    const projectItem = projects.find((el) => el.id === selectedProject.id);
    if (projectItem) {
      const result =
        (projectItem.fields as FieldResponseInterface[]).find(
          (field) => field.id === 'FIAPPROJECTASSIGNEE',
        )?.values || [];
      setProjectMemberList(result);
    }
  };

  useEffect(() => {
    if (hash === '#output') {
      setActiveTab(1);
    }
  }, [hash]);

  useEffect(() => {
    const selectedMembers = getSelectedMembers();
    fieldsFilled[FIRST_STEP] = {
      ...fieldsFilled[FIRST_STEP],
      members: {
        value: selectedMembers,
        required: false,
      },
    };
    setFieldsFilled({ ...fieldsFilled });
  }, [projectMemberList]);

  useEffect(() => {
    if (projectsData?.length && selectedProject?.id && !projectMemberList?.length) {
      setListOfMembers(projectsData);
    }
  }, [projectsData, selectedProject]);

  const isStepsCompleted = (stepData: StepFieldsInterface | SelectedProductMetadata): boolean => {
    const newEmptyFields: string[] = [];
    if (!stepData) {
      return false;
    }
    if (editStep === FIRST_STEP) {
      Object.entries(stepData).forEach(([key, item]) => {
        if (item.required && !item.value?.trim()) {
          newEmptyFields.push(key);
        }
      });
    }
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const getData = () => {
    if (id && wdDefinitions.fields?.length) {
      setIsDataLoaded(false);
      dispatch(
        getWorkingDocumentById(() => {
          setIsDataLoaded(true);
        }, id),
      );
    }
  };

  useEffect(() => {
    getData();
  }, [id, wdDefinitions.fields]);

  useEffect(() => {
    if (workingDocument.id !== id) {
      return;
    }
    if (workingDocument.isFavorite) {
      setIconColor('#FF7D29');
    } else {
      setIconColor('');
    }
    const selectedMembers = getSelectedMembers();
    fieldsFilled[FIRST_STEP] = {
      ...fieldsFilled[FIRST_STEP],
      FTITLE: {
        value: (workingDocument.FTITLE as string) || '',
        required: true,
      },
      FDESCRIPTION: {
        value: (workingDocument.FDESCRIPTION as string) || '',
        required: false,
      },
      selectedProject: {
        value: workingDocumentObject.fields.find((el) => el.id === 'FIAPPROJECTREF')?.values[0]
          .value as string,
        required: true,
      },
      selectedTemplate: {
        value: workingDocumentObject.fields.find((el) => el.id === 'FIAPTEMPLATEREF')?.values[0]
          .value as string,
        required: true,
      },
      members: {
        value: selectedMembers,
        required: false,
      },
    };
    if (workingDocument.FCUSTPRODUCTFAMILY) {
      const {
        selectedProductFamilies,
        selectedDrugProducts,
        selectedDrugSubstances,
        selectedPackagingCodes,
        selectedDPManufacturers,
        selectedDSManufacturers,
        selectedEventTypes,
        selectedEvents,
        selectedChangeCategory,
        diaProductType,
        diaMaterial,
        diaPRIMEProjectPPN,
        diaPRIMEProjectLPN,
        diaStudyType,
        diaStudySubtype,
        diaStudySubTypeVariant,
        diaLifeCycleStatus,
        diaDiagnosticsSolution,
        diaPRIMEProjectName,
        diaReportType,
        diamQMSDocDeliverable,
        diaProjectName,
        diaProjectNumber,
        diaLct,
        diaProductName,
        diaTestNumber,
        diaMaterialNameMedium,
        diaMaterialNameShort,
        diaMaterialNumber,
        diaInstrumentName,
        diaCustomerArea,
      } = getSelectedMetadata(workingDocument);
      fieldsFilled[SECOND_STEP] = {
        selectedProductFamilies,
        selectedDrugProducts,
        selectedDrugSubstances,
        selectedPackagingCodes,
        selectedDPManufacturers,
        selectedDSManufacturers,
        selectedEventTypes,
        selectedEvents,
        selectedChangeCategory,
        diaProductType,
        diaMaterial,
        diaInstrumentName,
        diaCustomerArea,
        diaPRIMEProjectPPN,
        diaPRIMEProjectLPN,
        diaStudyType,
        diaStudySubtype,
        diaStudySubTypeVariant,
        diaLifeCycleStatus,
        diaDiagnosticsSolution,
        diaPRIMEProjectName,
        diaReportType,
        diamQMSDocDeliverable,  
        diaProjectName,
        diaProjectNumber,
        diaLct,
        diaProductName,
        diaTestNumber,
        diaMaterialNameMedium,
        diaMaterialNameShort,
        diaMaterialNumber,
      };
      setFieldsFilled({ ...fieldsFilled });
    }
    if (workingDocument.FIAPPROJECTREF) {
      dispatch(
        getProjectById(() => {
          setIsDataLoaded(true);
        }, workingDocumentObject.fields.find((el) => el.id === 'FIAPPROJECTREF')?.values[0].value as string),
      );
    }
    if (workingDocument.FIAPTEMPLATEREF) {
      dispatch(
        getTemplateById(() => {
          setIsDataLoaded(true);
        }, workingDocumentObject.fields.find((el) => el.id === 'FIAPTEMPLATEREF')?.values[0].value as string),
      );
    }
  }, [workingDocument]);

  useEffect(() => {
    setTemplate(selectedTemplate);
  }, [selectedTemplate]);

  useEffect(() => {
    const tempAdditionalMetadata = filterAdditionalMetadataFields(
      documentAdditionalMetadataAndReports,
      wdDefinitions.fields.map((el) => ({
        definition: el,
        id: el.id,
        type: el.type,
        values: [],
      })),
      template,
    );
    const tempAdditionalMetadataValues: FormValues = {};
    workingDocumentObject.fields.forEach((el) => {
      if (tempAdditionalMetadata.find((metadata) => metadata.id === el.id)) {
        if (el.type === 'multiLov') {
          tempAdditionalMetadataValues[el.id] = el.values.map((v) => ({
            id: (v.value as DefinitioniInterface).id,
            label: (v.value as DefinitioniInterface).label,
          }));
        } else {
          tempAdditionalMetadataValues[el.id] = el.values?.[0]?.value;
        }
      }
    });
    setAdditionalMetadataValues(tempAdditionalMetadataValues);
    setAdditionalMetadataInitialValues({ ...tempAdditionalMetadataValues });
    setAdditionalMetadata(tempAdditionalMetadata);
  }, [workingDocument, template]);

  const handleAdditionalMetadataChange = (
    fieldId: string,
    value: string | SelectValue | SelectValue[],
  ) => {
    const field = wdDefinitions.fields.find((el) => el.id === fieldId);
    if (Array.isArray(value)) {
      let initialValues: SelectValue[] = [];
      if (field && field.flags?.isMultiValue) {
        initialValues = additionalMetadataInitialValues[fieldId] as SelectValue[];
      }
      initialValues.forEach((iv) => {
        if (value.findIndex((v) => iv.id === v.id) === -1) {
          value.push(iv);
        }
      });
    }

    setAdditionalMetadataValues((prevValue) => {
      return {
        ...prevValue,
        [fieldId]: value,
      };
    });
  };

  const fillStepFields = (
    step: number,
    value?: StepFieldsInterface | SelectedProductMetadata,
    templateObj?: TemplateInterface,
  ) => {
    if (value) {
      fieldsFilled[step] = value;
      setFieldsFilled({ ...fieldsFilled });
    }
    if (templateObj) {
      setTemplate({ ...templateObj });
    }
  };

  const handleSubmit = () => {
    const { step, stepData } = updateDocumentBody as DocumentBody;
    if (!isStepsCompleted(stepData)) {
      setSaveClicked(true);
      return;
    }
    setSaving(true);
    setSaveClicked(false);
    let data: WorkingDocumentInterface = {};
    if (step === FIRST_STEP) {
      data = {
        FTITLE: stepData.FTITLE.value as string,
        FDESCRIPTION: (stepData.FDESCRIPTION.value as string) ?? '',
      };
    }
    dispatch(
      updateWorkingDocument(
        (err) => {
          setSaving(false);
          if (!err) {
            getData();
            setEditStep(0);
          }
        },
        {
          body: data,
          id: id as string,
        },
      ),
    );
  };

  const handleFavoriteClick = () => {
    if (workingDocument) {
      const isFavorite = workingDocument.isFavorite;
      dispatch(
        setFavorite(
          () => {
            setAlertMessage(
              `Document ${workingDocument.FTITLE ?? ''} is ${
                isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'documents',
          workingDocument,
        ),
      );
    }
  };

  const getTabContent = () => {
    if (saving) {
      return (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      );
    }
    if (editStep === FIRST_STEP) {
      return (
        <BasicDetails
          projectFields={projectDefinitions?.fields}
          projectLovs={projectDefinitions?.lovs}
          templateFields={templateDefinitions?.fields}
          fillStepFields={(step, value) => {
            if (saveClicked) {
              isStepsCompleted(value);
            }
            setUpdateDocumentBody({ step, stepData: value as StepFieldsInterface });
          }}
          fieldsFilled={(fieldsFilled[1] as StepFieldsInterface) || {}}
          template={template}
          page={templatePage}
          isEdit={true}
          isReview={false}
          setPage={setTemplatePage}
          emptyFields={emptyFields}
        />
      );
    }

    if (editStep === SECOND_STEP) {
      return (
        <DocumentData
          fillStepFields={() => {}}
          fieldsFilled={(fieldsFilled[SECOND_STEP] as SelectedProductMetadata) ?? {}}
          giveProductMetadata={(data: ProductFamilies[]) => setProductMetadata(data)}
          productMetadata={productMetadata}
          emptyFields={emptyFields}
          additionalMetadata={additionalMetadata}
          additionalMetadataValues={additionalMetadataValues}
          handleAdditionalMetadataChange={handleAdditionalMetadataChange}
          projectId={
            ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedProject?.value as string) ??
            ''
          }
          templateId={
            ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedTemplate
              ?.value as string) ?? ''
          }
        />
      );
    }
    return '';
  };

  const getTabTitleAndStatus = () => {
    if (editStep === FIRST_STEP) {
      return {
        title: 'Edit basic details',
        open: true,
      };
    }
    if (editStep === SECOND_STEP) {
      return {
        title: 'Edit document data',
        open: true,
      };
    }
    return {
      title: '',
      open: false,
    };
  };

  const getAvatarInfo = () => {
    if (workingDocument.FIAPREVIEWER || workingDocument.FIAPAUTHORLIST) {
      const reviewers = workingDocument.FIAPREVIEWER
        ? (workingDocument.FIAPREVIEWER as string).split(',')
        : [];
      const authors = workingDocument.FIAPAUTHORLIST
        ? (workingDocument.FIAPAUTHORLIST as string).split(',')
        : [];
      return (
        <Tooltip title='Update members' placement='top'>
          <AvatarGroup
            className='project-card-avatar'
            max={4}
            onClick={(e) => {
              e.stopPropagation();
              setMembersDialog(true);
            }}
          >
            <Avatar alt='add member'>
              <AddMember />
            </Avatar>
            {[...reviewers, ...authors].map((el) => (
              <Avatar key={el} alt={el}>
                {getInitials(el)}
              </Avatar>
            ))}
          </AvatarGroup>
        </Tooltip>
      );
    }
    return (
      <AvatarGroup
        className='project-card-avatar'
        max={4}
        onClick={(e) => {
          e.stopPropagation();
          setMembersDialog(true);
        }}
      >
        <Tooltip title='Add member' placement='top'>
          <Avatar alt='add member'>
            <AddMember />
          </Avatar>
        </Tooltip>
      </AvatarGroup>
    );
  };

  const renderAvatar = () => {
    if (isDataLoaded && selectedProject?.id && canChange) {
      return <div style={{ cursor: 'pointer', pointerEvents: 'auto' }}>{getAvatarInfo()}</div>;
    }
    return <></>;
  };

  const getDocumentCreator = () => {
    const creatorObject = workingDocumentObject?.fields?.find((el) => el.id === 'FIAPCREATEDBY')
      ?.values[0];
    return {
      userId: creatorObject?.value as string,
      displayName: creatorObject?.label as string,
    };
  };

  const saveMembers = (data: Member[]) => {
    let authorList = data
      .filter((member) => member.role.toLowerCase().includes('author'))
      .map((el) => el.value);
    const documentCreator = getDocumentCreator();
    if (documentCreator?.userId && !authorList.includes(documentCreator?.userId)) {
      authorList = [...authorList, documentCreator?.userId];
    }

    const projectItem = projectsData.find((el) => el.id === selectedProject.id);
    if (projectItem) {
      const field = (projectItem.fields as FieldResponseInterface[]).find(
        (item) => item.id === 'FIAPCREATEDBY',
      );
      const creatorId = field?.values[0].value as string;
      if (creatorId && !authorList.includes(creatorId)) {
        authorList = [...authorList, creatorId];
      }
    }

    const reviewerList = data
      .filter((member) => member.role.toLowerCase().includes('reviewer'))
      .map((el) => el.value);
    setSaveInProcess(true);
    dispatch(
      updateWorkingDocument(
        (err) => {
          setSaveInProcess(false);
          if (err) {
            console.log(err);
          } else {
            getData();
            setMembersDialog(false);
          }
        },
        {
          id: id as string,
          body: {
            FIAPAUTHORLIST: authorList,
            FIAPREVIEWER: reviewerList,
          },
        },
      ),
    );
  };

  const getReleaseNote = () => {
    if (workingDocument?.FIAPDOCUMENTSTATUS !== 'Released') {
      return null;
    }
    const note = workingDocument?.FIAPSTATUSTRANSITIONNOTE as string;
    if (note?.length > characterCountReleaseNote && !readMore) {
      return `${note.slice(0, characterCountReleaseNote)}...`;
    }
    return note || '-';
  };

  return (
    <div className='working-document-details'>
      <div className='page-header'>
        <PageTitle
          name={workingDocument?.id === id ? (workingDocument.FTITLE as string) : ''}
          icon={
            <div className='title-back-icon'>
              <ArrowBackIcon />
            </div>
          }
          onIconClick={() => navigate('/layout/working-documents')}
        />
        {workingDocument?.id === id && (
          <div className='title-buttons' data-testid='working-document-title-buttons'>
            <Star
              className='favorite-icon'
              fill={iconColor}
              stroke={iconColor}
              onClick={() => {
                handleFavoriteClick();
              }}
            />
            <div
              className={`status ${getStatusClassName(
                workingDocument?.FIAPDOCUMENTSTATUS as string,
              )}`}
              data-testid='working-document-status'
            >
              {workingDocument?.FIAPDOCUMENTSTATUS}
            </div>
            {canChange && (
              <IAButton
                className='main-action-btn'
                handleClick={() => navigate(`/layout/edit/document/${workingDocument.id}/editor`)}
                data-testid='working-document-title-main-action-btn'
              >
                Open Editor
              </IAButton>
            )}
            <ThreeDots
              data-testid='document-three-dots'
              className='document-three-dots'
              aria-label='three-dots'
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            />
          </div>
        )}
      </div>
      <div className='page-content'>
        {isDataLoaded && workingDocument?.id === id ? (
          <>
            <Accordion disabled={!getReleaseNote()}>
              <AccordionSummary
                expandIcon={getReleaseNote() ? <ExpandMoreIcon /> : null}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <div className='details-members-container'>
                  <div className='details'>
                    <div>
                      <div className='detail-text'>Assigned to workspace</div>
                      <div className='detail-text'>{workingDocument.FIAPPROJECTREF}</div>
                    </div>
                    {workingDocument?.FCUSTCOUNTRY && (
                      <div>
                        <div className='detail-text'>Submission Countries</div>
                        <div className='detail-text'>{workingDocument?.FCUSTCOUNTRY}</div>
                      </div>
                    )}
                    <div>
                      <div className='detail-text'>Created By</div>
                      <div className='detail-text'>{workingDocument?.FIAPCREATEDBY}</div>
                    </div>
                    <div>
                      <div className='detail-text'>Last Modified By</div>
                      <div className='detail-text'>{workingDocument?.FIAPMODIFIEDBY}</div>
                    </div>
                    <div>
                      <div className='detail-text'>Date Modified</div>
                      <div className='detail-text'>
                        {workingDocument['MODIFIED-ON'] &&
                          new Date(workingDocument['MODIFIED-ON'] as string).toLocaleDateString(
                            'en-GB',
                          )}
                      </div>
                    </div>
                    <div>
                      <div className='detail-text'>Version</div>
                      <div className='detail-text'>{workingDocument?.VERSION}</div>
                    </div>
                  </div>
                  {renderAvatar()}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='details' style={{ maxWidth: '85%' }}>
                  <div className='detail-text'>Release notes</div>
                  <div className='detail-text'>
                    {getReleaseNote()}{' '}
                    {(workingDocument?.FIAPSTATUSTRANSITIONNOTE as string)?.length >
                      characterCountReleaseNote &&
                      (!readMore ? (
                        <span className='read-more' onClick={() => setReadMore(true)}>
                          read more
                        </span>
                      ) : (
                        <span className='read-more' onClick={() => setReadMore(false)}>
                          read less...
                        </span>
                      ))}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <IATabs
              tabs={[{ label: 'Document details' }, { label: 'Outputs' }]}
              defaultValue={activeTab}
              handleTabChange={(index) => {
                setActiveTab(index);
              }}
            />
            <TabPanel className='tab-panel' index={0} value={activeTab}>
              <div className='form'>
                <BasicDetails
                  title='Basic details'
                  projectFields={projectDefinitions?.fields}
                  projectLovs={projectDefinitions?.lovs}
                  templateFields={templateDefinitions?.fields}
                  fillStepFields={fillStepFields}
                  fieldsFilled={(fieldsFilled[FIRST_STEP] as StepFieldsInterface) ?? {}}
                  template={template}
                  isReview={true}
                  setActiveStep={(step) => {
                    setEditStep(step);
                  }}
                  canChange={canChange}
                />
                <DocumentData
                  type={selectedProject?.FCUSTPROJECTTYPE as string}
                  title='Document data'
                  fillStepFields={fillStepFields}
                  fieldsFilled={(fieldsFilled[SECOND_STEP] as SelectedProductMetadata) ?? {}}
                  giveProductMetadata={(data: ProductFamilies[]) => setProductMetadata(data)}
                  isReview={true}
                  productMetadata={productMetadata}
                  additionalMetadata={additionalMetadata}
                  additionalMetadataValues={additionalMetadataValues}
                  handleAdditionalMetadataChange={() => {}}
                  wdId={id}
                  projectId={
                    ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedProject
                      ?.value as string) ?? ''
                  }
                  templateId={
                    ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedTemplate
                      ?.value as string) ?? ''
                  }
                />
              </div>
            </TabPanel>
            <TabPanel className='tab-panel' index={1} value={activeTab}>
              <OutputTable documentId={id as string} />
            </TabPanel>
          </>
        ) : (
          <div className='loader-container'>
            <CircularProgress value={30} size='80px' />
          </div>
        )}
      </div>
      <IADialog
        className='working-document-details'
        title={getTabTitleAndStatus().title}
        open={getTabTitleAndStatus().open}
        handleClose={() => {
          if (saving) {
            return;
          }
          setEditStep(0);
          setEmptyFields([]);
          setUpdateDocumentBody(undefined);
          setSaveClicked(false);
        }}
        handleSubmit={() => {
          handleSubmit();
        }}
        xButton
        mainBtnText={saving ? 'Saving...' : 'Save'}
        maxWidth='lg'
        disabled={saving}
      >
        {getTabContent()}
      </IADialog>

      <div className='options-menu'>
        <Menu
          disablePortal
          open={!!anchorEl}
          className='options-list'
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuList autoFocusItem={!!anchorEl}>
            {menuItems.map((menuItem) => {
              return (
                <MenuItem
                  key={menuItem.label}
                  className='menu-item'
                  onClick={() => menuItem.onClick()}
                >
                  {menuItem.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertMessage}
        autoHideDuration={5000}
        onClose={() => setAlertMessage('')}
      >
        <Alert
          onClose={() => setAlertMessage('')}
          severity='success'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <DocumentMembersDialog
        open={membersDialog}
        handleClose={() => setMembersDialog(false)}
        save={(data) => saveMembers(data)}
        project={selectedProject}
        documentCreator={getDocumentCreator()}
        projectMemberList={projectMemberList}
        addedMembers={
          ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.members?.value as Member[]) ?? []
        }
        saveInProcess={saveInProcess}
      />
    </div>
  );
};
