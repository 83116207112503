import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import IASelect from '../Select/Select';
import IAMultiSelect from '../MultiSelect/MultiSelect';
import { FormValues, IdLabel, SelectValue } from '../../core/models/global';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import ReviewActionTitle from '../ReviewActionTitle/ReviewActionTitle';
import {
  SelectedProductMetadata,
  ProductFamilies,
} from '../../core/models/project/projectInterface';
import RemoveItem from '../icons/RemoveItem';
import AddMore from '../icons/AddMore';
import {
  RequiredMetadata,
  RequiredMetadataValues,
} from '../../core/models/workingDocument/requiredMetadata';
import AdditionalMetadata from './AdditionalMetadata';
import { LovValue } from '../../core/models/lovValue';
import {
  DefinitioniInterface,
  FieldResponseInterface,
} from '../../core/models/system/systemDefinition';
import { getProjectMetadataValues } from '../../state/actions/project';
import { GlobalState } from '../../core/models/state/globalState';
import {
  documentAdditionalMetadataAndReports,
  SECOND_STEP,
  FLATTEN_ARRAY_DEPTH,
} from '../../constants';
import {
  getProductFamilyById,
  getSelectedDrugProducts,
  getSelectedDrugSubstances,
  getFieldValues,
  getDSInReviewMode,
  getDPInReviewMode,
  getDSManufacturersInReviewMode,
  renderProductINNAndCode,
  getDPManufacturersInReviewMode,
  getPackagingCodesInReviewMode,
  renderDosageForm,
  renderDosageStrength,
} from '../../core/services/productMetadata';
import EventDetails from '../Events/Events';
import { getLovLabel } from '../../core/services/apiTranformations';

interface DocumentDataProps {
  title?: string;
  subtitle?: string;
  fillStepFields: (
    step: number,
    value: SelectedProductMetadata,
    template?: TemplateInterface,
  ) => void;
  fieldsFilled: SelectedProductMetadata;
  isReview?: boolean;
  setActiveStep?: (activeStep: number) => void;
  productMetadata: ProductFamilies[];
  projectMetadata?: SelectedProductMetadata;
  type?: string;
  emptyFields?: string[];
  additionalMetadata: RequiredMetadata[];
  additionalMetadataValues?: FormValues;
  handleAdditionalMetadataChange: (id: string, value: string | SelectValue | SelectValue[]) => void;
  giveProductMetadata: (data: ProductFamilies[]) => void;
  wdId?: string;
  projectId: string;
  templateId: string;
}

const DocumentData = (props: DocumentDataProps) => {
  const dispatch = useDispatch();
  const {
    title,
    subtitle,
    fillStepFields,
    fieldsFilled,
    isReview,
    setActiveStep,
    productMetadata,
    projectMetadata,
    emptyFields = [],
    additionalMetadata,
    handleAdditionalMetadataChange,
    additionalMetadataValues,
    giveProductMetadata,
    wdId,
    // projectId,
    // templateId,
  } = props;
  const { project, projectData } = useSelector((state: GlobalState) => state.project);
  const {
    project: { lovs },
  } = useSelector((state: GlobalState) => state.system);
  const {
    document: { fields: definitions },
  } = useSelector((state: GlobalState) => state.system);
  const [productFamilyList, setProductFamilyList] = useState<ProductFamilies[]>(
    (lovs?.DCUSTPRODUCTFAMILY || [])
      .filter((el) => !!wdId || projectMetadata?.selectedProductFamilies.includes(el.id))
      .map((item) => {
        const productFamily = productMetadata?.find((el) => el.id === item.id);
        return {
          id: item.id,
          label: item.label,
          productInn: productFamily?.productInn ?? '',
          productFamilyCode: productFamily?.productFamilyCode ?? '',
          roNumber: productFamily?.roNumber ?? '',
          drugProducts: productFamily?.drugProducts || [],
          drugSubstances: productFamily?.drugSubstances || [],
          eventTypes: productFamily?.eventTypes || [],
        };
      }),
  );
  const [selectedProductFamilies, setSelectedProductFamilies] = useState<string[]>(
    fieldsFilled?.selectedProductFamilies ?? ['TEMP'],
  );
  const [selectedDrugProducts, setSelectedDrugProducts] = useState<string[][]>(
    fieldsFilled?.selectedDrugProducts ?? [[]],
  );
  const [selectedDrugSubstances, setSelectedDrugSubstances] = useState<string[][]>(
    fieldsFilled?.selectedDrugSubstances ?? [[]],
  );
  const [selectedPackagingCodes, setSelectedPackagingCodes] = useState<string[][][]>(
    fieldsFilled?.selectedPackagingCodes ?? [[[]]],
  );
  const [selectedDPManufacturers, setSelectedDPManufacturers] = useState<string[][][]>(
    fieldsFilled?.selectedDPManufacturers ?? [[[]]],
  );
  const [selectedDSManufacturers, setSelectedDSManufacturers] = useState<string[][][]>(
    fieldsFilled?.selectedDSManufacturers ?? [[[]]],
  );
  const [selectedEventTypes, setSelectedEventTypes] = useState<IdLabel[]>(
    fieldsFilled?.selectedEventTypes?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTEVENTTYPE', el, definitions, lovs) ?? el,
    })) ?? [],
  );
  const [selectedEvents, setSelectedEvents] = useState<IdLabel[]>(
    fieldsFilled?.selectedEvents?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTEVENT', el, definitions, lovs) || el,
    })) ?? [],
  );
  const [DIAProductType, setDIAProductType] = useState<IdLabel>(
      fieldsFilled?.diaProductType ? { id: fieldsFilled.diaProductType, label: getLovLabel('FCUSTDIAPRODUCTTYPE', fieldsFilled.diaProductType, definitions, lovs) ?? fieldsFilled.diaProductType } : { id: '', label: '' }
    );
  const [diaMaterial, setdiaMaterial] = useState<IdLabel[]>(
      fieldsFilled?.diaMaterial?.map((el) => ({
        id: el,
        label: getLovLabel('FCUSTDPMATERIAL', el, definitions, lovs) || el,
      })) ?? [],
    );
    const [diaInstrumentName, setdiaInstrumentName] = useState<IdLabel[]>(
      fieldsFilled?.diaInstrumentName?.map((el) => ({
        id: el,
        label: getLovLabel('FCUSTDIAINSTRUMENTNAME', el, definitions, lovs) || el,
      })) ?? [],
    );
    const [diaCustomerArea, setdiaCustomerArea] = useState<IdLabel[]>(
      fieldsFilled?.diaCustomerArea?.map((el) => ({
        id: el,
        label: getLovLabel('FCUSTDIACUSTOMERAREA', el, definitions, lovs) || el,
      })) ?? [],
    );
  // diaInstrumentName
  const [diaPRIMEProjectPPN, setdiaPRIMEProjectPPN] = useState<IdLabel>(
    fieldsFilled?.diaPRIMEProjectPPN ? { id: fieldsFilled.diaPRIMEProjectPPN, label: getLovLabel('FCUSTPRIMEPROJECTPPN', fieldsFilled.diaPRIMEProjectPPN, definitions, lovs) ?? fieldsFilled.diaPRIMEProjectPPN } : { id: '', label: '' }
  );
  const [diaPRIMEProjectLPN, setdiaPRIMEProjectLPN] = useState<IdLabel>(
    fieldsFilled?.diaPRIMEProjectLPN ? { id: fieldsFilled.diaPRIMEProjectLPN, label: getLovLabel('FCUSTPRIMEPROJECTNAME', fieldsFilled.diaPRIMEProjectLPN, definitions, lovs) ?? fieldsFilled.diaPRIMEProjectLPN } : { id: '', label: '' }
  );
  const [diaStudyType, setdiaStudyType] = useState<IdLabel>(
    fieldsFilled?.diaStudyType ? { id: fieldsFilled.diaStudyType, label: getLovLabel('FCUSTDIASTUDYTYPE', fieldsFilled.diaStudyType, definitions, lovs) ?? fieldsFilled.diaStudyType } : { id: '', label: '' }
  );
  const [diaStudySubType, setdiastudysubtype] = useState<IdLabel>(
    fieldsFilled?.diaStudySubtype ? { id: fieldsFilled.diaStudySubtype, label: getLovLabel('FCUSTDIASTUDYSUBTYPE', fieldsFilled.diaStudySubtype, definitions, lovs) ?? fieldsFilled.diaStudySubtype } : { id: '', label: '' }
  );
  const [diaStudySubTypeVariant] = useState<IdLabel[]>(
    Array.isArray(fieldsFilled?.diaStudySubTypeVariant)
      ? fieldsFilled.diaStudySubTypeVariant.map((el) => ({
          id: el,
          label: getLovLabel('FCUSTSTUDYSUBTYPEVARIANT', el, definitions, lovs) || el,
        }))
      : [],
  );
  const [diaLifeCycleStatus, setdiaLifeCycleStatus] = useState<IdLabel>(
    fieldsFilled?.diaLifeCycleStatus ? { id: fieldsFilled.diaLifeCycleStatus, label: getLovLabel('FCUSTLIFECYCLESTATUS', fieldsFilled.diaLifeCycleStatus, definitions, lovs) ?? fieldsFilled.diaLifeCycleStatus } : { id: '', label: '' }
  );
  const [diaDiagnosticsSolution] = useState<IdLabel[]>(
    Array.isArray(fieldsFilled?.diaDiagnosticsSolution)
      ? fieldsFilled.diaDiagnosticsSolution.map((el) => ({
          id: el,
          label: getLovLabel('FCUSTDIADIAGNOSTICSSOL', el, definitions, lovs) || el,
        }))
      : [],
  );
  
  const [diaPRIMEProjectName, setdiaPRIMEProjectName] = useState<IdLabel>(
    fieldsFilled?.diaPRIMEProjectName ? { id: fieldsFilled.diaPRIMEProjectName, label: getLovLabel('FCUSTPRIMEPROJECTLPN', fieldsFilled.diaPRIMEProjectName, definitions, lovs) ?? fieldsFilled.diaPRIMEProjectName } : { id: '', label: '' }
  );
  const [diaReportType, setdiaReportType] = useState<IdLabel>(
    fieldsFilled?.diaReportType ? { id: fieldsFilled.diaReportType, label: getLovLabel('FCUSTDIAREPORTTYPE', fieldsFilled.diaReportType, definitions, lovs) ?? fieldsFilled.diaReportType } : { id: '', label: '' }
  );
  
  const [DIAmQMSDocDeliverable, setDIAmQMSDocDeliverable] = useState<IdLabel>(
    fieldsFilled?.diamQMSDocDeliverable ? { id: fieldsFilled.diamQMSDocDeliverable, label: getLovLabel('FCUSTDIAPRODUCTTYPE', fieldsFilled.diamQMSDocDeliverable, definitions, lovs) ?? fieldsFilled.diamQMSDocDeliverable } : { id: '', label: '' }
  );
  const [diaMaterialNumber, setdiaMaterialNumber] = useState<IdLabel>(
    fieldsFilled?.diaMaterialNumber ? { id: fieldsFilled.diaMaterialNumber, label: getLovLabel('FCUSTDIAMATERIALNUMBER', fieldsFilled.diaMaterialNumber, definitions, lovs) ?? fieldsFilled.diaMaterialNumber } : { id: '', label: '' }
  );
  const [diaMaterialNameShort, setdiaMaterialNameShort] = useState<IdLabel>(
    fieldsFilled?.diaMaterialNameShort ? { id: fieldsFilled.diaMaterialNameShort, label: getLovLabel('FCUSTDIAMATNAMESHORT', fieldsFilled.diaMaterialNameShort, definitions, lovs) ?? fieldsFilled.diaMaterialNameShort } : { id: '', label: '' }
  );
  const [diaMaterialNameMedium, setdiaMaterialNameMedium] = useState<string[]>(
    fieldsFilled?.diaMaterialNameMedium ?? [],
  );
  
  const [selectedChangeCategory] = useState<string[]>(fieldsFilled?.selectedChangeCategory ?? []);
  
  const [diaProjectName, setdiaProjectName] = useState<IdLabel>(
    fieldsFilled?.diaProjectName ? { id: fieldsFilled.diaProjectName, label: getLovLabel('FCUSTDIAPROJECTNAME', fieldsFilled.diaProjectName, definitions, lovs) ?? fieldsFilled.diaProjectName } : { id: '', label: '' }
  );
  useEffect(() => {
    fillStepFields(SECOND_STEP, {
      selectedProductFamilies,
      selectedDrugProducts,
      selectedDrugSubstances,
      selectedPackagingCodes,
      selectedDPManufacturers,
      selectedDSManufacturers,
      selectedChangeCategory: fieldsFilled.selectedChangeCategory,
      selectedEventTypes: selectedEventTypes.map((el) => el.id),
      selectedEvents: selectedEvents.map((el) => el.id),
      diaProductType: DIAProductType.id,
      diaReportType: diaReportType.id,
      diaMaterial: diaMaterial.map((el) => el.id),
      diaPRIMEProjectPPN: diaPRIMEProjectPPN.id,
      diaPRIMEProjectLPN: diaPRIMEProjectLPN.id,
      diaStudyType: diaStudyType.id,
      diaStudySubtype: diaStudySubType.id,
      diaStudySubTypeVariant: diaStudySubTypeVariant?.map((el) => el.id),
      diaLifeCycleStatus: diaLifeCycleStatus.id,
      diaDiagnosticsSolution: diaDiagnosticsSolution?.map((el) => el.id),
      diaPRIMEProjectName: diaPRIMEProjectName.id,
      diamQMSDocDeliverable: DIAmQMSDocDeliverable.id,
      diaProjectName: diaProjectName.id,
      diaProjectNumber: typeof project.FPROJECTNUMBER === 'string' ? project.FPROJECTNUMBER : '',
      diaLct: typeof project.FLCT === 'string' ? project.FLCT : '',
      diaProductName: typeof project.FPRODUCTNAME === 'string' ? project.FPRODUCTNAME : '',
      diaTestNumber: typeof project.FTESTNUMBER === 'string' ? project.FTESTNUMBER : '',
      diaMaterialNameMedium:  diaMaterialNameMedium.map((el) => el),
      diaMaterialNameShort:  diaMaterialNameShort.label,
      diaMaterialNumber: diaMaterialNumber.id,
      diaInstrumentName: diaInstrumentName?.map((el) => el.id),
      diaCustomerArea: diaCustomerArea?.map((el) => el.id),
    });
  }, [
    selectedProductFamilies,
    selectedDrugProducts,
    selectedDrugSubstances,
    selectedPackagingCodes,
    selectedDPManufacturers,
    selectedDSManufacturers,
    selectedEventTypes,
    selectedEvents,
    DIAProductType,
    diaMaterial,
    diaInstrumentName,
    diaCustomerArea,
    diaPRIMEProjectPPN,
    diaPRIMEProjectLPN,
    diaStudyType,
    diaStudySubType,
    diaReportType,
    diaMaterialNumber,
    diaMaterialNameShort,
    diaMaterialNameMedium,
    diaStudySubTypeVariant,
  ]);

  useEffect(() => {
    giveProductMetadata(
      productFamilyList.filter(
        (el) => el.drugProducts?.length || el.drugSubstances?.length || el.roNumber,
      ),
    );
  }, [productFamilyList]);

  const setProductFamilyValues = (fields: RequiredMetadata[], id: string) => {
    const data = getFieldValues(
      fields,
      [
        'FCUSTPRODUCT',
        'FCUSTSUBSTANCE',
        'FCUSTPRODUCTFAMINN',
        'FCUSTPRODFAMFAMILYCODE',
        'FCUSTPRODFAMROCHENUM',
        'FCUSTEVENTTYPE',
        'FCUSTEVENT',
      ],
      definitions,
    );

    const {
      FCUSTPRODUCT: drugProductValues,
      FCUSTSUBSTANCE: drugSubstanceValues,
      FCUSTPRODUCTFAMINN: productInn,
      FCUSTPRODFAMFAMILYCODE: productFamilyCode,
      FCUSTPRODFAMROCHENUM: roNumber,
    } = data;
    const productFamily = getProductFamilyById(id, productFamilyList);
    if (productFamily) {
      productFamily.drugProducts = (drugProductValues as RequiredMetadataValues[])
        .map((el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }))
        .filter(
          (item) =>
            !!wdId ||
            projectMetadata?.selectedDrugProducts.flat(FLATTEN_ARRAY_DEPTH).includes(item.id),
        );
      productFamily.drugSubstances = (drugSubstanceValues as RequiredMetadataValues[])
        .map((el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }))
        .filter(
          (item) =>
            !!wdId ||
            projectMetadata?.selectedDrugSubstances.flat(FLATTEN_ARRAY_DEPTH).includes(item.id),
        );
      productFamily.productInn = productInn as string;
      productFamily.productFamilyCode = productFamilyCode as string;
      productFamily.roNumber = roNumber as string;
      setProductFamilyList([...productFamilyList]);
    }
    return productFamily;
  };

  const setProductValues = (fields: RequiredMetadata[], id: string, productFamilyId: string) => {
    const data = getFieldValues(
      fields,
      ['FCUSTDOSAGEFORM', 'FCUSTDOSAGESTRENGTH', 'FCUSTPACKAGINGCODE', 'FCUSTDPMANUFACTURER'],
      definitions,
    );
    const {
      FCUSTDOSAGEFORM: dosageForm,
      FCUSTDOSAGESTRENGTH: dosageStrength,
      FCUSTPACKAGINGCODE: packagingCodeValues,
      FCUSTDPMANUFACTURER: dpManufacturersValues,
    } = data;
    const productFamily = getProductFamilyById(productFamilyId, productFamilyList);
    const drugProduct = productFamily?.drugProducts.find((el) => el.id === id);
    if (drugProduct) {
      drugProduct.dosageForm = dosageForm as string;
      drugProduct.dosageStrength = dosageStrength as string;
      drugProduct.packagingCodes = (packagingCodeValues as RequiredMetadataValues[])
        .map((el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }))
        .filter(
          (item) =>
            !!wdId ||
            projectMetadata?.selectedPackagingCodes.flat(FLATTEN_ARRAY_DEPTH).includes(item.id),
        );
      drugProduct.dpManufacturers = (dpManufacturersValues as RequiredMetadataValues[])
        .map((el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }))
        .filter(
          (item) =>
            !!wdId ||
            projectMetadata?.selectedDPManufacturers.flat(FLATTEN_ARRAY_DEPTH).includes(item.id),
        );
      setProductFamilyList([...productFamilyList]);
    }
  };

  const setSubstanceValues = (fields: RequiredMetadata[], id: string, productFamilyId: string) => {
    const data = getFieldValues(fields, ['FCUSTDSMANUFACTURER'], definitions);
    const { FCUSTDSMANUFACTURER: dsManufacturersValues } = data;
    const productFamily = getProductFamilyById(productFamilyId, productFamilyList);
    const drugSubstance = productFamily?.drugSubstances.find((el) => el.id === id);
    if (drugSubstance) {
      drugSubstance.dsManufacturers = (dsManufacturersValues as RequiredMetadataValues[])
        .map((el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }))
        .filter(
          (item) =>
            !!wdId ||
            projectMetadata?.selectedDSManufacturers.flat(FLATTEN_ARRAY_DEPTH).includes(item.id),
        );
      setProductFamilyList([...productFamilyList]);
    }
  };

  const getProductsValues = (productFamilyId: string) => {
    if (fieldsFilled?.selectedDrugProducts) {
      fieldsFilled?.selectedDrugProducts
        .flat(FLATTEN_ARRAY_DEPTH)
        .forEach((selectedDrugProduct: string) => {
          dispatch(
            getProjectMetadataValues(
              (err, fields: RequiredMetadata[]) => {
                if (!err && fields) {
                  setProductValues(fields, selectedDrugProduct, productFamilyId);
                }
              },
              {
                fields: [
                  {
                    id: 'FCUSTPRODUCTFAMILY',
                    type: 'multiLov',
                    value: [
                      {
                        id: productFamilyId,
                      },
                    ],
                  },
                  {
                    id: 'FCUSTPRODUCT',
                    type: 'multiLov',
                    value: [
                      {
                        id: selectedDrugProduct,
                      },
                    ],
                  },
                ],
              },
            ),
          );
        });
    }
  };

  const getSubstanceValues = (productFamilyId: string) => {
    if (fieldsFilled?.selectedDrugSubstances) {
      fieldsFilled?.selectedDrugSubstances
        .flat(FLATTEN_ARRAY_DEPTH)
        .forEach((selectedDrugSubstance: string) => {
          dispatch(
            getProjectMetadataValues(
              (err, fields: RequiredMetadata[]) => {
                if (!err && fields) {
                  setSubstanceValues(fields, selectedDrugSubstance, productFamilyId);
                }
              },
              {
                fields: [
                  {
                    id: 'FCUSTPRODUCTFAMILY',
                    type: 'multiLov',
                    value: [
                      {
                        id: productFamilyId,
                      },
                    ],
                  },
                  {
                    id: 'FCUSTSUBSTANCE',
                    type: 'multiLov',
                    value: [
                      {
                        id: selectedDrugSubstance,
                      },
                    ],
                  },
                ],
              },
            ),
          );
        });
    }
  };

  const findAndSetSelectedEvent = (fields: RequiredMetadata[]) => {
    const events = fields
      .find((el) => el.id === 'FCUSTEVENT')
      ?.values.map((el) => ({
        id: (el.value as LovValue).id,
        label: (el.value as LovValue).label,
      }));
    const projectEvents = (projectData.fields as FieldResponseInterface[])
      .find((el) => el.id === 'FCUSTEVENT')
      ?.values.map((el) => ({
        id: (el.value as LovValue).id,
        label: (el.value as LovValue).label,
        dependencies: el.dependencies,
      }));

    projectEvents?.forEach((projectEvent) => {
      if (events?.find((event) => event.id === projectEvent.id)) {
        const eventType = projectEvent.dependencies?.find(
          (el) => el.id === 'FCUSTEVENTTYPE',
        )?.value;
        if (eventType) {
          setSelectedEventTypes([eventType as LovValue]);
        }
        setSelectedEvents([projectEvent]);
      }
    });
  };

  const getEventsValues = (productFamilyId: string) => {
    if (project?.FCUSTEVENTTYPE) {
      const eventTypes =
        (projectData.fields as FieldResponseInterface[])
          .find((el) => el.id === 'FCUSTEVENTTYPE')
          ?.values.map((el) => ({
            id: (el.value as LovValue).id,
          })) || [];
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              findAndSetSelectedEvent(fields);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: productFamilyId,
                  },
                ],
              },
              {
                id: 'FCUSTEVENTTYPE',
                type: 'multiLov',
                value: eventTypes,
              },
            ],
          },
        ),
      );
    }
  };

  const handleSelectedProductFamilies = (selectedProductFamily: string) => {
    if (selectedProductFamily !== 'TEMP') {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const productFamily = setProductFamilyValues(fields, selectedProductFamily);
              if (productFamily) {
                Object.entries(documentAdditionalMetadataAndReports)
                  .filter(([, value]) => value.includes('inn'))
                  .forEach(([key]) => {
                    handleAdditionalMetadataChange(key, productFamily?.productInn[0]);
                  });
                getProductsValues(selectedProductFamily);
                getSubstanceValues(selectedProductFamily);
                if (project.FCUSTEVENT) {
                  getEventsValues(selectedProductFamily);
                }
              }
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: selectedProductFamily,
                  },
                ],
              },
            ],
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (fieldsFilled?.selectedProductFamilies && (!isReview || wdId)) {
      fieldsFilled?.selectedProductFamilies.forEach((selectedProductFamily: string) => {
        handleSelectedProductFamilies(selectedProductFamily);
      });
    }
  }, [fieldsFilled?.selectedProductFamilies]);

  const onChangeProductFamily = (
    index: number,
    _event: React.SyntheticEvent,
    value: SelectValue,
  ) => {
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            setProductFamilyValues(fields, value.id);
          }
        },
        {
          fields: [
            {
              id: 'FCUSTPRODUCTFAMILY',
              type: 'multiLov',
              value: [
                {
                  id: value.id,
                },
              ],
            },
          ],
        },
      ),
    );
    selectedProductFamilies[index] = value.id;
    setSelectedProductFamilies([...selectedProductFamilies]);

    selectedDrugProducts[index] = [`TEMP_${value.id}`];
    setSelectedDrugProducts([...selectedDrugProducts]);

    selectedDrugSubstances[index] = [`TEMP_${value.id}`];
    setSelectedDrugSubstances([...selectedDrugSubstances]);
  };

  const onChangeProduct = (
    _event: React.SyntheticEvent,
    index: number,
    upperIndex: number,
    productFamilyId: string,
    value: SelectValue,
  ) => {
    const tempIndex = selectedDrugProducts[upperIndex].findIndex(
      (el) => el === `TEMP_${productFamilyId}`,
    );
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              setProductValues(fields, value.id, productFamilyId);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: productFamilyId,
                  },
                ],
              },
              {
                id: 'FCUSTPRODUCT',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
      if (tempIndex === -1) {
        selectedDrugProducts[upperIndex][index] = value.id;
      } else {
        selectedDrugProducts[upperIndex][tempIndex] = value.id;
      }
    } else if (tempIndex === -1) {
      selectedDrugProducts[upperIndex][index] = `TEMP_${productFamilyId}`;
    } else {
      selectedDrugProducts[upperIndex][tempIndex] = `TEMP_${productFamilyId}`;
    }

    setSelectedDrugProducts([...selectedDrugProducts]);

    if (!selectedPackagingCodes[upperIndex]) {
      selectedPackagingCodes[upperIndex] = [];
    }
    selectedPackagingCodes[upperIndex][index] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    if (!selectedDPManufacturers[upperIndex]) {
      selectedDPManufacturers[upperIndex] = [];
    }
    selectedDPManufacturers[upperIndex][index] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const onChangeDrugSubstance = (
    _event: React.SyntheticEvent,
    index: number,
    upperIndex: number,
    productFamilyId: string,
    value: SelectValue,
  ) => {
    const tempIndex = selectedDrugSubstances[upperIndex].findIndex(
      (el) => el === `TEMP_${productFamilyId}`,
    );
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              setSubstanceValues(fields, value.id, productFamilyId);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: productFamilyId,
                  },
                ],
              },
              {
                id: 'FCUSTSUBSTANCE',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
      if (tempIndex === -1) {
        selectedDrugSubstances[upperIndex][index] = value.id;
      } else {
        selectedDrugSubstances[upperIndex][tempIndex] = value.id;
      }
    } else if (tempIndex === -1) {
      selectedDrugSubstances[upperIndex][index] = `TEMP_${productFamilyId}`;
    } else {
      selectedDrugSubstances[upperIndex][tempIndex] = `TEMP_${productFamilyId}`;
    }

    setSelectedDrugSubstances([...selectedDrugSubstances]);

    if (!selectedDSManufacturers[upperIndex]) {
      selectedDSManufacturers[upperIndex] = [];
    }
    selectedDSManufacturers[upperIndex][index] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const onChangePackagingCode = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedPackagingCodes[upperIndex][index] = values.map((el) => el.id);
    setSelectedPackagingCodes([...selectedPackagingCodes]);
  };

  const onChangeDPManufacturers = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedDPManufacturers[upperIndex][index] = values.map((el) => el.id);
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const onChangeDSManufacturers = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedDSManufacturers[upperIndex][index] = values.map((el) => el.id);
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const removeProductFamily = (upperIndex: number, selectedProductFamilyId: string) => {
    setSelectedProductFamilies(
      selectedProductFamilies.filter(
        (selectedProductFamily) => selectedProductFamily !== selectedProductFamilyId,
      ),
    );
    selectedDrugProducts[upperIndex] = [];
    setSelectedDrugProducts([...selectedDrugProducts]);
    selectedDrugSubstances[upperIndex] = [];
    setSelectedDrugSubstances([...selectedDrugSubstances]);
    selectedPackagingCodes[upperIndex] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    selectedDPManufacturers[upperIndex] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
    selectedDSManufacturers[upperIndex] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const addProduct = (upperIndex: number, productFamilyId: string) => {
    if (
      selectedDrugProducts[upperIndex].find(
        (selectedDrugProduct) => selectedDrugProduct === `TEMP_${productFamilyId}`,
      )
    ) {
      return;
    }
    selectedDrugProducts[upperIndex].push(`TEMP_${productFamilyId}`);
    setSelectedDrugProducts([...selectedDrugProducts]);
  };

  const removeProduct = (upperIndex: number, index: number, selectedProductId: string) => {
    selectedDrugProducts[upperIndex] = selectedDrugProducts[upperIndex].filter(
      (selectedDrugProduct) => selectedDrugProduct !== selectedProductId,
    );
    setSelectedDrugProducts([...selectedDrugProducts]);
    selectedPackagingCodes[upperIndex][index] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    selectedDPManufacturers[upperIndex][index] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const addSubstance = (upperIndex: number, productFamilyId: string) => {
    if (
      selectedDrugSubstances[upperIndex].find(
        (selectedDrugSubstance) => selectedDrugSubstance === `TEMP_${productFamilyId}`,
      )
    ) {
      return;
    }
    selectedDrugSubstances[upperIndex].push(`TEMP_${productFamilyId}`);
    setSelectedDrugSubstances([...selectedDrugSubstances]);
  };

  const removeSubstance = (upperIndex: number, index: number, selectedDrugSubstanceId: string) => {
    selectedDrugSubstances[upperIndex] = selectedDrugSubstances[upperIndex].filter(
      (selectedDrugSubstance) => selectedDrugSubstance !== selectedDrugSubstanceId,
    );
    setSelectedDrugSubstances([...selectedDrugSubstances]);
    selectedDSManufacturers[upperIndex][index] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const renderDrugSubstances = (
    productFamilyId: string,
    selectedDrugSubstanceId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDSInReviewMode(productFamilyId, selectedDrugSubstanceId, productFamilyList);
    }
    return (
      <Grid item xs={4}>
        <div className='form-field'>
          <IASelect
            data-testid='Drug Substance'
            label='Drug Substance'
            placeholder='Select drug substance'
            options={
              getProductFamilyById(productFamilyId, productFamilyList)
                ?.drugSubstances.map((el) => ({
                  id: el.id,
                  label: el.label,
                }))
                ?.filter(
                  (item) =>
                    item.id === selectedDrugSubstanceId ||
                    !selectedDrugSubstances[upperIndex].includes(item.id),
                ) || []
            }
            onChange={(event, value) =>
              onChangeDrugSubstance(event, index, upperIndex, productFamilyId, value as SelectValue)
            }
            value={
              getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
                (el) => el.id === selectedDrugSubstanceId,
              )?.label ?? ''
            }
            disabled={isReview}
            error={emptyFields.includes(`substance ${upperIndex + 1} ${index + 1}`)}
            disableClearable={index < selectedDrugSubstances[upperIndex].length - 1}
          />
        </div>
      </Grid>
    );
  };

  const renderDrugSubstanceManufacturers = (
    productFamilyId: string,
    selectedDrugSubstanceId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDSManufacturersInReviewMode(
        productFamilyId,
        selectedDrugSubstanceId,
        productFamilyList,
        upperIndex,
        index,
        selectedDSManufacturers,
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            <IAMultiSelect
              data-testid='Drug Substance Manufacturers'
              label='Drug Substance Manufacturers'
              placeholder='Select drug substance manufacturers'
              options={
                getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
                  (el) => el.id === selectedDrugSubstanceId,
                )?.dsManufacturers || []
              }
              onChange={(event, value) =>
                onChangeDSManufacturers(event, upperIndex, index, value as SelectValue[])
              }
              value={
                getProductFamilyById(productFamilyId, productFamilyList)
                  ?.drugSubstances.find((el) => el.id === selectedDrugSubstanceId)
                  ?.dsManufacturers?.filter(({ id }) =>
                    selectedDSManufacturers[upperIndex][index].includes(id),
                  ) || []
              }
              disabled={isReview}
              disableClearable={false}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const getDSRemoveIcon = (selectedDrugSubstanceId: string, upperIndex: number, index: number) => {
    if (index > 0 && !isReview) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`remove-substance-${selectedDrugSubstanceId}`}
              className='add-more'
              onClick={() => removeSubstance(upperIndex, index, selectedDrugSubstanceId)}
            >
              <RemoveItem />
              <span> Remove</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const getDSAddIcon = (
    selectedDrugSubstanceId: string,
    productFamilyId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (
      !isReview &&
      !!getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
        (el) => el.id === selectedDrugSubstanceId,
      )?.label &&
      index === 0 &&
      (
        getSelectedDrugSubstances(
          upperIndex,
          productFamilyId,
          selectedDrugSubstances,
          productFamilyList,
        ) || []
      ).length <
      (getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances || []).length
    ) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`add-substance-${productFamilyId}`}
              className='add-more'
              onClick={() => addSubstance(upperIndex, productFamilyId)}
            >
              <AddMore />
              <span> Add More</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const renderSelectedSubstances = (productFamilyId: string, upperIndex: number) => {
    if (getProductFamilyById(productFamilyId, productFamilyList)?.label) {
      return getSelectedDrugSubstances(
        upperIndex,
        productFamilyId,
        selectedDrugSubstances,
        productFamilyList,
      )?.map((selectedDrugSubstanceId, index) => (
        <div key={selectedDrugSubstanceId}>
          <hr className='divider-dotted' />
          <div className='product-group-title'>Substance {index + 1}</div>
          <Grid container spacing={2}>
            {renderDrugSubstances(productFamilyId, selectedDrugSubstanceId, upperIndex, index)}
            {isReview &&
              renderDrugSubstanceManufacturers(
                productFamilyId,
                selectedDrugSubstanceId,
                upperIndex,
                index,
              )}
            {getDSRemoveIcon(selectedDrugSubstanceId, upperIndex, index)}
            {getDSAddIcon(selectedDrugSubstanceId, productFamilyId, upperIndex, index)}
          </Grid>
          {getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
            (el) => el.id === selectedDrugSubstanceId,
          )?.label &&
            !isReview &&
            renderDrugSubstanceManufacturers(
              productFamilyId,
              selectedDrugSubstanceId,
              upperIndex,
              index,
            )}
        </div>
      ));
    }
    return <></>;
  };

  const renderDrugProducts = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDPInReviewMode(productFamilyId, productFamilyList, selectedProductId);
    }
    return (
      <IASelect
        data-testid='Product name'
        label='Product name'
        placeholder='Select product name'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.map((el) => ({
              id: el.id,
              label: el.label,
            }))
            ?.filter(
              (item) =>
                item.id === selectedProductId ||
                !selectedDrugProducts[upperIndex].includes(item.id),
            ) || []
        }
        onChange={(event, value) =>
          onChangeProduct(event, index, upperIndex, productFamilyId, value as SelectValue)
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.label ?? ''
        }
        disabled={isReview}
        error={emptyFields.includes(`product ${upperIndex + 1} ${index + 1}`)}
        disableClearable={index < selectedDrugProducts[upperIndex].length - 1}
      />
    );
  };

  const renderPackagingCodes = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getPackagingCodesInReviewMode(
        productFamilyId,
        selectedProductId,
        productFamilyList,
        upperIndex,
        index,
        selectedPackagingCodes,
      );
    }
    return (
      <IAMultiSelect
        data-testid='Packaging Code'
        label='Packaging Code'
        placeholder='Select packaging code'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.packagingCodes || []
        }
        onChange={(event, value) =>
          onChangePackagingCode(event, upperIndex, index, value as SelectValue[])
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.packagingCodes?.filter(({ id }) =>
              selectedPackagingCodes[upperIndex][index].includes(id),
            ) || []
        }
        disabled={isReview}
        disableClearable={false}
      />
    );
  };

  const renderDrugProductManufacturers = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDPManufacturersInReviewMode(
        productFamilyId,
        selectedProductId,
        productFamilyList,
        upperIndex,
        index,
        selectedDPManufacturers,
      );
    }
    return (
      <IAMultiSelect
        data-testid='Drug Product Manufacturers'
        label='Drug Product Manufacturers'
        placeholder='Select drug product manufacturers'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.dpManufacturers || []
        }
        onChange={(event, value) =>
          onChangeDPManufacturers(event, upperIndex, index, value as SelectValue[])
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.dpManufacturers?.filter(({ id }) =>
              selectedDPManufacturers[upperIndex][index].includes(id),
            ) || []
        }
        disabled={isReview}
        disableClearable={false}
      />
    );
  };

  const getDPRemoveIcon = (selectedProductId: string, upperIndex: number, index: number) => {
    if (index > 0 && !isReview) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`remove-product-${selectedProductId}`}
              className='add-more'
              onClick={() => removeProduct(upperIndex, index, selectedProductId)}
            >
              <RemoveItem />
              <span> Remove</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const getDPAddIcon = (
    selectedProductId: string,
    productFamilyId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (
      !isReview &&
      !!getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
        (el) => el.id === selectedProductId,
      )?.label &&
      index === 0 &&
      (
        getSelectedDrugProducts(
          upperIndex,
          productFamilyId,
          selectedDrugProducts,
          productFamilyList,
        ) || []
      ).length <
      (getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts || []).length
    ) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`add-product-${productFamilyId}`}
              className='add-more'
              onClick={() => addProduct(upperIndex, productFamilyId)}
            >
              <AddMore />
              <span> Add More</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const renderSelectedProducts = (productFamilyId: string, upperIndex: number) => {
    if (getProductFamilyById(productFamilyId, productFamilyList)?.label) {
      return getSelectedDrugProducts(
        upperIndex,
        productFamilyId,
        selectedDrugProducts,
        productFamilyList,
      )?.map((selectedProductId, index) => (
        <div key={selectedProductId}>
          <hr className='divider-dotted' />
          <div className='product-group-title'>Product {index + 1}</div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                {renderDrugProducts(productFamilyId, selectedProductId, upperIndex, index)}
              </div>
            </Grid>
            {getDPRemoveIcon(selectedProductId, upperIndex, index)}
            {getDPAddIcon(selectedProductId, productFamilyId, upperIndex, index)}
          </Grid>
          {!!getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.label && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className='form-field'>
                      {renderDosageForm(
                        productFamilyId,
                        selectedProductId,
                        productFamilyList,
                        isReview,
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className='form-field'>
                      {renderDosageStrength(
                        productFamilyId,
                        selectedProductId,
                        productFamilyList,
                        isReview,
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className='form-field'>
                      {renderPackagingCodes(productFamilyId, selectedProductId, upperIndex, index)}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className='form-field'>
                      {renderDrugProductManufacturers(
                        productFamilyId,
                        selectedProductId,
                        upperIndex,
                        index,
                      )}
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
        </div>
      ));
    }
    return <></>;
  };

  const renderProductFamily = (productFamilyId: string, upperIndex: number) => {
    if (isReview) {
      return (
        <div>
          <div className='label'>Product Family</div>
          <div className='review-value'>
            {getProductFamilyById(productFamilyId, productFamilyList)?.label || '-'}
          </div>
        </div>
      );
    }

    return (
      <IASelect
        data-testid='Product Family'
        label='Product Family'
        placeholder='Select product family'
        options={productFamilyList.filter(
          (el) => el.id === productFamilyId || !selectedProductFamilies.includes(el.id),
        )}
        onChange={(event, value) =>
          onChangeProductFamily(upperIndex, event, value as { id: string; label: string })
        }
        value={getProductFamilyById(productFamilyId, productFamilyList)?.label ?? ''}
        disabled={isReview}
        error={emptyFields.includes(`name ${upperIndex + 1}`)}
      />
    );
  };

  const renderEvents = (upperIndex: number) => {
    return (
      <EventDetails
        index={upperIndex}
        isReview={isReview}
        selectedEventType={selectedEventTypes}
        selectedEvent={selectedEvents}
        events={selectedEvents}
        changeCategories={selectedChangeCategory}
        disableAll={true}
        handleChange={() => { }}
      />
    );
  };

  const renderSelectedProductFamilies = () =>
    selectedProductFamilies.map((productFamilyId, upperIndex) => (
      <div key={productFamilyId}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>{renderProductFamily(productFamilyId, upperIndex)}</div>
          </Grid>
          {isReview && (
            <>
              {renderProductINNAndCode(productFamilyId, productFamilyList, isReview)}
              {project?.FCUSTPROJECTTYPE === 'Regulatory Submission' && (
                <Grid item xs={12}>
                  {renderEvents(upperIndex)}
                </Grid>
              )}
            </>
          )}

          {upperIndex > 0 && !isReview && (
            <Grid item xs={4}>
              <div className='add-more-container'>
                <div
                  data-testid={`remove-product-family-${productFamilyId}`}
                  className='add-more'
                  onClick={() => removeProductFamily(upperIndex, productFamilyId)}
                >
                  <RemoveItem />
                  <span> Remove</span>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {!isReview && (
          <Grid container spacing={2}>
            {!!getProductFamilyById(productFamilyId, productFamilyList)?.label &&
              renderProductINNAndCode(productFamilyId, productFamilyList, isReview)}
            {project?.FCUSTPROJECTTYPE === 'Regulatory Submission' && (
              <Grid item xs={12}>
                {renderEvents(upperIndex)}
              </Grid>
            )}
          </Grid>
        )}
        {renderSelectedProducts(productFamilyId, upperIndex)}
        {renderSelectedSubstances(productFamilyId, upperIndex)}
        {selectedProductFamilies.length - 1 !== upperIndex && <hr className='divider' />}
      </div>
    ));

  return (
    <div
      className='document-data'
      data-testid='document-data'
      style={isReview ? { marginTop: '48px' } : {}}
    >
      <ReviewActionTitle
        onEdit={
          setActiveStep
            ? () => {
              setActiveStep(SECOND_STEP);
            }
            : undefined
        }
        title={title}
        isReview={isReview}
      />
      {subtitle && (
        <div className='action-subtitle' data-testid='action-subtitle'>
          {subtitle}
        </div>
      )}
      {renderSelectedProductFamilies()}
      {(!!selectedDrugProducts.length || !!selectedDrugSubstances.length) &&
        additionalMetadata.length > 0 && (
          <AdditionalMetadata
            additionalMetadata={additionalMetadata}
            handleValueChange={(id, value) => {
              handleAdditionalMetadataChange(id, value);
            }}
            isReview={isReview}
            additionalMetadataValues={additionalMetadataValues}
          />
        )}
    </div>
  );
};

export default DocumentData;
