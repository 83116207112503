import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ProductDetails from '../../components/ProjectSteps/ProductDetails';
import ProjectDetails from '../../components/ProjectSteps/ProjectDetails';
import {
  SelectedProductMetadata,
  ProjectFormValues,
  ProductFamilies,
} from '../../core/models/project/projectInterface';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../core/models/state/globalState';
import IADialog from '../../components/Dialog/Dialog';
import { getProjectById, updateProject } from '../../state/actions/project';
import { CircularProgress } from '@mui/material';
import { validateProductMetadata, getSelectedMetadata } from '../../core/services/productMetadata';
import { getLovId } from '../../core/services/apiTranformations';

export const Details = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { project } = useSelector((state: GlobalState) => state.project);
  const {
    project: { lovs, fields },
  } = useSelector((state: GlobalState) => state.system);
  const productAreas = (lovs?.DCUSTPRODUCTAREA || []).map((item) => ({
    id: item.id as string,
    label: item.label as string,
  }));
  const [productDetails, setProductDetails] = useState<SelectedProductMetadata>();

  const getSelectedProductAreas = () => {
    const arr = (project.FCUSTPRODUCTAREA as string)?.split(', ') || [];
    return productAreas.filter((productArea) => arr.includes(productArea.label)).map((el) => el.id);
  };

  const getSelectedMembers = () => {
    const arr = (project.FIAPPROJECTASSIGNEE as string)?.split(',') || [];
    return arr.map((el) => ({ id: el, label: el }));
  };

  const [basicDetails, setBasicDetails] = useState({
    type: project.FCUSTPROJECTTYPE,
    name: project.FTITLE || '',
    // countries: fieldsFilled.countries || [],
    productAreas: getSelectedProductAreas(),
    members: getSelectedMembers(),
  });
  const [updateBody, setUpdateBody] = useState<SelectedProductMetadata>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [productMetadata, setProductMetadata] = useState<ProductFamilies[]>();

  useEffect(() => {
    const {
      selectedProductFamilies,
      selectedDrugProducts,
      selectedDrugSubstances,
      selectedPackagingCodes,
      selectedDPManufacturers,
      selectedDSManufacturers,
      selectedEventTypes,
      selectedEvents,
      selectedChangeCategory,
      studyId,
      diaProductType,
      diaMaterial,
      diaPRIMEProjectPPN,
      diaPRIMEProjectLPN,
      diaStudyType,
      diaStudySubTypeVariant,
      diaLifeCycleStatus,
      diaDiagnosticsSolution,
      diaPRIMEProjectName,
      diaReportType,
      diamQMSDocDeliverable,
      diaProjectName,
      diaProjectNumber,
      diaLct,
      diaProductName,
      diaTestNumber,
      diaMaterialNameMedium,
      diaMaterialNameShort,
      diaMaterialNumber,
      diaInstrumentName,
      diaCustomerArea,
      diaStudySubtype,
    } = getSelectedMetadata(project);
    setProductDetails({
      selectedProductFamilies,
      selectedDrugProducts,
      selectedDrugSubstances,
      selectedPackagingCodes,
      selectedDPManufacturers,
      selectedDSManufacturers,
      selectedEventTypes,
      selectedEvents,
      selectedChangeCategory,
      studyId,
      diaProductType,
      diaMaterial,
      diaPRIMEProjectPPN,
      diaPRIMEProjectLPN,
      diaStudyType,
      diaStudySubTypeVariant,
      diaLifeCycleStatus,
      diaDiagnosticsSolution,
      diaPRIMEProjectName,
      diaReportType,
      diamQMSDocDeliverable,
      diaProjectName,
      diaProjectNumber,
      diaLct,
      diaProductName,
      diaTestNumber,
      diaMaterialNameMedium,
      diaMaterialNameShort,
      diaMaterialNumber,
      diaInstrumentName,
      diaCustomerArea,
      diaStudySubtype,
    });
    setBasicDetails({
      type: project.FCUSTPROJECTTYPE,
      name: project.FTITLE || '',
      // countries: fieldsFilled.countries || [],
      productAreas: getSelectedProductAreas(),
      members: getSelectedMembers(),
    });
  }, [project]);

  const getData = () => {
    setIsDataLoaded(false);
    dispatch(
      getProjectById(() => {
        setIsDataLoaded(true);
      }, projectId as string),
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const isStepsCompleted = (value: SelectedProductMetadata): boolean => {
    const newEmptyFields = validateProductMetadata(
      value,
      [],
      (lovs?.DCUSTPROJECTTYPE || []).find((item) => item.label === (basicDetails.type as string))
        ?.id,
    );
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const getBodyValue = (key: keyof SelectedProductMetadata) => {
    return Array.from(
      new Set(
        ((updateBody as SelectedProductMetadata)[key] as string[])
          ?.flat(3)
          .filter((el) => !el.includes('TEMP')),
      ),
    );
  };

  const save = () => {
    if (!isStepsCompleted(updateBody as SelectedProductMetadata)) {
      setSaveClicked(true);
      return;
    }
    setSaveInProcess(true);
    setSaveClicked(false);
    dispatch(
      updateProject(
        (err) => {
          setSaveInProcess(false);
          if (err) {
            console.log(err);
          } else {
            getData();
            setEditDialogOpen(false);
          }
        },
        {
          id: projectId as string,
          body: {
            FCUSTMETADATA: productMetadata
              ? JSON.stringify(
                  productMetadata.filter((el) =>
                    getBodyValue('selectedProductFamilies').includes(el.id),
                  ),
                )
              : '',
            FCUSTPRODUCTFAMILY: getBodyValue('selectedProductFamilies'),
            FCUSTPRODUCT: getBodyValue('selectedDrugProducts'),
            FCUSTSUBSTANCE: getBodyValue('selectedDrugSubstances'),
            FCUSTPACKAGINGCODE: getBodyValue('selectedPackagingCodes'),
            FCUSTDPMANUFACTURER: getBodyValue('selectedDPManufacturers'),
            FCUSTDSMANUFACTURER: getBodyValue('selectedDSManufacturers'),
          },
        },
      ),
    );
  };

  return (
    <div className='project'>
      <div className='page-header'>
        <PageTitle
          name={
            project?.FTITLE && isDataLoaded && project?.id === projectId
              ? `${project.FTITLE} details`
              : ''
          }
          icon={<ArrowBackIcon />}
          onIconClick={() => navigate(`/layout/workspaces/${project.id}`)}
        />
      </div>
      {isDataLoaded ? (
        <>
          <div className='page-content'>
            <ProjectDetails
              isReview
              title={'Basic details'}
              fillStepFields={() => {}}
              fieldsFilled={(basicDetails as ProjectFormValues) || {}}
            />
            <ProductDetails
              isReview
              projectId={projectId}
              title={'Workspace data'}
              fieldsFilled={productDetails as SelectedProductMetadata}
              fillStepFields={() => {}}
              giveProductMetadata={() => {}}
              productMetadata={productMetadata}
              workspaceType={getLovId(
                'FCUSTPROJECTTYPE',
                project.FCUSTPROJECTTYPE as string,
                fields,
                lovs,
              )}
            />
          </div>

          <IADialog
            title='Edit workspace data'
            open={editDialogOpen}
            handleClose={() => {
              if (saveInProcess) {
                return;
              }
              setEditDialogOpen(false);
              setEmptyFields([]);
              setUpdateBody(undefined);
              setSaveClicked(false);
            }}
            handleSubmit={() => {
              save();
            }}
            xButton
            mainBtnText={saveInProcess ? 'Saving...' : 'Save'}
            maxWidth='xl'
            disabled={saveInProcess}
          >
            <div className='editable-content'>
              <ProductDetails
                fillStepFields={(_step, value) => {
                  setUpdateBody(value);
                  if (saveClicked) {
                    isStepsCompleted(value);
                  }
                }}
                giveProductMetadata={(data) => setProductMetadata(data)}
                fieldsFilled={productDetails as SelectedProductMetadata}
                emptyFields={emptyFields}
                productMetadata={productMetadata}
              />
              {saveInProcess && (
                <>
                  <div className='loader-container with-bg'>
                    <CircularProgress value={30} size='80px' />
                  </div>
                  <div className='loader-background'></div>
                </>
              )}
            </div>
          </IADialog>
        </>
      ) : (
        <div className='loader-container'>
          <CircularProgress value={30} size='80px' />
        </div>
      )}
    </div>
  );
};
