import { call, put, select, take, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  getProjectById,
  getProjects,
  createProject,
  updateProject,
  deleteProjectById,
  getProjectMetadataValuesApi,
} from '../../core/api/project.api';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import {
  ListResponse,
  FieldResponseInterface,
  FieldRequestInterface,
  ItemsInterface,
} from '../../core/models/system/systemDefinition';
import {
  getProjectSuccess,
  getProjectsSuccess,
  createProjectSuccess,
  updateProjectSuccess,
  deleteProjectSuccess,
  projectActionTypes,
  getMyProjectsSuccess,
  getProjectMetadataValuesSuccess,
} from '../actions/project';
import { getFavorites } from '../actions/favorite';
import { GlobalState } from '../../core/models/state/globalState';
import { ProjectState } from '../../core/models/project/projectState';
import { SystemStateInterface } from '../../core/models/system/systemState';
import { setProjectCategories } from '../actions/filter';
import { systemActionTypes } from '../actions/system';
import { createFilterFields } from '../../core/models/filter/filter';
import { Project } from '../../core/models/project/project';
import { FavoriteState } from '../../core/models/favorites/favoriteState';
import { GetListActionInterface } from '../../core/models/global';
import { TYPES_NO_FILTERING } from '../../constants';
import {
  RequiredMetadataResponse,
  RequiredMetadata,
} from '../../core/models/workingDocument/requiredMetadata';

const getProjectFields = (state: GlobalState) => state.system;
const getProjectTableValues = (state: GlobalState) => state.project;
const getFavoriteValues = (state: GlobalState) => state.favorite;

export function* getProject(action: {
  callback: (err: unknown, data?: FieldResponseInterface) => void;
  type: string;
  payload: string;
}) {
  try {
    const projectItem: ItemsInterface = yield call(() => getProjectById(action.payload, []));
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteProjects = favorites.projects || [];
    if (projectItem.id) {
      projectItem.isFavorite = favoriteProjects.includes(projectItem.id);
    }
    yield put(getProjectSuccess(projectItem));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getProjectsByPagination(
  action: GetListActionInterface,
  successAction: (payload: ListResponse) => { type: string; payload: ListResponse },
) {
  try {
    let projectFields: string[] = [];
    const { project }: SystemStateInterface = yield select(getProjectFields) || [];
    const { tableHeadCells }: ProjectState = yield select(getProjectTableValues) || [];

    projectFields = tableHeadCells.map((field) => field.id);
    const { page, size, filterFields, orderBy, allFavorites } = action.payload;

    const projects: ListResponse = yield call(() =>
      getProjects(
        createFilterFields(filterFields),
        Array.from(
          new Set([
            ...projectFields,
            'FCUSTMETADATA',
            'FCUSTPROJECTTYPE',
            'FIAPPROJECTSTATUS',
            'FCUSTPRODUCTFAMILY',
            'FCUSTPRODUCTAREA',
            'FCUSTPRODUCT',
            'FCUSTSUBSTANCE',
            'FCUSTPACKAGINGCODE',
            'FCUSTDPMANUFACTURER',
            'FCUSTDSMANUFACTURER',
            'FIAPPROJECTASSIGNEE',
            'FCUSTEVENTTYPE',
            'FCUSTEVENT',
            'FCUSTEVENTNAME',
            'FCUSTEVENTCHANGECATEGORY',
            'FIAPCREATEDBY',
          ]),
        ),
        allFavorites ? 1 : page,
        allFavorites ? 1000 : size,
        orderBy,
      ),
    );

    action.callback(null);
    yield put(
      setProjectCategories(
        () => {},
        project.fields
          .filter(
            (field) =>
              field.flags?.isFilterable === true && !TYPES_NO_FILTERING.includes(field.type),
          )
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );

    yield put(getFavorites());
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteProjects = favorites.projects || [];
    projects.items.forEach((item) => {
      if (favoriteProjects.includes(item.id)) {
        item.isFavorite = true;
      }
    });
    if (allFavorites) {
      projects.items = projects.items.filter((item) => item.isFavorite);
      //   .slice((page - 1) * size, (page - 1) * size + size);
      // projects.total = favoriteProjects.length;
      // projects.size = size;
    }
    yield put(successAction(projects));
  } catch (error) {
    action.callback(error);
  }
}

export function* addProject(action: {
  callback: (err: unknown, data?: ProjectInterface) => void;
  type: string;
  payload: ProjectInterface;
}) {
  try {
    const {
      project: { fields: projectDefinitions },
    }: SystemStateInterface = yield select(getProjectFields) || [];
    const newProject = Project.createNewProjectObject(action.payload, projectDefinitions);
    const project: ProjectInterface = yield call(() => createProject(newProject));
    yield put(createProjectSuccess(project));
    action.callback(null, project);
  } catch (error) {
    action.callback(error);
  }
}

export function* editProject(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: { body: ProjectInterface; id: string };
}) {
  try {
    const {
      project: { fields: projectDefinitions },
    }: SystemStateInterface = yield select(getProjectFields) || [];
    const projectData = Project.createNewProjectObject(action.payload.body, projectDefinitions);
    projectData.id = action.payload.id;
    const project: ProjectInterface = yield call(() =>
      updateProject(action.payload.id, projectData),
    );
    yield put(updateProjectSuccess(project));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* deleteProject(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: string;
}) {
  try {
    const project: ProjectInterface = yield call(() => deleteProjectById(action.payload));
    yield put(deleteProjectSuccess(project));
  } catch (error) {
    action.callback(error);
  }
}

export function* getProjectMetadataValues(action: {
  callback: (err: { response: { data: string } } | null, data?: RequiredMetadata[]) => void;
  type: string;
  payload: {
    fields: FieldRequestInterface[];
  };
}) {
  try {
    const { fields } = action.payload;
    const projectMetadata: RequiredMetadataResponse = yield call(() =>
      getProjectMetadataValuesApi(fields),
    );
    const { project }: SystemStateInterface = yield select(getProjectFields) || [];
    projectMetadata.fields.forEach((field) => {
      const definition = project.fields.find((el) => el.id === field.id);
      if (definition) {
        field.definition = definition;
      }
    });
    yield put(getProjectMetadataValuesSuccess(projectMetadata));
    action.callback(null, projectMetadata.fields);
  } catch (error) {
    action.callback(error as { response: { data: string } });
  }
}

export default function* projectWatcher() {
  yield takeLatest(projectActionTypes.GET_PROJECT, getProject);
  yield take(systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS);
  yield takeLatest(projectActionTypes.GET_PROJECTS, (action: GetListActionInterface) =>
    getProjectsByPagination(action, getProjectsSuccess),
  );
  yield takeLatest(projectActionTypes.GET_MY_PROJECTS, (action: GetListActionInterface) =>
    getProjectsByPagination(action, getMyProjectsSuccess),
  );
  yield takeLatest(projectActionTypes.CREATE_PROJECT, addProject);
  yield takeLatest(projectActionTypes.UPDATE_PROJECT, editProject);
  yield takeLatest(projectActionTypes.DELETE_PROJECT, deleteProject);
  yield takeEvery(projectActionTypes.GET_PROJECT_METADATA_VALUES, getProjectMetadataValues);
}
