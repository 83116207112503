import { Grid } from '@mui/material';
import { FormValues, SelectValue } from '../../core/models/global';
import { CountryGoup, TEMPLATE_TYPES } from '../../core/models/template/template';
import { LovValue } from '../../core/models/lovValue';

interface TemplateDataReviewProps {
  formValues: FormValues;
  type?: string;
  countryGroups?: CountryGoup[];
}

export const TemplateDataReview = (props: TemplateDataReviewProps) => {
  const { formValues, type, countryGroups = [] } = props;
  const getGountryGroups = (countryGroup: CountryGoup) => {
    if (typeof countryGroup.countryGroupName === 'string') {
      return {
        countryGroupName: countryGroup.countryGroupName,
        countries: countryGroup.countries,
      };
    } else {
      return {
        countryGroupName: countryGroup.countryGroupName?.label,
        countries: countryGroup.countries,
      };
    }
  };

  const getMetadataBasedOnType = () => {
    if (type === TEMPLATE_TYPES.ptqagreements || type === TEMPLATE_TYPES.ptqapqr) {
      return;
    }
    if (type === TEMPLATE_TYPES.submission) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>CTD CODE</div>
                <div className='review-value'>{(formValues.ctdCode as LovValue).label ?? '-'}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>CTD TITLE</div>
                <div className='review-value'>
                  {(formValues.ctdTitle as SelectValue).label ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>BB Code</div>
                <div className='review-value'>
                  {(formValues.bbCode as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      );
    } else if (type === TEMPLATE_TYPES.diapoc) {
      return (
      <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA - mQMS Doc. Deliverable</div>
                <div className='review-value'>
                  {(formValues.DIAmQMSDocDeliverable as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Studytype</div>
                <div className='review-value'>{(formValues.diastudytype as LovValue).label ?? '-'}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Study Sub-Type</div>
                <div className='review-value'>
                {(formValues.diastudysubtype as LovValue).label ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Study Sub-Type Variant</div>
                <div className='review-value'>{(formValues.diastudysubtypevariant as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Life-Cycle Status</div>
                <div className='review-value'>{(formValues.dialifecyclestatus as LovValue).label ?? '-'}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Customer Area</div>
                <div className='review-value'>
                  {(formValues.diacustomerarea as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>DIA Diagnostics Solution</div>
                <div className='review-value'>
                  {(formValues.diadignosticssolution as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
    else {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>Report Classification</div>
                  <div className='review-value'>{(formValues.report as LovValue).label ?? '-'}</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>Report Title</div>
                  <div className='review-value'>
                    {(formValues.reportTitle as SelectValue[])?.map((el) => el.label).join(', ') ??
                      '-'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>Scope</div>
                  <div className='review-value'>
                    {(formValues.bbCode as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>CTD CODE</div>
                  <div className='review-value'>
                    {(formValues.ctdCode as LovValue).label ?? '-'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <div>
                  <div className='label'>CTD TITLE</div>
                  <div className='review-value'>
                    {(formValues.ctdTitle as SelectValue).label ?? '-'}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
  };
  return (
    <div>
      <Grid container spacing={2}>
        {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqapqr && (
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>Product Area</div>
                <div className='review-value'>
                  {(formValues.productArea as SelectValue[])?.map((el) => el.label).join(', ') ??
                    '-'}
                </div>
              </div>
            </div>
          </Grid>
        )}
        {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && (
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>Stage</div>
                <div className='review-value'>
                  {(formValues.stage as SelectValue[])?.map((el) => el.label).join(', ') ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
        )}
        {type === TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && type !== TEMPLATE_TYPES.diapoc &&(
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>Agreement Type</div>
                <div className='review-value'>
                  {(formValues.agreementType as LovValue).label || '-'}
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {getMetadataBasedOnType()}
      {type !== TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && type !== TEMPLATE_TYPES.diapoc && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>Countries (optional)</div>
                <div className='review-value'>
                  {(formValues.countries as SelectValue[])?.map((el) => el.label).join(', ') || '-'}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      { <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className='form-field'>
            <div>
              <div className='label'>Template Title</div>
              <div className='review-value'>{(formValues.title as string) ?? '-'}</div>
            </div>
          </div>
        </Grid>
      </Grid>}
      {!!(countryGroups[0]?.countries?.length || countryGroups[0]?.countryGroupName) && (
        <hr className='divider-dotted' />
      )}
      {countryGroups.map((countryGroup, index) => (
        <Grid container spacing={2} key={getGountryGroups(countryGroup).countryGroupName}>
          <Grid item xs={4}>
            <div className='form-field'>
              <div>
                <div className='label'>Country group {index + 1}</div>
                <div className='review-value'>
                  {getGountryGroups(countryGroup).countryGroupName ?? '-'}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className='form-field'>
              <div>
                <div className='label'>Countries</div>
                <div className='review-value'>{countryGroup.countries.join(', ') ?? '-'}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
