import { ProjectInterface, NewProjectInterface } from './projectInterface';
import { DefinitioniInterface } from '../system/systemDefinition';

export const PROJECT_TYPES = {
  ptqagreements: 'VCUSTPROJECTTYPEPTQUALITYAGREEMENTS',
  submission: 'VCUSTPROJECTTYPEREGULATORYSUBMISSION',
  diapoc: 'VCUSTPROJECTTYPEDIAPOC',
};

export class Project implements ProjectInterface {
  constructor(data: ProjectInterface) {
    Object.assign(this, data);
  }

  [key: string]: string | boolean | string[];

  public static createProject = (data: ProjectInterface) => {
    return new Project(data);
  };

  public static createNewProjectObject = (
    data: ProjectInterface | { [key: string]: string[] },
    projectDefinitions: DefinitioniInterface[],
  ) => {
    const getMultiValues = (type: string, field: string[]) =>
      field?.map((item) => ({ [type === 'userReference' ? 'value' : 'id']: item }));

    const getMultiValueType = (type: string) => {
      if (type === 'userReference') {
        return 'multiUserReference';
      }
      return 'multiLov';
    };

    const result = Object.keys(data)
      .map((key: string) => {
        const definition = projectDefinitions.find((item: DefinitioniInterface) => item.id === key);
        
        if (definition && data[key as keyof ProjectInterface]) {
          return {
            id: definition.id,
            value:
              definition.type === 'string' || definition.type === 'dateTime'
                ? data[key as keyof ProjectInterface]
                : definition.type === 'lov' && !definition.flags?.isMultiValue
                ? { id: data[key as keyof ProjectInterface] }
                : definition.type === 'multiLov' || definition.flags?.isMultiValue
                ? getMultiValues(definition.type, data[key] as string[])
                : '',
            type: definition.flags?.isMultiValue
              ? getMultiValueType(definition.type)
              : definition.type,
          };
        }
        return null;
      })
      .filter((item) => item);
    return { fields: result } as NewProjectInterface;
  };

  public static requiredFields = ['FTITLE'];

  public static checkFormValidity = (project: ProjectInterface) => {
    const keys = Object.keys(project) as (keyof ProjectInterface)[];
    const errors: { key: keyof ProjectInterface; error: string }[] = [];
    keys.forEach((key) => {
      if (this.requiredFields.indexOf(key as string) > -1 && !project[key]) {
        errors.push({
          key,
          error: 'Field is required',
        });
      }
    });
    return errors;
  };
}
