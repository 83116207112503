import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import { SelectValue, FormValues, IdLabel } from '../../core/models/global';
import IAMultiSelect from '../MultiSelect/MultiSelect';
import './TemplateSteps.scss';
import ReviewActionTitle from '../../components/ReviewActionTitle/ReviewActionTitle';
import { GlobalState } from '../../core/models/state/globalState';
import { CountryGoup, TEMPLATE_TYPES } from '../../core/models/template/template';
import { SECOND_STEP } from '../../constants';
import { IAInput } from '../Input/Input';
import { TemplateDataReview } from './TemplateDataReview';
import { getLovValues, populateFieldsForValuesRequest } from '../../core/services/helpers';
import { CountryGroups } from '../CountryGroups/CountryGroups';
import { FieldsToSend, RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { getTemplateMetadataValues } from '../../state/actions/template';
import { LovValue } from '../../core/models/lovValue';
import { getTemplateLovsSuccess } from '../../state/actions/system';

export interface TemplateDataProps {
  fillStepFields: (step: string, value: FormValues) => void;
  fieldsFilled: FormValues;
  isReview?: boolean;
  setActiveStep?: (activeStep: number) => void;
  type?: string;
  resetValuesBasedOnType: boolean | string;
  setResetValuesBasedOnType: (str?: string) => void;
  title?: string;
  emptyFields?: string[];
  setCountryGroupValues?: (countryGroups: CountryGoup[]) => void;
  getCountriesOfCountryGroup?: (countryGroup: IdLabel) => void;
  countryGroups?: CountryGoup[];
  canChange?: boolean;
  published?: boolean;
}

const TemplateData = (props: TemplateDataProps) => {
  const {
    fillStepFields,
    fieldsFilled,
    isReview,
    setActiveStep,
    type,
    resetValuesBasedOnType,
    setResetValuesBasedOnType,
    title,
    emptyFields = [],
    setCountryGroupValues = () => {},
    getCountriesOfCountryGroup = () => {},
    countryGroups = [],
    canChange = true,
    published = false,
  } = props;
  const dispatch = useDispatch();
  const {
    template: { lovs, fields },
  } = useSelector((state: GlobalState) => state.system);

  const [formValues, setFormValues] = useState<FormValues>({
    productArea: fieldsFilled.productArea ?? [],
    stage: fieldsFilled.stage ?? [],
    report: fieldsFilled.report ?? '',
    reportTitle: fieldsFilled.reportTitle ?? [],
    ctdCode: fieldsFilled.ctdCode ?? '',
    ctdTitle: fieldsFilled.ctdTitle ?? '',
    bbCode: fieldsFilled.bbCode ?? [],
    countries: fieldsFilled.countries ?? [],
    title: fieldsFilled.title ?? '',
    agreementType: fieldsFilled.agreementType ?? '',
    DIAmQMSDocDeliverable: fieldsFilled.DIAmQMSDocDeliverable ?? [],
    diastudytype: fieldsFilled.diastudytype ?? '',
    diastudysubtype: fieldsFilled.diastudysubtype ?? '',
    diastudysubtypevariant: fieldsFilled.diastudysubtypevariant ?? [],
    dialifecyclestatus: fieldsFilled.dialifecyclestatus ?? '',
    diacustomerarea: fieldsFilled.diacustomerarea ?? [],
    diadignosticssolution: fieldsFilled.diadignosticssolution ?? []
  });

  const [disableCtdTitle, setDisableCtdTitle] = useState(true);
  const [disableReportTitle, setDisableReportTitle] = useState(true);
  const [disableDIAStudyType, setDisableDIAStudyType] = useState(true);
  const [disableDIAStudySubType, setDisableDIAStudySubType] = useState(true);
  const [disableDIAStudySubTypeVariant, setDisableDIAStudySubTypeVariant] = useState(true);
  const [disableDIALifeCycleStatus, setDisableDIALifeCycleStatus] = useState(true);

  const getDependentLovValues = (
    fieldsToSend: FieldsToSend[],
    disableField: (state: boolean) => void,
  ) => {
    disableField(true);
    dispatch(
      getTemplateMetadataValues(
        (err, fieldsReponse: RequiredMetadata[]) => {
          disableField(false);
          if (err || !fieldsReponse) {
            return;
          }

          fieldsReponse
            .filter(
              (field) => ['FCUSTREPORTTITLE', 'FCUSTCTDTITLE','FCUSTDIASTUDYTYPE','FCUSTDIASTUDYSUBTYPE','FCUSTSTUDYSUBTYPEVARIANT','FCUSTDIACUSTOMERAREA'].includes(field.id) && field.values,
            )
            .forEach((field) => {
              const lovId = fields.find((el) => el.id === field.id)?.lovId;
              if (lovId) {
                lovs[lovId] = field.values.map((el) => el.value as LovValue);
              }
            });
          dispatch(getTemplateLovsSuccess({ ...lovs }));
        },
        {
          fields: fieldsToSend,
        },
      ),
    );
  };
  const onChange = (input: string, _event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    const newValue = value as string | SelectValue | SelectValue[];
    if (newValue === 'N/A') {
      setFormValues({
        ...formValues,
        [input]: newValue,
      });
      return;
    }

    if (input === 'reportTitle') {
      setFormValues({
        ...formValues,
        title: `${(formValues.report as LovValue).label} ${(newValue as LovValue[])
          .map((el) => el.label)
          .join(',')}`,
        [input]: newValue,
      });
      return;
    }
    if (input === 'ctdTitle') {
      setFormValues({
        ...formValues,
        title: `${(formValues.ctdCode as LovValue).label} ${(newValue as LovValue).label}`,
        [input]: newValue,
      });
      return;
    }
    let fieldsToSend: FieldsToSend[] = [];
    if (input === 'report') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTCTDCODE: formValues.ctdCode as SelectValue,
        FCUSTREPORTCLASSIFICATION: newValue as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableReportTitle);
      setFormValues({
        ...formValues,
        reportTitle: [],
        title: (newValue as LovValue).label,
        [input]: newValue,
      });
      return;
    }
    if (input === 'ctdCode') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTCTDCODE: newValue as SelectValue,
        FCUSTREPORTCLASSIFICATION: formValues.report as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableCtdTitle);
      setFormValues({
        ...formValues,
        ctdTitle: '',
        title: (newValue as LovValue).label,
        [input]: newValue,
      });
      return;
    }
    if (input === 'diastudytype') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTDIASTUDYSUBTYPE: formValues.diastudytype as SelectValue,
        FCUSTDIASTUDYTYPE: newValue as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableDIAStudyType);
      setFormValues({
        ...formValues,
        diastudysubtype: '',
        [input]: newValue,
      });
      return;
    }
    if (input === 'diastudysubtype') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTDIASTUDYSUBTYPE: newValue as SelectValue,
        FCUSTSTUDYSUBTYPEVARIANT: formValues.diastudysubtype as SelectValue,
        FCUSTDIASTUDYTYPE: formValues.diastudytype as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableDIAStudySubType);
      setFormValues({
        ...formValues,
        diastudysubtypevariant: [],
        [input]: newValue,
      });
      return;
    }
    if (input === 'diastudysubtypevariant') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTSTUDYSUBTYPEVARIANT : newValue as SelectValue,
        FCUSTDIASTUDYTYPE: formValues.diastudytype as SelectValue,
        FCUSTDIASTUDYSUBTYPE: formValues.diastudysubtype as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableDIAStudySubTypeVariant);
      setFormValues({
          ...formValues,
          [input]: newValue,
      });
      return;
    }
    if (input === 'diacustomerarea') {
      fieldsToSend = populateFieldsForValuesRequest(fields, {
        FCUSTDIACUSTOMERAREA: newValue as SelectValue,
        FCUSTDIADIAGNOSTICSSOL: formValues.diacustomerarea as SelectValue,
      });
      getDependentLovValues(fieldsToSend, setDisableDIALifeCycleStatus);
      setFormValues({
          ...formValues,
          diadignosticssolution: [],
          [input]: newValue,
      });
      return;
    }
    setFormValues({ ...formValues, [input]: newValue });
  };

  useEffect(() => {
    setDisableCtdTitle(!formValues.ctdCode);
    setDisableReportTitle(!formValues.report);
    fillStepFields('2', formValues);
  }, [formValues]);

  useEffect(() => {
    if (typeof resetValuesBasedOnType === 'boolean') {
      setFormValues({
        ...formValues,
        bbCode: [],
        report: '',
        reportTitle: [],
        title: '',
        ctdCode: '',
        ctdTitle: '',
        DIAmQMSDocDeliverable: [],
        diastudytype: '',
        diastudysubtype: '',
        diastudysubtypevariant: [],
        dialifecyclestatus:'',
        diacustomerarea:[],
        diadignosticssolution: []
      });
    }
  }, [resetValuesBasedOnType]);

  const getTypesBasedMetadataFields = () => {
    if (type === TEMPLATE_TYPES.ptqagreements || type === TEMPLATE_TYPES.ptqapqr) {
      return;
    }
    if (type === TEMPLATE_TYPES.submission) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <IASelect
                data-testid='CTD Code'
                label='CTD Code'
                placeholder='Select CTD Code'
                options={getLovValues(lovs, 'DCUSTCTDCODE')}
                onChange={(event, value) => onChange('ctdCode', event, value)}
                value={formValues.ctdCode as IdLabel}
                disabled={isReview}
                error={emptyFields.includes('ctdCode')}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <IASelect
                data-testid='CTD Title'
                label='CTD Title'
                placeholder='Select CTD Title'
                options={getLovValues(lovs, 'DCUSTCTDTITLE')}
                onChange={(event, value) => onChange('ctdTitle', event, value)}
                value={formValues.ctdTitle as IdLabel}
                disabled={disableCtdTitle || isReview}
                error={emptyFields.includes('ctdTitle')}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <IAMultiSelect
                data-testid='BB Code'
                label='BB Code'
                placeholder='Select BB Code'
                options={getLovValues(lovs, 'DCUSTBBCODE')}
                onChange={(event, value) => onChange('bbCode', event, value)}
                value={formValues.bbCode as IdLabel}
                disabled={isReview}
                error={emptyFields.includes('bbCode')}
              />
            </div>
          </Grid>
        </Grid>
      );
    }
    else if(type === TEMPLATE_TYPES.diapoc) {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='DIA - mQMS Doc. Deliverable'
                  label='DIA - mQMS Doc. Deliverable'
                  placeholder='Select DIA - mQMS Doc. Deliverable'
                  options={getLovValues(lovs, 'DCUSTDIAMQMSDOCDELIVERABLE')}
                  onChange={(event, value) => onChange('DIAmQMSDocDeliverable', event, value)}
                  value={formValues.DIAmQMSDocDeliverable as IdLabel[]}
                  disabled={isReview}
                  error={emptyFields.includes('DIAmQMSDocDeliverable')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='DIA Studytype'
                  label='DIA Studytype'
                  placeholder='Select DIA Studytype'
                  options={getLovValues(lovs, 'DCUSTDIASTUDYTYPE')}
                  onChange={(event, value) => onChange('diastudytype', event, value)}
                  value={formValues.diastudytype as IdLabel}
                  disabled={isReview}
                  error={emptyFields.includes('diastudytype')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='DIA Study Sub-Type'
                  label='DIA Study Sub-Type'
                  placeholder='Select DIA Study Sub-Type'
                  options={getLovValues(lovs, 'DCUSTDIASTUDYSUBTYPE')}
                  onChange={(event, value) => onChange('diastudysubtype', event, value)}
                  value={formValues.diastudysubtype as IdLabel}
                  disabled={disableDIAStudyType || isReview}
                  error={emptyFields.includes('diastudysubtype')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='DIA Study Sub-Type Variant'
                  label='DIA Study Sub-Type Variant'
                  placeholder='Select DIA Study Sub-Type Variant'
                  options={getLovValues(lovs, 'DCUSTSTUDYSUBTYPEVARIANT')}
                  onChange={(event, value) => onChange('diastudysubtypevariant', event, value)}
                  value={formValues.diastudysubtypevariant as IdLabel[]}
                  disabled={disableDIAStudySubType || isReview}
                  error={emptyFields.includes('diastudysubtypevariant')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='DIA Life-Cycle Status'
                  label='DIA Life-Cycle Status'
                  placeholder='Select DIA Life-Cycle Status'
                  options={getLovValues(lovs, 'DCUSTLIFECYCLESTATUS')}
                  onChange={(event, value) => onChange('dialifecyclestatus', event, value)}
                  value={formValues.dialifecyclestatus as IdLabel}
                  disabled={disableDIAStudySubTypeVariant || isReview}
                  error={emptyFields.includes('dialifecyclestatus')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='DIA Customer Area'
                  label='DIA Customer Area'
                  placeholder='Select DIA Customer Area'
                  options={getLovValues(lovs, 'DCUSTDIACUSTOMERAREA')}
                  onChange={(event, value) => onChange('diacustomerarea', event, value)}
                  value={formValues.diacustomerarea as IdLabel[]}
                  disabled={isReview}
                  error={emptyFields.includes('diacustomerarea')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='DIA Diagnostics Solution'
                  label='DIA Diagnostics Solution'
                  placeholder='Select DIA Diagnostics Solution'
                  options={getLovValues(lovs, 'DCUSTDIADIAGNOSTICSSOL')}
                  onChange={(event, value) => onChange('diadignosticssolution', event, value)}
                  value={formValues.diadignosticssolution as IdLabel[]}
                  disabled={disableDIALifeCycleStatus || isReview}
                  error={emptyFields.includes('diadignosticssolution')}
                />
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
    else {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='Report Classification'
                  label='Report Classification'
                  placeholder='Select Report Classification'
                  options={getLovValues(lovs, 'DCUSTREPORTCLASSIFICATION')}
                  onChange={(event, value) => onChange('report', event, value)}
                  value={formValues.report as IdLabel}
                  disabled={isReview}
                  error={emptyFields.includes('report')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='Report Title'
                  label='Report Title'
                  placeholder='Select Report Title'
                  options={getLovValues(lovs, 'DCUSTREPORTTITLE')}
                  onChange={(event, value) => onChange('reportTitle', event, value)}
                  value={formValues.reportTitle as IdLabel[]}
                  disabled={disableReportTitle}
                  error={emptyFields.includes('reportTitle')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='Scope'
                  label='Scope'
                  placeholder='Select Scope'
                  options={getLovValues(lovs, 'DCUSTBBCODE')}
                  onChange={(event, value) => onChange('bbCode', event, value)}
                  value={formValues.bbCode as IdLabel}
                  disabled={isReview}
                  error={emptyFields.includes('bbCode')}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='CTD Code'
                  label='CTD Code'
                  placeholder='Select CTD Code'
                  options={getLovValues(lovs, 'DCUSTCTDCODE')}
                  onChange={(event, value) => onChange('ctdCode', event, value)}
                  value={formValues.ctdCode as IdLabel}
                  disabled={isReview}
                  error={emptyFields.includes('ctdCode')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='CTD Title'
                  label='CTD Title'
                  placeholder='Select CTD Title'
                  options={getLovValues(lovs, 'DCUSTCTDTITLE')}
                  onChange={(event, value) => onChange('ctdTitle', event, value)}
                  value={formValues.ctdTitle as IdLabel}
                  disabled={disableCtdTitle || isReview}
                  error={emptyFields.includes('ctdTitle')}
                />
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
  };
  const onEdit = () => {
    if (setActiveStep && canChange) {
      return () => {
        setActiveStep(SECOND_STEP);
        setResetValuesBasedOnType('keep');
      };
    }
  };

  return (
    <div
      className='template-form'
      data-testid='template-data'
      style={isReview ? { marginTop: '48px' } : {}}
    >
      {isReview && (
        <>
          <ReviewActionTitle
            allowEdit={!published && canChange}
            onEdit={onEdit()}
            title={title}
            isReview={isReview}
          />
          <TemplateDataReview formValues={formValues} type={type} countryGroups={countryGroups} />
        </>
      )}

      {!isReview && (
        <div>
          <div className='action-title' data-testid='action-title'>
            {title}
          </div>
          <Grid container spacing={2}>
            {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqapqr && (
              <Grid item xs={4}>
                <div className='form-field'>
                  <IAMultiSelect
                    data-testid='Product Area'
                    label='Product Area'
                    placeholder='Select product area'
                    options={getLovValues(lovs, 'DCUSTPRODUCTAREA')}
                    onChange={(event, value) => onChange('productArea', event, value)}
                    value={formValues.productArea as SelectValue[]}
                    disabled={isReview}
                    error={emptyFields.includes('productArea')}
                  />
                </div>
              </Grid>
            )}
            {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && (
              <Grid item xs={4}>
                <div className='form-field'>
                  <IAMultiSelect
                    data-testid='Stage'
                    label='Stage'
                    placeholder='Select stage'
                    options={getLovValues(lovs, 'DCUSTTEMPLATESTAGE')}
                    onChange={(event, value) => onChange('stage', event, value)}
                    value={formValues.stage as SelectValue[]}
                    disabled={isReview}
                    error={emptyFields.includes('stage')}
                  />
                </div>
              </Grid>
            )}
            {type === TEMPLATE_TYPES.ptqagreements && (
              <Grid item xs={4}>
                <div className='form-field'>
                  <IASelect
                    data-testid='Agreement Type'
                    label='Agreement Type'
                    placeholder='Select Agreement Type'
                    options={getLovValues(lovs, 'DCUSTAGREEMENTTYPE') || []}
                    onChange={(event, value) => onChange('agreementType', event, value)}
                    value={formValues.agreementType as SelectValue}
                    disabled={isReview}
                    error={emptyFields.includes('agreementType')}
                  />
                </div>
              </Grid>
            )}
          </Grid>

          {getTypesBasedMetadataFields()}
          {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className='form-field'>
                  <IAMultiSelect
                    data-testid='Countries (optional)'
                    label='Countries (optional)'
                    placeholder='Enter countries'
                    options={getLovValues(lovs, 'DCUSTCOUNTRY')}
                    onChange={(event, value) => onChange('countries', event, value)}
                    value={formValues.countries as SelectValue[]}
                    disabled={isReview}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {(<Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAInput
                  data-testid={'Title'}
                  inputLabel='Template Title'
                  placeholder='Enter Title'
                  onChange={(event) => onChange('title', event, event?.target?.value)}
                  value={formValues.title}
                  disabled={isReview}
                  error={emptyFields.includes('title')}
                />
              </div>
            </Grid>
          </Grid>)}
          {type !== TEMPLATE_TYPES.diapoc && type !== TEMPLATE_TYPES.ptqagreements && type !== TEMPLATE_TYPES.ptqapqr && (
            <CountryGroups
              lovs={lovs}
              countryGroupValues={countryGroups}
              setCountryGroupValues={setCountryGroupValues}
              getCountriesOfCountryGroup={getCountriesOfCountryGroup}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateData;
