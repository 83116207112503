import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import IASelect from '../Select/Select';
import IAMultiSelect from '../MultiSelect/MultiSelect';
import { IAInput } from '../Input/Input';
import RemoveItem from '../icons/RemoveItem';
import AddMore from '../icons/AddMore';
import ReviewActionTitle from '../ReviewActionTitle/ReviewActionTitle';
import {
  SelectedProductMetadata,
  ProductFamilies,
} from '../../core/models/project/projectInterface';
import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import { GlobalState } from '../../core/models/state/globalState';
import { getProjectMetadataValues } from '../../state/actions/project';
import { IdLabel, SelectValue } from '../../core/models/global';
import {
  RequiredMetadata,
  RequiredMetadataValues,
} from '../../core/models/workingDocument/requiredMetadata';
import {
  getProductFamilyById,
  getSelectedDrugProducts,
  getSelectedDrugSubstances,
  getFieldValues,
  getDSInReviewMode,
  getDPInReviewMode,
  getDSManufacturersInReviewMode,
  renderProductINNAndCode,
  getDPManufacturersInReviewMode,
  getPackagingCodesInReviewMode,
  renderDosageForm,
  renderDosageStrength,
} from '../../core/services/productMetadata';
import { FLATTEN_ARRAY_DEPTH } from '../../constants';
import { PROJECT_TYPES } from '../../core/models/project/project';
import { getLovValues } from '../../core/services/helpers';
import EventDetails from '../Events/Events';
import { LovValue } from '../../core/models/lovValue';
import { getLovLabel } from '../../core/services/apiTranformations';

interface ProductDetailsProps {
  fillStepFields: (step: string, value: SelectedProductMetadata) => void;
  giveProductMetadata: (data: ProductFamilies[]) => void;
  productMetadata?: ProductFamilies[];
  fieldsFilled?: SelectedProductMetadata;
  isReview?: boolean;
  onEdit?: () => void;
  title?: string;
  emptyFields?: string[];
  projectId?: string;
  workspaceType?: string;
}

const ProductDetails = (props: ProductDetailsProps) => {
  const dispatch = useDispatch();
  const {
    fillStepFields,
    giveProductMetadata,
    productMetadata,
    fieldsFilled,
    isReview,
    onEdit,
    title,
    emptyFields = [],
    projectId,
    workspaceType,
  } = props;
  const {
    project: { lovs, fields: definitions },
  } = useSelector((state: GlobalState) => state.system);
  const [productFamilyList, setProductFamilyList] = useState<ProductFamilies[]>(
    (lovs?.DCUSTPRODUCTFAMILY || []).map((item) => {
      const productFamily = productMetadata?.find((el) => el.id === item.id);
      return {
        id: item.id as string,
        label: item.label as string,
        productInn: productFamily?.productInn || '',
        productFamilyCode: productFamily?.productFamilyCode || '',
        roNumber: productFamily?.roNumber || '',
        drugProducts: productFamily?.drugProducts || [],
        drugSubstances: productFamily?.drugSubstances || [],
        eventTypes: productFamily?.eventTypes || [],
      };
    }),
  );
  const [selectedProductFamilies, setSelectedProductFamilies] = useState<string[]>(
    fieldsFilled?.selectedProductFamilies ?? ['TEMP'],
  );
  const [selectedDrugProducts, setSelectedDrugProducts] = useState<string[][]>(
    fieldsFilled?.selectedDrugProducts ?? [[]],
  );
  const [selectedDrugSubstances, setSelectedDrugSubstances] = useState<string[][]>(
    fieldsFilled?.selectedDrugSubstances ?? [[]],
  );
  const [selectedPackagingCodes, setSelectedPackagingCodes] = useState<string[][][]>(
    fieldsFilled?.selectedPackagingCodes ?? [[[]]],
  );
  const [selectedDPManufacturers, setSelectedDPManufacturers] = useState<string[][][]>(
    fieldsFilled?.selectedDPManufacturers ?? [[[]]],
  );
  const [selectedDSManufacturers, setSelectedDSManufacturers] = useState<string[][][]>(
    fieldsFilled?.selectedDSManufacturers ?? [[[]]],
  );
  const [studyId, setStudyId] = useState<LovValue>({
    id: fieldsFilled?.studyId || '',
    label:
      getLovLabel('FCUSTSTUDYID', fieldsFilled?.studyId as string, definitions, lovs) ||
      fieldsFilled?.studyId ||
      '',
  });
  const [DIAProductType, setDIAProductType] = useState<LovValue>({
    id: fieldsFilled?.diaProductType || '',
    label:
      getLovLabel('FCUSTDIAPRODUCTTYPE', fieldsFilled?.diaProductType as string, definitions, lovs) ||
      fieldsFilled?.diaProductType ||
      '',
  });
  const [diaMaterial, setdiaMaterial] = useState<IdLabel[]>(
    fieldsFilled?.diaMaterial?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTDPMATERIAL', el, definitions, lovs) || el,
    })) || [],
  );
  
  const [diaInstrumentName, setdiaInstrumentName] = useState<IdLabel[]>(
    fieldsFilled?.diaInstrumentName?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTDIAINSTRUMENTNAME', el, definitions, lovs) || el,
    })) || [],
  );
  const [diaCustomerArea, setdiaCustomerArea] = useState<IdLabel[]>(
    fieldsFilled?.diaCustomerArea?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTDIACUSTOMERAREA', el, definitions, lovs) || el,
    })) || [],
  );
  // diaPRIMEProjectPPN
  const [diaPRIMEProjectPPN, setdiaPRIMEProjectPPN] = useState<LovValue>({
    id: fieldsFilled?.diaPRIMEProjectPPN || '',
    label:
      getLovLabel('FCUSTPRIMEPROJECTPPN', fieldsFilled?.diaPRIMEProjectPPN as string, definitions, lovs) ||
      fieldsFilled?.diaPRIMEProjectPPN ||
      '',
  });
  const [diaPRIMEProjectLPN, setdiaPRIMEProjectLPN] = useState<LovValue>({
    id: fieldsFilled?.diaPRIMEProjectLPN || '',
    label:
      getLovLabel('FCUSTPRIMEPROJECTLPN', fieldsFilled?.diaPRIMEProjectLPN as string, definitions, lovs) ||
      fieldsFilled?.diaPRIMEProjectLPN ||
      '',
  });
  const [diaStudyType, setdiaStudyType] = useState<LovValue>({
    id: fieldsFilled?.diaStudyType || '',
    label:
      getLovLabel('FCUSTDIASTUDYTYPE', fieldsFilled?.diaStudyType as string, definitions, lovs) ||
      fieldsFilled?.diaStudyType ||
      '',
  });
  const [diastudysubtypeOption, setdiastudysubtypeOption] = useState<IdLabel[]>([]);
  const [diastudysubtypeVariantOption, setdiastudysubtypeVariantOption] = useState<IdLabel[]>([]);
  const [diaLifeCycleStatusOption, setdiaLifeCycleStatusOption] = useState<IdLabel[]>([]);
  const [diaDiagnosticsSolutionOption, setdiaDiagnosticsSolutionOption] = useState<IdLabel[]>([]);
  const [diaStudySubtype, setdiastudysubtype] = useState<LovValue>({
    id: fieldsFilled?.diaStudySubtype || '',
    label:
      getLovLabel('FCUSTDIASTUDYSUBTYPE', fieldsFilled?.diaStudySubtype as string, definitions, lovs) ||
      fieldsFilled?.diaStudySubtype ||
      '',
  });
  const [diaStudySubTypeVariant, setdiaStudySubTypeVariant] = useState<IdLabel[]>(
    fieldsFilled?.diaStudySubTypeVariant
      ? fieldsFilled.diaStudySubTypeVariant.map((el) => ({
          id: el,
          label: getLovLabel('FCUSTSTUDYSUBTYPEVARIANT', el, definitions, lovs) || el,
        }))
      : [],
  );
  
  const [diaLifeCycleStatus, setdiaLifeCycleStatus] = useState<LovValue>({
    id: fieldsFilled?.diaLifeCycleStatus || '',
    label:
      getLovLabel('FCUSTSTUDYSUBTYPEVARIANT', fieldsFilled?.diaLifeCycleStatus as string, definitions, lovs) ||
      fieldsFilled?.diaLifeCycleStatus ||
      '',
  });
  const [diaDiagnosticsSolution, setdiaDiagnosticsSolution] = useState<IdLabel[]>(
    fieldsFilled?.diaStudySubTypeVariant
      ? fieldsFilled?.diaDiagnosticsSolution?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTDIADIAGNOSTICSSOL', el, definitions, lovs) || el,
    })) : [],
  );
  
  const [diaPRIMEProjectName, setdiaPRIMEProjectName] = useState<LovValue>({
    id: fieldsFilled?.diaPRIMEProjectName || '',
    label:
      getLovLabel('FCUSTPRIMEPROJECTNAME', fieldsFilled?.diaPRIMEProjectName as string, definitions, lovs) ||
      fieldsFilled?.diaPRIMEProjectName ||
      '',
  });
  const [diaReportType, setdiaReportType] = useState<LovValue>({
    id: fieldsFilled?.diaProductType || '',
    label:
      getLovLabel('FCUSTDIAREPORTTYPE', fieldsFilled?.diaReportType as string, definitions, lovs) ||
      fieldsFilled?.diaReportType ||
      '',
  });
  // mQMSDocDeliverable

  const [DIAmQMSDocDeliverable, setDIAmQMSDocDeliverable] = useState<LovValue>({
    id: fieldsFilled?.diamQMSDocDeliverable || '',
    label:
      getLovLabel('FCUSTDIAMQMSDOCDELIVERABLE', fieldsFilled?.diamQMSDocDeliverable as string, definitions, lovs) ||
      fieldsFilled?.diamQMSDocDeliverable ||
      '',
  });
  const [diaProjectName, setDiaProjectName] = useState<string>(fieldsFilled?.diaProjectName || '');
  const [diaProjectNumber, setDiaProjectNumber] = useState<string>(fieldsFilled?.diaProjectNumber || '');
  const [diaLct, setDiaLct] = useState<string>(fieldsFilled?.diaLct || '');
  const [diaProductName, setdiaProductName] = useState<string>(fieldsFilled?.diaProductName || '');
  const [diaMaterialNumber, setdiaMaterialNumber] = useState<string>(fieldsFilled?.diaMaterialNumber || '');
  const [diaMaterialNameShort, setdiaMaterialNameShort] = useState<string>(fieldsFilled?.diaMaterialNameShort || '');
  const [diaMaterialNameMedium, setdiaMaterialNameMedium] = useState<string[]>(
    fieldsFilled?.diaMaterialNameMedium || [],
  );
  const [diaTestNumber, setdiaTestNumber] = useState<string>(fieldsFilled?.diaTestNumber || '');
  const onChangeStudyId = (value: LovValue) => {
    setStudyId(value);
  };
  const onChangeDIAProductType = (value: LovValue) => {
    setDIAProductType(value);
  };
  const onChangediaReportType = (value: LovValue) => {
    setdiaReportType(value);
  };
  const onChangeDIAmQMSDocDeliverable = (value: LovValue) => {
    setDIAmQMSDocDeliverable(value);
  };
  const onChangediaMaterial = (value: LovValue[]) => {
    setdiaMaterial(value);
  };
  const onChangediaInstrumentName = (value: LovValue[]) => {
    setdiaInstrumentName(value);
  };
  const onChangediaCustomerArea = (value: LovValue[]) => {
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const data = getFieldValues(fields, ['FCUSTDIADIAGNOSTICSSOL'], definitions);
              const {
                FCUSTDIADIAGNOSTICSSOL: diastudysubtypeOptionList
              } = data;
              const filteredData = (diastudysubtypeOptionList as RequiredMetadataValues[]).map((el) => ({
                id: (el.value as DefinitioniInterface).id,
                label: (el.value as DefinitioniInterface).label,
              }));
              setdiaDiagnosticsSolutionOption(filteredData);
            }
          },
          {
            fields:
              [{
                id: 'FCUSTDIADIAGNOSTICSSOL',
                type: 'multiLov',
                value: value                
              },
              {
                id: 'FCUSTDIACUSTOMERAREA',
                type: 'multiLov',
                value: value                
              }
              ]

          },
        ),
      );
    }
    setdiaCustomerArea(value);
  };
  const onChangediaPRIMEProjectPPN = (value: LovValue) => {
    setdiaPRIMEProjectPPN(value);
  };
  const onChangediaPRIMEProjectLPN = (value: LovValue) => {
    setdiaPRIMEProjectLPN(value);
  };
  
  const onChangediaStudyType = (value: LovValue) => {
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const data = getFieldValues(fields, ['FCUSTDIASTUDYSUBTYPE'], definitions);
              const {
                FCUSTDIASTUDYSUBTYPE: diastudysubtypeOptionList
              } = data;

              const filteredData = (diastudysubtypeOptionList as RequiredMetadataValues[]).map((el) => ({
                id: (el.value as DefinitioniInterface).id,
                label: (el.value as DefinitioniInterface).label,
              }));
              setdiastudysubtypeOption(filteredData);
            }
          },
          {
            fields:
              [{
                id: 'FCUSTDIASTUDYSUBTYPE',
                type: 'lov',
                value:
                {
                  id: value.id,
                  label: value.label
                }
              },
              {
                id: 'FCUSTDIASTUDYTYPE',
                type: 'lov',
                value:
                {
                  id: value.id,
                  label: value.label
                }

              }
              ]

          },
        ),
      );
    }
    setdiaStudyType(value);
  };
  const onChangediastudysubtype = (value: LovValue) => {
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const data = getFieldValues(fields, ['FCUSTSTUDYSUBTYPEVARIANT'], definitions);
              const {
                FCUSTSTUDYSUBTYPEVARIANT: diastudysubtypeOptionList
              } = data;

              const filteredData = (diastudysubtypeOptionList as RequiredMetadataValues[]).map((el) => ({
                id: (el.value as DefinitioniInterface).id,
                label: (el.value as DefinitioniInterface).label,
              }));
              setdiastudysubtypeVariantOption(filteredData);
            }
          },
          {
            fields:
              [{
                id: 'FCUSTSTUDYSUBTYPEVARIANT',
                type: 'multiLov',
                value:
                [{
                  id: value.id,
                }]
              },
              {
                id: 'FCUSTDIASTUDYSUBTYPE',
                type: 'lov',
                value:
                {
                  id: value.id,
                  label: value.label
                }

              },
              {
                id: 'FCUSTDIASTUDYTYPE',
                type: 'lov',
                value:
                {
                  id: value.id,
                  label: value.label
                }

              }
              ]

          },
        ),
      );
    }
    setdiastudysubtype(value);
  };
  const onChangediaStudySubTypeVariant = (value: LovValue[]) => {
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const data = getFieldValues(fields, ['FCUSTLIFECYCLESTATUS'], definitions);
              const {
                FCUSTLIFECYCLESTATUS: diastudysubtypeOptionList
              } = data;
              const filteredData = (diastudysubtypeOptionList as RequiredMetadataValues[]).map((el) => ({
                id: (el.value as DefinitioniInterface).id,
                label: (el.value as DefinitioniInterface).label,
              }));
              setdiaLifeCycleStatusOption(filteredData);
            }
          },
          {
            fields:
              [{
                id: 'FCUSTSTUDYSUBTYPEVARIANT',
                type: 'multiLov',
                value: value                
              },
              {
                id: 'FCUSTDIASTUDYSUBTYPE',
                type: 'lov',
                value: diaStudySubtype               

              },
              {
                id: 'FCUSTDIASTUDYTYPE',
                type: 'lov',
                value: diaStudyType
              }
              ]

          },
        ),
      );
    }
    setdiaStudySubTypeVariant(value);
  };
  const onChangediaLifeCycleStatus = (value: LovValue) => {    
    setdiaLifeCycleStatus(value);
  };
  const onChangediaDiagnosticsSolution = (value: LovValue[]) => {
    setdiaDiagnosticsSolution(value);
  };
    
  const onChangediaPRIMEProjectName = (value: LovValue) => {
    setdiaPRIMEProjectName(value);
  };
  // diaPRIMEProjectName
  const [selectedEventTypes, setSelectedEventTypes] = useState<IdLabel[]>(
    fieldsFilled?.selectedEventTypes?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTEVENTTYPE', el, definitions, lovs) || el,
    })) || [],
  );
  const [selectedEvents, setSelectedEvents] = useState<IdLabel[]>(
    fieldsFilled?.selectedEvents?.map((el) => ({
      id: el,
      label: getLovLabel('FCUSTEVENT', el, definitions, lovs) || el,
    })) || [],
  );
  const [events, setEvents] = useState<IdLabel[]>([]);
  const [changeCategories, setChangeCategories] = useState<string[]>(
    fieldsFilled?.selectedChangeCategory || [],
  );

  const setProductFamilyValues = (fields: RequiredMetadata[], id: string) => {
    const data = getFieldValues(
      fields,
      [
        'FCUSTPRODUCT',
        'FCUSTSUBSTANCE',
        'FCUSTPRODUCTFAMINN',
        'FCUSTPRODFAMFAMILYCODE',
        'FCUSTPRODFAMROCHENUM',
        'FCUSTEVENTTYPE',
      ],
      definitions,
    );
    const {
      FCUSTPRODUCT: drugProductValues,
      FCUSTSUBSTANCE: drugSubstanceValues,
      FCUSTPRODUCTFAMINN: productInn,
      FCUSTPRODFAMFAMILYCODE: productFamilyCode,
      FCUSTPRODFAMROCHENUM: roNumber,
      FCUSTEVENTTYPE: eventTypesValues,
    } = data;
    const productFamily = getProductFamilyById(id, productFamilyList);
    if (productFamily) {
      productFamily.drugProducts = (drugProductValues as RequiredMetadataValues[]).map((el) => ({
        id: (el.value as DefinitioniInterface).id,
        label: (el.value as DefinitioniInterface).label,
      }));
      productFamily.drugSubstances = (drugSubstanceValues as RequiredMetadataValues[]).map(
        (el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }),
      );
      productFamily.productInn = productInn as string;
      productFamily.productFamilyCode = productFamilyCode as string;
      productFamily.roNumber = roNumber as string;
      productFamily.eventTypes = (eventTypesValues as RequiredMetadataValues[]).map((el) => ({
        id: (el.value as DefinitioniInterface).id,
        label: (el.value as DefinitioniInterface).label,
      }));
      setProductFamilyList([...productFamilyList]);
    }
    return productFamily;
  };

  const setProductValues = (fields: RequiredMetadata[], id: string, productFamilyId: string) => {
    const data = getFieldValues(
      fields,
      ['FCUSTDOSAGEFORM', 'FCUSTDOSAGESTRENGTH', 'FCUSTPACKAGINGCODE', 'FCUSTDPMANUFACTURER'],
      definitions,
    );
    const {
      FCUSTDOSAGEFORM: dosageForm,
      FCUSTDOSAGESTRENGTH: dosageStrength,
      FCUSTPACKAGINGCODE: packagingCodeValues,
      FCUSTDPMANUFACTURER: dpManufacturersValues,
    } = data;
    const productFamily = getProductFamilyById(productFamilyId, productFamilyList);
    const drugProduct = productFamily?.drugProducts.find((el) => el.id === id);
    if (drugProduct) {
      drugProduct.dosageForm = dosageForm as string;
      drugProduct.dosageStrength = dosageStrength as string;
      drugProduct.packagingCodes = (packagingCodeValues as RequiredMetadataValues[]).map((el) => ({
        id: (el.value as DefinitioniInterface).id,
        label: (el.value as DefinitioniInterface).label,
      }));
      drugProduct.dpManufacturers = (dpManufacturersValues as RequiredMetadataValues[]).map(
        (el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }),
      );
      setProductFamilyList([...productFamilyList]);
    }
  };

  const setSubstanceValues = (fields: RequiredMetadata[], id: string, productFamilyId: string) => {
    const data = getFieldValues(fields, ['FCUSTDSMANUFACTURER'], definitions);
    const { FCUSTDSMANUFACTURER: dsManufacturersValues } = data;
    const productFamily = getProductFamilyById(productFamilyId, productFamilyList);
    const drugSubstance = productFamily?.drugSubstances.find((el) => el.id === id);
    if (drugSubstance) {
      drugSubstance.dsManufacturers = (dsManufacturersValues as RequiredMetadataValues[]).map(
        (el) => ({
          id: (el.value as DefinitioniInterface).id,
          label: (el.value as DefinitioniInterface).label,
        }),
      );
      setProductFamilyList([...productFamilyList]);
    }
  };
  const getProductsValues = (productFamilyId: string) => {
    if (fieldsFilled?.selectedDrugProducts && projectId) {
      fieldsFilled?.selectedDrugProducts
        .flat(FLATTEN_ARRAY_DEPTH)
        .forEach((selectedDrugProduct: string) => {
          dispatch(
            getProjectMetadataValues(
              (err, fields: RequiredMetadata[]) => {
                if (!err && fields) {
                  setProductValues(fields, selectedDrugProduct, productFamilyId);
                }
              },
              {
                fields: [
                  {
                    id: 'FCUSTPRODUCTFAMILY',
                    type: 'multiLov',
                    value: [
                      {
                        id: productFamilyId,
                      },
                    ],
                  },
                  {
                    id: 'FCUSTPRODUCT',
                    type: 'multiLov',
                    value: [
                      {
                        id: selectedDrugProduct,
                      },
                    ],
                  },
                ],
              },
            ),
          );
        });
    }
  };

  const getSubstanceValues = (productFamilyId: string) => {
    if (fieldsFilled?.selectedDrugSubstances && projectId) {
      fieldsFilled?.selectedDrugSubstances
        .flat(FLATTEN_ARRAY_DEPTH)
        .forEach((selectedDrugSubstance: string) => {
          dispatch(
            getProjectMetadataValues(
              (err, fields: RequiredMetadata[]) => {
                if (!err && fields) {
                  setSubstanceValues(fields, selectedDrugSubstance, productFamilyId);
                }
              },
              {
                fields: [
                  {
                    id: 'FCUSTPRODUCTFAMILY',
                    type: 'multiLov',
                    value: [
                      {
                        id: productFamilyId,
                      },
                    ],
                  },
                  {
                    id: 'FCUSTSUBSTANCE',
                    type: 'multiLov',
                    value: [
                      {
                        id: selectedDrugSubstance,
                      },
                    ],
                  },
                ],
              },
            ),
          );
        });
    }
  };

  useEffect(() => {
    if (fieldsFilled?.selectedProductFamilies && projectId) {
      fieldsFilled?.selectedProductFamilies.forEach((selectedProductFamily: string) => {
        dispatch(
          getProjectMetadataValues(
            (err, fields: RequiredMetadata[]) => {
              if (!err && fields) {
                const productFamily = setProductFamilyValues(fields, selectedProductFamily);
                if (productFamily) {
                  getProductsValues(selectedProductFamily);
                  getSubstanceValues(selectedProductFamily);
                }
              }
            },
            {
              fields: [
                {
                  id: 'FCUSTPRODUCTFAMILY',
                  type: 'multiLov',
                  value: [
                    {
                      id: selectedProductFamily,
                    },
                  ],
                },
              ],
            },
          ),
        );
      });
    }
  }, [fieldsFilled?.selectedProductFamilies]);

  useEffect(() => {
    fillStepFields('2', {
      selectedProductFamilies,
      selectedDrugProducts,
      selectedDrugSubstances,
      selectedPackagingCodes,
      selectedDPManufacturers,
      selectedDSManufacturers,
      studyId: studyId.id,
      diaProductType: DIAProductType.id,
      diaReportType: diaReportType.id,
      diamQMSDocDeliverable: DIAmQMSDocDeliverable.id,
      diaProjectName: diaProjectName,
      diaProjectNumber: diaProjectNumber,
      diaLct: diaLct,
      diaProductName: diaProductName,
      diaTestNumber: diaTestNumber,
      selectedEventTypes: selectedEventTypes.map((el) => el.id),
      selectedEvents: selectedEvents.map((el) => el.id),
      selectedChangeCategory: changeCategories,
      diaMaterial: diaMaterial.map((el) => el.id),
      diaPRIMEProjectPPN: diaPRIMEProjectPPN.id,
      diaPRIMEProjectLPN: diaPRIMEProjectLPN.id,
      diaPRIMEProjectName: diaPRIMEProjectName.id,
      diaMaterialNameMedium: diaMaterialNameMedium,
      diaMaterialNameShort: diaMaterialNameShort,
      diaMaterialNumber: diaMaterialNumber,
      diaInstrumentName: diaInstrumentName.map((el) => el.id),
      diaCustomerArea: diaCustomerArea.map((el) => el.id),
      diaStudyType: diaStudyType.id,
      diaStudySubtype: diaStudySubtype.id,
      diaStudySubTypeVariant: diaCustomerArea.map((el) => el.id),
      diaLifeCycleStatus: diaLifeCycleStatus.id,  
      diaDiagnosticsSolution: diaDiagnosticsSolution.map((el) => el.id),
    });
  }, [
    selectedProductFamilies,
    selectedDrugProducts,
    selectedDrugSubstances,
    selectedPackagingCodes,
    selectedDPManufacturers,
    selectedDSManufacturers,
    studyId,
    selectedEventTypes,
    selectedEvents,
    diaMaterial,
    diaPRIMEProjectPPN,
    diaPRIMEProjectLPN,
    diaPRIMEProjectName,
    diaReportType,
    diaProjectName,
    diaProjectNumber,
    diaLct,
    diaProductName,
    diaTestNumber,
    diaMaterialNameMedium,
    diaMaterialNameShort,
    diaMaterialNumber,
    diaStudyType,
    diaStudySubTypeVariant,
    diaLifeCycleStatus,
    diaStudySubtype,
    diaDiagnosticsSolution,
  ]);

  useEffect(() => {
    giveProductMetadata(
      productFamilyList.filter((el) => el.drugProducts?.length || el.drugSubstances?.length),
    );
  }, [productFamilyList]);

  const onChangeProductFamily = (
    index: number,
    _event: React.SyntheticEvent,
    value: SelectValue,
  ) => {
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              setProductFamilyValues(fields, value.id);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
      selectedProductFamilies[index] = value.id;
      selectedDrugProducts[index] = [`TEMP_${value.id}`];
      selectedDrugSubstances[index] = [`TEMP_${value.id}`];
    } else {
      selectedProductFamilies[index] = 'TEMP';
      selectedDrugProducts[index] = [];
      selectedDrugSubstances[index] = [];
    }
    setSelectedProductFamilies([...selectedProductFamilies]);
    setSelectedDrugProducts([...selectedDrugProducts]);
    setSelectedDrugSubstances([...selectedDrugSubstances]);
  };

  const onChangeProduct = (
    _event: React.SyntheticEvent,
    index: number,
    upperIndex: number,
    productFamilyId: string,
    value: SelectValue,
  ) => {
    const tempIndex = selectedDrugProducts[upperIndex].findIndex(
      (el) => el === `TEMP_${productFamilyId}`,
    );
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              setProductValues(fields, value.id, productFamilyId);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: productFamilyId,
                  },
                ],
              },
              {
                id: 'FCUSTPRODUCT',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
      if (tempIndex === -1) {
        selectedDrugProducts[upperIndex][index] = value.id;
      } else {
        selectedDrugProducts[upperIndex][tempIndex] = value.id;
      }
    } else if (tempIndex === -1) {
      selectedDrugProducts[upperIndex][index] = `TEMP_${productFamilyId}`;
    } else {
      selectedDrugProducts[upperIndex][tempIndex] = `TEMP_${productFamilyId}`;
    }
    setSelectedDrugProducts([...selectedDrugProducts]);

    if (!selectedPackagingCodes[upperIndex]) {
      selectedPackagingCodes[upperIndex] = [];
    }
    selectedPackagingCodes[upperIndex][index] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    if (!selectedDPManufacturers[upperIndex]) {
      selectedDPManufacturers[upperIndex] = [];
    }
    selectedDPManufacturers[upperIndex][index] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const onChangeDrugSubstance = (
    _event: React.SyntheticEvent,
    index: number,
    upperIndex: number,
    productFamilyId: string,
    value: SelectValue,
  ) => {
    const tempIndex = selectedDrugSubstances[upperIndex].findIndex(
      (el) => el === `TEMP_${productFamilyId}`,
    );
    if (value) {
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              setSubstanceValues(fields, value.id, productFamilyId);
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: productFamilyId,
                  },
                ],
              },
              {
                id: 'FCUSTSUBSTANCE',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
      if (tempIndex === -1) {
        selectedDrugSubstances[upperIndex][index] = value.id;
      } else {
        selectedDrugSubstances[upperIndex][tempIndex] = value.id;
      }
    } else if (tempIndex === -1) {
      selectedDrugSubstances[upperIndex][index] = `TEMP_${productFamilyId}`;
    } else {
      selectedDrugSubstances[upperIndex][tempIndex] = `TEMP_${productFamilyId}`;
    }

    setSelectedDrugSubstances([...selectedDrugSubstances]);

    if (!selectedDSManufacturers[upperIndex]) {
      selectedDSManufacturers[upperIndex] = [];
    }
    selectedDSManufacturers[upperIndex][index] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const onChangePackagingCode = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedPackagingCodes[upperIndex][index] = values.map((el) => el.id);
    setSelectedPackagingCodes([...selectedPackagingCodes]);
  };

  const onChangeDPManufacturers = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedDPManufacturers[upperIndex][index] = values.map((el) => el.id);
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const onChangeDSManufacturers = (
    _event: React.SyntheticEvent,
    upperIndex: number,
    index: number,
    values: SelectValue[],
  ) => {
    selectedDSManufacturers[upperIndex][index] = values.map((el) => el.id);
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const onChangeEvents = (key: 'eventType' | 'event', upperIndex: number, value: SelectValue) => {
    if (key === 'eventType') {
      selectedEventTypes[upperIndex] = value;
      setSelectedEventTypes([...selectedEventTypes]);
      selectedEvents.splice(upperIndex, 1);
      changeCategories.splice(upperIndex, 1);
      setSelectedEvents([...selectedEvents]);
      setChangeCategories([...changeCategories]);
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const eventsData = fields.find((el) => el.id === 'FCUSTEVENT');
              if (eventsData) {
                setEvents(
                  eventsData.values.map((el) => ({
                    id: (el.value as LovValue).id,
                    label: (el.value as LovValue).label,
                  })),
                );
              }
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: selectedProductFamilies[upperIndex],
                  },
                ],
              },
              {
                id: 'FCUSTEVENTTYPE',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
    } else {
      selectedEvents[upperIndex] = value;
      setSelectedEvents([...selectedEvents]);
      dispatch(
        getProjectMetadataValues(
          (err, fields: RequiredMetadata[]) => {
            if (!err && fields) {
              const changeCategoryData = fields.find((el) => el.id === 'FCUSTEVENTCHANGECATEGORY');
              if (changeCategoryData) {
                changeCategories[upperIndex] = (
                  changeCategoryData.values[0].value as string[]
                )?.join();
                setChangeCategories([...changeCategories]);
              }
            }
          },
          {
            fields: [
              {
                id: 'FCUSTPRODUCTFAMILY',
                type: 'multiLov',
                value: [
                  {
                    id: selectedProductFamilies[upperIndex],
                  },
                ],
              },
              {
                id: 'FCUSTEVENTTYPE',
                type: 'multiLov',
                value: [
                  {
                    id: selectedEventTypes[upperIndex].id,
                  },
                ],
              },
              {
                id: 'FCUSTEVENT',
                type: 'multiLov',
                value: [
                  {
                    id: value.id,
                  },
                ],
              },
            ],
          },
        ),
      );
    }
  };
  const removeProductFamily = (upperIndex: number, selectedProductFamilyId: string) => {
    setSelectedProductFamilies(
      selectedProductFamilies.filter(
        (selectedProductFamily) => selectedProductFamily !== selectedProductFamilyId,
      ),
    );
    selectedDrugProducts[upperIndex] = [];
    setSelectedDrugProducts([...selectedDrugProducts]);
    selectedDrugSubstances[upperIndex] = [];
    setSelectedDrugSubstances([...selectedDrugSubstances]);
    selectedPackagingCodes[upperIndex] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    selectedDPManufacturers[upperIndex] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
    selectedDSManufacturers[upperIndex] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const addProductFamily = () => {
    if (selectedProductFamilies.find((selectedProductFamily) => selectedProductFamily === 'TEMP')) {
      return;
    }
    setSelectedProductFamilies([...selectedProductFamilies, 'TEMP']);
  };

  const addProduct = (upperIndex: number, productFamilyId: string) => {
    if (
      selectedDrugProducts[upperIndex].find(
        (selectedDrugProduct) => selectedDrugProduct === `TEMP_${productFamilyId}`,
      )
    ) {
      return;
    }
    selectedDrugProducts[upperIndex].push(`TEMP_${productFamilyId}`);
    setSelectedDrugProducts([...selectedDrugProducts]);
  };

  const removeProduct = (upperIndex: number, index: number, selectedProductId: string) => {
    selectedDrugProducts[upperIndex] = selectedDrugProducts[upperIndex].filter(
      (selectedDrugProduct) => selectedDrugProduct !== selectedProductId,
    );
    setSelectedDrugProducts([...selectedDrugProducts]);
    selectedPackagingCodes[upperIndex][index] = [];
    setSelectedPackagingCodes([...selectedPackagingCodes]);
    selectedDPManufacturers[upperIndex][index] = [];
    setSelectedDPManufacturers([...selectedDPManufacturers]);
  };

  const addSubstance = (upperIndex: number, productFamilyId: string) => {
    if (
      selectedDrugSubstances[upperIndex].find(
        (selectedDrugSubstance) => selectedDrugSubstance === `TEMP_${productFamilyId}`,
      )
    ) {
      return;
    }
    selectedDrugSubstances[upperIndex].push(`TEMP_${productFamilyId}`);
    setSelectedDrugSubstances([...selectedDrugSubstances]);
  };

  const removeSubstance = (upperIndex: number, index: number, selectedDrugSubstanceId: string) => {
    selectedDrugSubstances[upperIndex] = selectedDrugSubstances[upperIndex].filter(
      (selectedDrugSubstance) => selectedDrugSubstance !== selectedDrugSubstanceId,
    );
    setSelectedDrugSubstances([...selectedDrugSubstances]);
    selectedDSManufacturers[upperIndex][index] = [];
    setSelectedDSManufacturers([...selectedDSManufacturers]);
  };

  const renderDrugSubstances = (
    productFamilyId: string,
    selectedDrugSubstanceId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDSInReviewMode(productFamilyId, selectedDrugSubstanceId, productFamilyList);
    }
    return (
      <Grid item xs={4}>
        <div className='form-field'>
          <IASelect
            data-testid='Drug Substance'
            label='Drug Substance'
            placeholder='Select drug substance'
            options={
              getProductFamilyById(productFamilyId, productFamilyList)
                ?.drugSubstances.map((el) => ({
                  id: el.id,
                  label: el.label,
                }))
                ?.filter(
                  (item) =>
                    item.id === selectedDrugSubstanceId ||
                    !selectedDrugSubstances[upperIndex].includes(item.id),
                ) || []
            }
            onChange={(event, value) =>
              onChangeDrugSubstance(event, index, upperIndex, productFamilyId, value as SelectValue)
            }
            value={
              getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
                (el) => el.id === selectedDrugSubstanceId,
              )?.label ?? ''
            }
            disabled={isReview}
            error={emptyFields.includes(`substance ${upperIndex + 1} ${index + 1}`)}
            disableClearable={index < selectedDrugSubstances[upperIndex].length - 1}
          />
        </div>
      </Grid>
    );
  };

  const renderDrugSubstanceManufacturers = (
    productFamilyId: string,
    selectedDrugSubstanceId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDSManufacturersInReviewMode(
        productFamilyId,
        selectedDrugSubstanceId,
        productFamilyList,
        upperIndex,
        index,
        selectedDSManufacturers,
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            <IAMultiSelect
              data-testid='Drug Substance Manufacturers'
              label='Drug Substance Manufacturers'
              placeholder='Select drug substance manufacturers'
              options={
                getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
                  (el) => el.id === selectedDrugSubstanceId,
                )?.dsManufacturers || []
              }
              onChange={(event, value) =>
                onChangeDSManufacturers(event, upperIndex, index, value as SelectValue[])
              }
              value={
                getProductFamilyById(productFamilyId, productFamilyList)
                  ?.drugSubstances.find((el) => el.id === selectedDrugSubstanceId)
                  ?.dsManufacturers?.filter(({ id }) =>
                    selectedDSManufacturers[upperIndex][index].includes(id),
                  ) || []
              }
              disabled={isReview}
              disableClearable={false}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const getDSRemoveIcon = (selectedDrugSubstanceId: string, upperIndex: number, index: number) => {
    if (index > 0 && !isReview) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`remove-substance-${selectedDrugSubstanceId}`}
              className='add-more'
              onClick={() => removeSubstance(upperIndex, index, selectedDrugSubstanceId)}
            >
              <RemoveItem />
              <span> Remove</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const getDSAddIcon = (
    selectedDrugSubstanceId: string,
    productFamilyId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (
      !isReview &&
      !!getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
        (el) => el.id === selectedDrugSubstanceId,
      )?.label &&
      index === 0 &&
      (
        getSelectedDrugSubstances(
          upperIndex,
          productFamilyId,
          selectedDrugSubstances,
          productFamilyList,
        ) || []
      ).length <
        (getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances || []).length
    ) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`add-substance-${productFamilyId}`}
              className='add-more'
              onClick={() => addSubstance(upperIndex, productFamilyId)}
            >
              <AddMore />
              <span> Add More</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const renderSelectedSubstances = (productFamilyId: string, upperIndex: number) => {
    if (getProductFamilyById(productFamilyId, productFamilyList)?.label) {
      return getSelectedDrugSubstances(
        upperIndex,
        productFamilyId,
        selectedDrugSubstances,
        productFamilyList,
      )?.map((selectedDrugSubstanceId, index) => (
        <div key={selectedDrugSubstanceId}>
          <hr className='divider-dotted' />
          <div className='product-group-title'>Substance {index + 1}</div>
          <Grid container spacing={2}>
            {renderDrugSubstances(productFamilyId, selectedDrugSubstanceId, upperIndex, index)}
            {isReview &&
              renderDrugSubstanceManufacturers(
                productFamilyId,
                selectedDrugSubstanceId,
                upperIndex,
                index,
              )}
            {getDSRemoveIcon(selectedDrugSubstanceId, upperIndex, index)}
            {getDSAddIcon(selectedDrugSubstanceId, productFamilyId, upperIndex, index)}
          </Grid>
          {getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
            (el) => el.id === selectedDrugSubstanceId,
          )?.label &&
            !isReview &&
            renderDrugSubstanceManufacturers(
              productFamilyId,
              selectedDrugSubstanceId,
              upperIndex,
              index,
            )}
        </div>
      ));
    }
    return <></>;
  };

  const renderDrugProducts = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDPInReviewMode(productFamilyId, productFamilyList, selectedProductId);
    }
    return (
      <IASelect
        data-testid='Product name'
        label='Product name'
        placeholder='Select product name'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.map((el) => ({
              id: el.id,
              label: el.label,
            }))
            ?.filter(
              (item) =>
                item.id === selectedProductId ||
                !selectedDrugProducts[upperIndex].includes(item.id),
            ) || []
        }
        onChange={(event, value) =>
          onChangeProduct(event, index, upperIndex, productFamilyId, value as SelectValue)
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.label ?? ''
        }
        disabled={isReview}
        error={emptyFields.includes(`product ${upperIndex + 1} ${index + 1}`)}
        disableClearable={index < selectedDrugProducts[upperIndex].length - 1}
      />
    );
  };

  const renderPackagingCodes = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getPackagingCodesInReviewMode(
        productFamilyId,
        selectedProductId,
        productFamilyList,
        upperIndex,
        index,
        selectedPackagingCodes,
      );
    }
    return (
      <IAMultiSelect
        data-testid='Packaging Code'
        label='Packaging Code'
        placeholder='Select packaging code'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.packagingCodes || []
        }
        onChange={(event, value) =>
          onChangePackagingCode(event, upperIndex, index, value as SelectValue[])
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.packagingCodes?.filter(({ id }) =>
              selectedPackagingCodes[upperIndex][index].includes(id),
            ) || []
        }
        disabled={isReview}
        disableClearable={false}
      />
    );
  };

  const renderDrugProductManufacturers = (
    productFamilyId: string,
    selectedProductId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (isReview) {
      return getDPManufacturersInReviewMode(
        productFamilyId,
        selectedProductId,
        productFamilyList,
        upperIndex,
        index,
        selectedDPManufacturers,
      );
    }
    return (
      <IAMultiSelect
        data-testid='Drug Product Manufacturers'
        label='Drug Product Manufacturers'
        placeholder='Select drug product manufacturers'
        options={
          getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.dpManufacturers || []
        }
        onChange={(event, value) =>
          onChangeDPManufacturers(event, upperIndex, index, value as SelectValue[])
        }
        value={
          getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.dpManufacturers?.filter(({ id }) =>
              selectedDPManufacturers[upperIndex][index].includes(id),
            ) || []
        }
        disabled={isReview}
        disableClearable={false}
      />
    );
  };

  const getDPRemoveIcon = (selectedProductId: string, upperIndex: number, index: number) => {
    if (index > 0 && !isReview) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`remove-product-${selectedProductId}`}
              className='add-more'
              onClick={() => removeProduct(upperIndex, index, selectedProductId)}
            >
              <RemoveItem />
              <span> Remove</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const getDPAddIcon = (
    selectedProductId: string,
    productFamilyId: string,
    upperIndex: number,
    index: number,
  ) => {
    if (
      !isReview &&
      !!getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
        (el) => el.id === selectedProductId,
      )?.label &&
      index === 0 &&
      (
        getSelectedDrugProducts(
          upperIndex,
          productFamilyId,
          selectedDrugProducts,
          productFamilyList,
        ) || []
      ).length <
        (getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts || []).length
    ) {
      return (
        <Grid item xs={4}>
          <div className='add-more-container'>
            <div
              data-testid={`add-product-${productFamilyId}`}
              className='add-more'
              onClick={() => addProduct(upperIndex, productFamilyId)}
            >
              <AddMore />
              <span> Add More</span>
            </div>
          </div>
        </Grid>
      );
    }
  };

  const renderSelectedProducts = (productFamilyId: string, upperIndex: number) => {
    if (getProductFamilyById(productFamilyId, productFamilyList)?.label) {
      return getSelectedDrugProducts(
        upperIndex,
        productFamilyId,
        selectedDrugProducts,
        productFamilyList,
      )?.map((selectedProductId, index) => (
        <div key={selectedProductId}>
          <hr className='divider-dotted' />
          <div className='product-group-title'>Product {index + 1}</div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                {renderDrugProducts(productFamilyId, selectedProductId, upperIndex, index)}
              </div>
            </Grid>
            {getDPRemoveIcon(selectedProductId, upperIndex, index)}
            {getDPAddIcon(selectedProductId, productFamilyId, upperIndex, index)}
          </Grid>
          {!!getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
            (el) => el.id === selectedProductId,
          )?.label && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className='form-field'>
                    {renderDosageForm(
                      productFamilyId,
                      selectedProductId,
                      productFamilyList,
                      isReview,
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='form-field'>
                    {renderDosageStrength(
                      productFamilyId,
                      selectedProductId,
                      productFamilyList,
                      isReview,
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='form-field'>
                    {renderPackagingCodes(productFamilyId, selectedProductId, upperIndex, index)}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className='form-field'>
                    {renderDrugProductManufacturers(
                      productFamilyId,
                      selectedProductId,
                      upperIndex,
                      index,
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      ));
    }
    return <></>;
  };
  
  const renderProductFamily = (productFamilyId: string, upperIndex: number) => {
    if (isReview) {
      return (
        <div>
          <div className='label'>Product Family</div>
          <div className='review-value'>
            {getProductFamilyById(productFamilyId, productFamilyList)?.label || '-'}
          </div>
        </div>
      );
    }

    return (
      <IASelect
        data-testid='Product Family'
        label='Product Family'
        placeholder='Select product family'
        options={productFamilyList.filter(
          (el) => el.id === productFamilyId || !selectedProductFamilies.includes(el.id),
        )}
        onChange={(event, value) =>
          onChangeProductFamily(upperIndex, event, value as { id: string; label: string })
        }
        value={getProductFamilyById(productFamilyId, productFamilyList)?.label ?? ''}
        disabled={isReview}
        error={emptyFields.includes(`name ${upperIndex + 1}`)}
      />
    );
  };

  const renderEvents = (productFamilyId: string, upperIndex: number) => {
    return (
      <EventDetails
        index={upperIndex}
        isReview={isReview}
        selectedEventType={selectedEventTypes}
        selectedEvent={selectedEvents}
        events={events}
        changeCategories={changeCategories}
        handleChange={(key, value) => {
          onChangeEvents(key, upperIndex, value);
        }}
      />
    );
  };

  const renderSelectedProductFamilies = () =>
    selectedProductFamilies.map((productFamilyId, upperIndex) => (
      <div key={productFamilyId}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>{renderProductFamily(productFamilyId, upperIndex)}</div>
          </Grid>
          {isReview && renderProductINNAndCode(productFamilyId, productFamilyList, isReview)}
          {!isReview &&
            !!getProductFamilyById(productFamilyId, productFamilyList)?.label &&
            renderProductINNAndCode(productFamilyId, productFamilyList, isReview)}
          {upperIndex > 0 && !isReview && (
            <Grid item xs={4}>
              <div className='add-more-container'>
                <div
                  data-testid={`remove-product-family-${productFamilyId}`}
                  className='add-more'
                  onClick={() => removeProductFamily(upperIndex, productFamilyId)}
                >
                  <RemoveItem />
                  <span> Remove</span>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {workspaceType === PROJECT_TYPES.submission && renderEvents(productFamilyId, upperIndex)}
        {workspaceType !== PROJECT_TYPES.diapoc && renderSelectedProducts(productFamilyId, upperIndex)}
        {workspaceType !== PROJECT_TYPES.diapoc && renderSelectedSubstances(productFamilyId, upperIndex)}
        {selectedProductFamilies.length - 1 !== upperIndex && <hr className='divider' />}
      </div>
    ));
    
  return (
    <div style={isReview ? { marginTop: '48px' } : {}}>
      {title && <ReviewActionTitle onEdit={onEdit} title={title} isReview={isReview} />}
      {workspaceType === PROJECT_TYPES.ptqagreements && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {isReview ? (
              <div className='form-field'>
                <div className='label'>Study ID</div>
                <div className='review-value'>{studyId.label || '-'}</div>
              </div>
            ) : (
              <div className='form-field'>
                <IASelect
                  data-testid='Study ID'
                  label='Study ID'
                  placeholder='Select Study ID'
                  options={getLovValues(lovs, 'DCUSTSTUDYID')}
                  onChange={(_, value) => onChangeStudyId(value as LovValue)}
                  value={studyId}
                  disabled={isReview}
                  error={emptyFields.includes('studyId')}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {renderSelectedProductFamilies()}
      
      {(workspaceType === PROJECT_TYPES.diapoc ) &&

        <><hr className='divider-dotted' />
          <div key='DiaPoc'>
            <Grid container spacing={2}>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Product Type</div>
                <div className='review-value'>{DIAProductType.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='DIA - Product Type'
                    label='DIA - Product Type'
                    placeholder='Select DIA - Product Type'
                    options={getLovValues(lovs, 'DCUSTDIAPRODUCTTYPE')}
                    onChange={(_, value) => onChangeDIAProductType(value as LovValue)}
                    value={DIAProductType}
                    disabled={isReview}
                    error={emptyFields.includes('diaProductType')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Product Type</div>
                <div className='review-value'>{DIAProductType.label || '-'}</div>
              </div>
            ) : (<div className='form-field'>
                  <IASelect
                    data-testid='DIA - mQMS Doc. Deliverable'
                    label='DIA - mQMS Doc. Deliverable'
                    placeholder='Select DIA - mQMS Doc. Deliverable'
                    options={getLovValues(lovs, 'DCUSTDIAMQMSDOCDELIVERABLE')}
                    onChange={(_, value) => onChangeDIAmQMSDocDeliverable(value as LovValue)}
                    value={DIAmQMSDocDeliverable}
                    disabled={isReview}
                    error={emptyFields.includes('DIAmQMSDocDeliverable')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Project Name</div>
                <div className='review-value'>{fieldsFilled?.diaProjectName || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Project Name'}
                    inputLabel='DIA - Project Name'
                    onChange={(event) => setDiaProjectName(event.target.value)}
                    value={diaProjectName}
                    disabled={isReview}
                    error={emptyFields.includes('diaProjectName')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Project Number</div>
                <div className='review-value'>{diaProjectNumber || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Project Number'}
                    inputLabel='DIA - Project Number'
                    onChange={(event) => setDiaProjectNumber(event.target.value)}
                    value={diaProjectNumber}
                    disabled={isReview}
                    error={emptyFields.includes('diaProjectNumber')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - LTC</div>
                <div className='review-value'>{diaLct || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - LCT'}
                    inputLabel='DIA - LCT'
                    onChange={(event) => setDiaLct(event.target.value)}
                    value={diaLct}
                    disabled={isReview}
                    error={emptyFields.includes('diaLct')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Product Name</div>
                <div className='review-value'>{diaProductName || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Product Name'}
                    inputLabel='DIA - Product Name'
                    onChange={(event) => setdiaProductName(event.target.value)}
                    value={diaProductName}
                    disabled={isReview}
                    error={emptyFields.includes(' diaProductName')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Test Number</div>
                <div className='review-value'>{diaTestNumber || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Test Number'}
                    inputLabel='DIA - Test Number'
                    onChange={(event) => setdiaTestNumber(event.target.value)}
                    value={diaTestNumber}
                    disabled={isReview}
                    error={emptyFields.includes('diaTestNumber')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Report Type</div>
                <div className='review-value'>{diaReportType.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='DIA - Report Type'
                    label='DIA - Report Type'
                    placeholder='Select DIA - Report Type'
                    options={getLovValues(lovs, 'DCUSTDIAREPORTTYPE')}
                    onChange={(_, value) => onChangediaReportType(value as LovValue)}
                    value={diaReportType}
                    disabled={isReview}
                    error={emptyFields.includes('diaReportType')}
                  />
                </div>)}
              </Grid>
              {/* <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Material</div>
                <div className='review-value'>{'-'}</div>
              </div>
            ) : (
                <div className='form-field'>                  
                  <IAMultiSelect
                    data-testid='DIA - Material'
                    label='DIA - Material'
                    placeholder='Select DIA - Material'
                    options={
                      getLovValues(lovs, 'DCUSTDIAMATERIAL') || []
                    }
                    onChange={(_event, value) => {
                      const selectedValues = value as { label: string; id: string }[];
                      onChangediaMaterial(
                        selectedValues
                      );
                    }}
                    // {(event, value) =>
                    //   console.log(value)
                    //   // onChangeDPManufacturers(event, upperIndex, index, value as SelectValue[])
                    // }
                    value={diaMaterial}
                    disabled={isReview}
                    disableClearable={false}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Material Name Medium</div>
                <div className='review-value'>{diaMaterialNameMedium || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Material Name Medium'}
                    inputLabel='DIA - Material Name Medium'
                    onChange={(event) => setdiaMaterialNameMedium([event.target.value])}
                    value={diaMaterialNameMedium}
                    disabled={isReview}
                    error={emptyFields.includes('diaMaterialNameMedium')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
                {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Material Name Short</div>
                <div className='review-value'>{diaMaterialNameShort || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA - Material Name Short'}
                    inputLabel='DIA - Material Name Short'
                    onChange={(event) => setdiaMaterialNameShort(event.target.value)}
                    value={diaMaterialNameShort}
                    disabled={isReview}
                    error={emptyFields.includes('diaMaterialNameShort')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Material Number</div>
                <div className='review-value'>{diaMaterialNumber || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAInput
                    data-testid={'DIA Material Number'}
                    inputLabel='DIA Material Number'
                    onChange={(event) => setdiaMaterialNumber(event.target.value)}
                    value={diaMaterialNumber}
                    disabled={isReview}
                    error={emptyFields.includes('diaMaterialNumber')}
                  />
                </div>)}
              </Grid> */}
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>PRIME Project Name</div>
                <div className='review-value'>{diaPRIMEProjectName.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='PRIME Project Name'
                    label='PRIME Project Name'
                    placeholder='Select PRIME Project Name'
                    options={getLovValues(lovs, 'DCUSTPRIMEPROJECTNAME')}
                    onChange={(_, value) => onChangediaPRIMEProjectName(value as LovValue)}
                    value={diaPRIMEProjectName}
                    disabled={isReview}
                    error={emptyFields.includes('diaPRIMEProjectName')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>PRIME Project LPN</div>
                <div className='review-value'>{diaPRIMEProjectLPN.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='PRIME Project LPN'
                    label='PRIME Project LPN'
                    placeholder='Select PRIME Project LPN'
                    options={getLovValues(lovs, 'DCUSTPRIMEPROJECTLPN')}
                    onChange={(_, value) => onChangediaPRIMEProjectLPN(value as LovValue)}
                    value={diaPRIMEProjectLPN}
                    disabled={isReview}
                    error={emptyFields.includes('diaPRIMEProjectLPN')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>PRIME Project PPN</div>
                <div className='review-value'>{diaPRIMEProjectPPN.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='PRIME Project PPN'
                    label='PRIME Project PPN'
                    placeholder='Select PRIME Project PPN'
                    options={getLovValues(lovs, 'DCUSTPRIMEPROJECTPPN')}
                    onChange={(_, value) => onChangediaPRIMEProjectPPN(value as LovValue)}
                    value={diaPRIMEProjectPPN}
                    disabled={isReview}
                    error={emptyFields.includes('diaPRIMEProjectPPN')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA - Instrument Name</div>
                <div className='review-value'>{diaInstrumentName.map((el) => el.label).join(', ')}</div>
              </div>
            ) : (
                <div className='form-field'>                  
                  <IAMultiSelect
                    data-testid='DIA - Instrument Name'
                    label='DIA - Instrument Name'
                    placeholder='Select DIA - Instrument Name'
                    options={
                      getLovValues(lovs, 'DCUSTDIAINSTRUMENTNAME') || []
                    }
                    onChange={(_event, value) => {
                      const selectedValues = value as { label: string; id: string }[];
                      onChangediaInstrumentName(
                        selectedValues
                      );
                    }}
                    value={diaInstrumentName}
                    disabled={isReview}
                    disableClearable={false}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Study Type</div>
                <div className='review-value'>{diaStudyType.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='DIA Study Type'
                    label='DIA Study Type'
                    placeholder='Select DIA Study Type'
                    options={getLovValues(lovs, 'DCUSTDIASTUDYTYPE')}
                    onChange={(_, value) => onChangediaStudyType(value as LovValue)}
                    value={diaStudyType}
                    disabled={isReview}
                    error={emptyFields.includes('diaStudyType')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Study Sub Type</div>
                <div className='review-value'>{diaStudySubtype.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='DIA Study Sub Type'
                    label='DIA Study Sub Type'
                    placeholder='Select DIA Study Sub Type'
                    options={diastudysubtypeOption}
                    onChange={(_, value) => onChangediastudysubtype(value as LovValue)}
                    value={diaStudySubtype}
                    disabled={isReview || !diastudysubtypeOption.length}
                    error={emptyFields.includes('diaStudySubtype')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Study Sub-Type Variant</div>
                <div className='review-value'>{diaStudySubTypeVariant.map((el) => el.label).join(', ')}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAMultiSelect
                    data-testid='DIA Study Sub-Type Variant'
                    label='DIA Study Sub-Type Variant'
                    placeholder='Select DIA Study Sub-Type Variant'
                    options={diastudysubtypeVariantOption}
                    onChange={(_, value) => onChangediaStudySubTypeVariant(value as LovValue[])}
                    value={diaStudySubTypeVariant}
                    disabled={isReview || !diastudysubtypeVariantOption}
                    error={emptyFields.includes('diaStudySubTypeVariant')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Life-Cycle Status</div>
                <div className='review-value'>{diaLifeCycleStatus.label || '-'}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IASelect
                    data-testid='DIA Life-Cycle Status'
                    label='DIA Life-Cycle Status'
                    placeholder='Select DIA Life-Cycle Status'
                    options={diaLifeCycleStatusOption}
                    onChange={(_, value) => onChangediaLifeCycleStatus(value as LovValue)}
                    value={diaLifeCycleStatus}
                    disabled={isReview || !diaLifeCycleStatusOption}
                    error={emptyFields.includes('diaLifeCycleStatus')}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Customer Area</div>
                <div className='review-value'>{diaCustomerArea.map((el) => el.label).join(', ')}</div>
              </div>
            ) : (
                <div className='form-field'>                  
                  <IAMultiSelect
                    data-testid='DIA Customer Area'
                    label='DIA Customer Area'
                    placeholder='Select DIA Customer Area'
                    options={
                      getLovValues(lovs, 'DCUSTDIACUSTOMERAREA') || []
                    }
                    onChange={(_event, value) => {
                      const selectedValues = value as { label: string; id: string }[];
                      onChangediaCustomerArea(
                        selectedValues
                      );
                    }}
                    value={diaCustomerArea}
                    disabled={isReview}
                    disableClearable={false}
                  />
                </div>)}
              </Grid>
              <Grid item xs={4}>
              {isReview ? (<div className='form-field'>
                <div className='label'>DIA Diagnostics Solution</div>
                <div className='review-value'>{diaDiagnosticsSolution.map((el) => el.label).join(', ')}</div>
              </div>
            ) : (
                <div className='form-field'>
                  <IAMultiSelect
                    data-testid='DIA Diagnostics Solution'
                    label='DIA Diagnostics Solution'
                    placeholder='Select DIA Diagnostics Solution'
                    options={
                      diaDiagnosticsSolutionOption
                    }
                    onChange={(_event, value) => {
                      const selectedValues = value as { label: string; id: string }[];
                      onChangediaDiagnosticsSolution(
                        selectedValues
                      );
                    }}
                    value={diaDiagnosticsSolution}
                    disabled={isReview || !diaDiagnosticsSolutionOption}
                    error={emptyFields.includes('diaDiagnosticsSolution')}
                  />
                </div>)}
              </Grid>
            </Grid>
          </div></>
      }
      {!isReview &&
        !!getProductFamilyById(
          selectedProductFamilies[selectedProductFamilies.length - 1],
          productFamilyList,
        )?.label &&
        selectedProductFamilies.length < productFamilyList.length && (
          <div className='add-more-container'>
            <div className='add-more' onClick={() => addProductFamily()}>
              <AddMore />
              <span> Add more product families</span>
            </div>
          </div>
        )}
    </div>
  );
};

export default ProductDetails;
